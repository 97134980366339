import FilterModal from '../../Modals/FilterModal/FilterModal';
import { Box, Button, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import { sx } from './listingFilters.sx';
import { REGIONS } from '../../../utils/listing/regions';
import { Check } from '@mui/icons-material';
import { memo } from 'react';
import clearFilter from '../../../assets/icons/clean-filter.svg';
import filter from '../../../assets/icons/filter.svg';
import peopleListings from '../../../assets/icons/peopleListings.svg';
import agentsListings from '../../../assets/icons/agentsListings.svg';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../firebase/config';

const ListingFilters = ({
	isFetching = false,
	regions,
	filterOptions,
	setRegions,
	isFilterModalOpen,
	setIsFilterModalOpen,
	isUsedInMainPage = false,
	handleResetFiltersAndRegions,
	isMobile = false,
	showOnlyAgentsListings,
	setShowOnlyAgentsListings,
}) => {
	const handleOpenFilters = () => {
		logEvent(analytics, 'open_filter_modal_click', {});
		setIsFilterModalOpen(true);
	};

	return (
		<>
			<FilterModal
				isOpen={isFilterModalOpen}
				setFilterModalOpen={setIsFilterModalOpen}
			/>
			<Box sx={isUsedInMainPage ? sx.controllers : sx.addingListingsControllers}>
				<Box sx={{ ...sx.filterButtonsWrapper, alignItems: 'flex-end' }}>
					<TextField
						value={regions || []}
						onClick={() => logEvent(analytics, 'region_select_click')}
						onChange={(e) => setRegions(e.target.value)}
						SelectProps={{
							multiple: true,
						}}
						select
						disabled={isFetching}
						sx={sx.regionSelect.sx}
						label="Region"
						InputProps={sx.regionSelect.inputProps}
						InputLabelProps={sx.regionSelect.inputLabelProps}
					>
						{REGIONS.map((region) => (
							<MenuItem
								sx={{ width: '253px', height: '43px', display: 'flex', justifyContent: 'space-between' }}
								value={region.city}
								key={region.city}
							>
								{region.label}
								{regions?.includes(region.city) ? <Check color="info" /> : null}
							</MenuItem>
						))}
					</TextField>
					<Tooltip title={!showOnlyAgentsListings ? 'Click to show only your listings' : 'Click to show all listings'}>
						<Button
							variant="outlined"
							disabled={isFetching}
							onClick={() => setShowOnlyAgentsListings((prev) => !prev)}
							sx={{
								width: { xs: '38px', md: 'fit-content' },
								minWidth: '38px',
								padding: { xs: '0px', md: '8px 16px 8px 16px' },
								height: '35px',
								borderRadius: '90px',
							}}
							startIcon={
								<img
									style={{ marginRight: isMobile ? '0px' : '6px' }}
									src={!showOnlyAgentsListings ? agentsListings : peopleListings}
									alt="People listings"
								/>
							}
						>
							{isMobile ? null : !showOnlyAgentsListings ? 'All listings' : 'User Listings'}
						</Button>
					</Tooltip>
				</Box>
				<Box sx={sx.filterButtonsWrapper}>
					{Boolean(filterOptions || regions?.length) && (
						<Tooltip title="Reset filters and regions">
							<IconButton
								onClick={handleResetFiltersAndRegions}
								sx={sx.filterButton}
							>
								<img
									src={clearFilter}
									alt="Clear filters"
								/>
							</IconButton>
						</Tooltip>
					)}
					<Tooltip title="Open filters">
						<Button
							sx={sx.filterButton}
							variant="outlined"
							disabled={isFetching}
							startIcon={
								<img
									style={{ marginRight: '8px' }}
									src={filter}
									alt="Filter"
								/>
							}
							onClick={handleOpenFilters}
						>
							Filter
						</Button>
					</Tooltip>
				</Box>
			</Box>
		</>
	);
};

export default memo(ListingFilters);
