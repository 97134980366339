import { Box, SvgIcon } from '@mui/material';
import { memo } from 'react';

const TwitterActionsIcons = () => {
	return (
		<Box
			sx={{
				margin: '18px 0 0 6px',
				width: '308px',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<SvgIcon>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.45911 8.33332C1.45911 4.64999 4.44577 1.66666 8.12994 1.66666H11.7683C15.5099 1.66666 18.5424 4.69999 18.5424 8.44166C18.5424 10.9083 17.2033 13.175 15.0458 14.3667L8.33411 18.0833V15.0083H8.27827C4.53661 15.0917 1.45911 12.0833 1.45911 8.33332ZM8.12994 3.33332C5.36577 3.33332 3.12577 5.57499 3.12577 8.33332C3.12577 11.1417 5.43411 13.4 8.24077 13.3417L8.53327 13.3333H10.0008V15.25L14.2399 12.9083C15.8658 12.0083 16.8758 10.3 16.8758 8.44166C16.8758 5.61666 14.5891 3.33332 11.7683 3.33332H8.12994Z"
						fill="#9FA0A2"
					/>
				</svg>
			</SvgIcon>
			<SvgIcon>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3.74997 3.23334L7.44331 6.68334L6.30664 7.9L4.58331 6.29167V13.3333C4.58331 14.25 5.32997 15 6.24997 15H10.8333V16.6667H6.24997C4.40914 16.6667 2.91664 15.175 2.91664 13.3333V6.29167L1.19331 7.9L0.0566406 6.68334L3.74997 3.23334ZM13.75 5H9.16664V3.33334H13.75C15.5908 3.33334 17.0833 4.825 17.0833 6.66667V13.7083L18.8066 12.1L19.9433 13.3167L16.25 16.7667L12.5566 13.3167L13.6933 12.1L15.4166 13.7083V6.66667C15.4166 5.75 14.67 5 13.75 5Z"
						fill="#9FA0A2"
					/>
				</svg>
			</SvgIcon>
			<SvgIcon>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13.9141 4.58335C12.8958 4.53335 11.6816 5.00835 10.6725 6.38335L10.0016 7.29168L9.32997 6.38335C8.31997 5.00835 7.10497 4.53335 6.08664 4.58335C5.05081 4.64168 4.12914 5.23335 3.66164 6.17501C3.20164 7.10835 3.13414 8.49168 4.06081 10.1917C4.95581 11.8333 6.77497 13.75 10.0016 15.7C13.2266 13.75 15.045 11.8333 15.94 10.1917C16.8658 8.49168 16.7983 7.10835 16.3375 6.17501C15.87 5.23335 14.9491 4.64168 13.9141 4.58335ZM17.4033 10.9917C16.2775 13.0583 14.0691 15.2583 10.4208 17.3833L10.0016 17.6333L9.58164 17.3833C5.93247 15.2583 3.72414 13.0583 2.59664 10.9917C1.46331 8.90835 1.42164 6.94168 2.16831 5.43335C2.90747 3.94168 4.37414 3.00835 6.00247 2.92501C7.37831 2.85001 8.80914 3.39168 10.0008 4.60001C11.1916 3.39168 12.6225 2.85001 13.9975 2.92501C15.6258 3.00835 17.0925 3.94168 17.8316 5.43335C18.5783 6.94168 18.5366 8.90835 17.4033 10.9917Z"
						fill="#9FA0A2"
					/>
				</svg>
			</SvgIcon>
			<SvgIcon>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.29171 17.5V2.5H8.95837V17.5H7.29171ZM15 17.5V7.08333H16.6667V17.5H15ZM3.33337 17.5L3.33671 9.16667H5.00337L5.00004 17.5H3.33337ZM11.04 17.5V11.6667H12.7067V17.5H11.04Z"
						fill="#9FA0A2"
					/>
				</svg>
			</SvgIcon>
			<SvgIcon>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10 2.15833L14.75 6.90832L13.575 8.09166L10.8333 5.34166V13.3333H9.16667V5.34166L6.41667 8.09166L5.24167 6.90832L10 2.15833ZM17.5 12.5L17.4833 15.425C17.4833 16.575 16.55 17.5 15.4 17.5H4.58333C3.425 17.5 2.5 16.5667 2.5 15.4167V12.5H4.16667V15.4167C4.16667 15.65 4.35 15.8333 4.58333 15.8333H15.4C15.6333 15.8333 15.8167 15.65 15.8167 15.4167L15.8333 12.5H17.5Z"
						fill="#9FA0A2"
					/>
				</svg>
			</SvgIcon>
		</Box>
	);
};

export default memo(TwitterActionsIcons);
