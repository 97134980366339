export const sx = {
	generatedItemsList: {
		maxWidth: '1100px',
		display: 'grid',
		gridTemplateColumns: { xs: '1fr', sm: 'repeat(auto-fit, 208px)' },
		margin: { xs: '11px auto 0 auto', md: '11px 0 0 0' },
		justifyContent: 'center',
		alignItems: 'flex-start',
		width: '100%',
		gap: { xs: '4px', sm: '16px 8px' },
		listStyle: 'none',
		padding: 0,
	},
	emptyWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '300px',
		width: 'max-content',
		maxWidth: '90%',
	},
	emptylabel: {
		marginTop: '20px',
		width: '100%',
		fontSize: '18px',
		fontWeight: '700',
		color: (theme) => theme.palette.text.primary,
		textWrap: 'nowrap',
	},
};
