import { Box, Modal } from '@mui/material';
import Lottie from 'react-lottie';
import generatingAnimation from '../../../assets/animations/ai-generation-content.json';
import { memo, useEffect, useState } from 'react';

//@@viewOn:private
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: generatingAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};
//@@viewOff:private

const GenerationContentModal = ({ isOpen = false, progress, isImageGenerating = false }) => {
	//@@viewOn:hooks
	const [widthPercentage, setWidthPercentage] = useState(0);
	//@@viewOff:private

	//@@viewOn:effects
	useEffect(() => {
		if (!isOpen || widthPercentage >= 100 || !isImageGenerating) return;

		let interval = setInterval(() => {
			const shouldIncreaseWidth = !(
				(progress && progress < widthPercentage - 6) ||
				(!progress && widthPercentage === 50)
			);

			if (shouldIncreaseWidth && widthPercentage < 100) {
				setWidthPercentage((prev) => (progress > widthPercentage ? progress : prev + 1));
			}
		}, 800);

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [isOpen, setWidthPercentage, progress, widthPercentage]);

	useEffect(() => {
		if (!isOpen || !isImageGenerating || progress === 'incomplete') return;

		if (progress && typeof progress === 'number') {
			const newValue = progress > widthPercentage ? progress : widthPercentage;
			setWidthPercentage(newValue);
		}
	}, [progress, widthPercentage, isOpen, isImageGenerating]);

	useEffect(() => {
		return () => {
			setWidthPercentage(0);
		};
	}, [isOpen]);
	//@@viewOff:effects

	return (
		<Modal
			open={isOpen}
			keepMounted={false}
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					display: 'flex',
					maxWidth: '662px',
					maxHeight: '462px',
					width: '96%',
					height: '100%',
					padding: '25px',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '10px',
					borderRadius: '14px',
					background: (theme) => theme.palette.bg.main,
				}}
			>
				<Lottie
					options={defaultOptions}
					height={300}
					width={300}
				/>
				<Box
					sx={{
						color: (theme) => theme.palette.text.primary,
						fontSize: '18px',
						fontWeight: '700',
						lineHeight: '24px',
						letterSpacing: '0.4px',
					}}
				>
					Generating content...
				</Box>
				{isImageGenerating && (
					<>
						<Box
							sx={{
								borderRadius: '90px',
								background: '#DDE0F2',
								width: { xs: '90%', sm: '350px' },
								height: '7px',
								overflow: 'hidden',
							}}
						>
							<Box
								sx={{
									height: '100%',
									borderRadius: '90px',
									background: (theme) => theme.palette.bg.successBg,
									width: `${widthPercentage}%`,
									transition: `width ${0.5}s linear`,
								}}
							></Box>
						</Box>
						<Box
							sx={{
								marginTop: '10px',
								color: (theme) => theme.palette.stroke.lighter,
							}}
						>
							{`${widthPercentage > 100 ? 100 : widthPercentage}%`}
						</Box>
					</>
				)}
			</Box>
		</Modal>
	);
};

export default memo(GenerationContentModal);
