export const sx = {
	container: {
		background: (theme) => theme.palette.bg.main,
		borderRadius: '12px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
		boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
	},

	image: (imageProp) => ({
		marginTop: '15px',
		...(imageProp && { backgroundImage: `url(${imageProp})` }),
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100%',
		minHeight: '310px',
	}),
	footerSocialMedia: {
		marginTop: '17px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '25px',
	},
	footerEmail: {
		marginTop: '15px',
		background: (theme) => theme.palette.text.primary,
		width: '100%',
		height: '267px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderBottomLeftRadius: '12px',
		borderBottomRightRadius: '12px',
	},
	unsubscribeButton: {
		marginTop: '34px',
		padding: '8px 14px',
		alignItems: 'flex-start',
		gap: '10px',
		borderRadius: '8px',
		background: (theme) => `${theme.palette.bg.main} !important`,
		color: (theme) => theme.palette.text.primary,
	},
};
