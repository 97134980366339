import { Box, Button, SwipeableDrawer, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { sx } from './addingListingsModal.sx';
import Loader from '../../UI/Loader/Loader';
import RoomMainListItem from '../../MainPage/RoomMainListItem/RoomMainListItem';
import useHandleListings from '../../../hooks/useHandleListings/useHandleListings';
import InfiniteScroll from 'react-infinite-scroller';
import { makeFilterChipsList } from '../../../utils/listing/makeFilterChipsList';
import { useNavigate } from 'react-router-dom';
import CreationAPI from '../../../services/creationAPI';
import { useAppProvider } from '../../../pages/Layout/LayoutProvider';
import { memo, useCallback, useMemo } from 'react';
import FilterListingsChips from '../../common/FilterListingsChips/FilterListingsChips';
import useFavoriteListings from '../../../hooks/useFavoriteListings/useFavoriteListings';
import { useAuthorization } from '../../../pages/AuthorizationLayer/AuthorizationLayer';

const AddingListingsModal = () => {
	//@@viewOn:hooks
	const { UID } = useAuthorization();

	const {
		setRooms,
		setCurrentPage,
		currentPage,
		rooms,
		setFilterOptions,
		filterOptions,
		regions,
		setRegions,
		isAddingModalOpen,
		setIsAddingModalOpen,
		showOnlyAgentsListings,
	} = useAppProvider();

	const { favoriteListingsIds } = useFavoriteListings({ UID });

	const { data, isLoading, userListings } = useHandleListings({
		filterOptions,
		currentPage,
		regions,
		rooms,
		setCurrentPage,
		setRooms,
		showOnlyAgentsListings,
		favoriteListingsIds,
	});

	const navigate = useNavigate();
	//@@viewOff:hooks

	//@@viewOn:statics
	const totalListings = data?.length;

	const filterChipsList = useMemo(() => makeFilterChipsList(filterOptions, regions || []), [filterOptions, regions]);
	//@@viewOff:statics

	//@@viewOn:handlers
	const scrollToTop = () => {
		document.getElementById('scrollableDiv').scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleCloseAddingModal = () => {
		setIsAddingModalOpen(false);
	};

	const handleAddListing = async (room) => {
		await CreationAPI.handleChooseListing(UID, room);
		setIsAddingModalOpen(false);
		navigate(`/create/${room.Id}`);
	};

	const handleDeleteFilterField = useCallback(
		(filterField) => {
			if (filterField.city) {
				const newRegions = regions?.filter((region) => region !== filterField.city);
				setRegions(newRegions);
				return;
			}

			const updatedFilterOptions = { ...filterOptions };
			typeof updatedFilterOptions[filterField] === 'number'
				? (updatedFilterOptions[filterField] = 0)
				: (updatedFilterOptions[filterField] = false);

			setFilterOptions(updatedFilterOptions);
		},
		[regions, filterOptions],
	);
	//@@viewOff:handlers

	return (
		<SwipeableDrawer
			keepMounted
			open={isAddingModalOpen}
			onClose={handleCloseAddingModal}
			onOpen={() => setIsAddingModalOpen(true)}
		>
			<Box sx={sx.container}>
				<Box sx={sx.modalHeader}>
					<Typography
						variant="h3"
						sx={sx.headerTitle}
					>
						Add listing to content creation
					</Typography>
					<Close
						onClick={handleCloseAddingModal}
						sx={{ cursor: 'pointer' }}
					/>
				</Box>
				<Box sx={sx.modalContent}>
					<FilterListingsChips
						filterOptions={filterOptions}
						regions={regions}
						isForMainPage={false}
						handleDeleteFilterField={handleDeleteFilterField}
						filterChipsList={filterChipsList}
					/>
					{isLoading ? (
						<Loader disableShrink={true} />
					) : (
						<Box
							sx={sx.roomsWrapper}
							id="scrollableDiv"
						>
							<InfiniteScroll
								key="adding-listings-modal-infinite-scroll"
								loadMore={() => setCurrentPage((prev) => prev + 1)}
								initialLoad={false}
								hasMore={showOnlyAgentsListings ? false : rooms.length < totalListings}
								threshold={400}
								useWindow={false}
								loader={
									<Loader
										key="infinite-scroll"
										sx={{ height: '60px' }}
									/>
								}
							>
								<Box sx={sx.roomsList}>
									{(showOnlyAgentsListings ? userListings : rooms).map((room) => {
										const isLiked = favoriteListingsIds.find((listing) => listing === room.Id);

										return (
											<RoomMainListItem
												handleAddListing={handleAddListing}
												isLiked={isLiked}
												room={room}
												allowShrinking={true}
												key={room.Id}
												isUsedInProfilePage={false}
											/>
										);
									})}
								</Box>
								{(showOnlyAgentsListings ? userListings : rooms).length === totalListings && (
									<Box sx={sx.endMessageBox}>
										<Button
											variant="contained"
											onClick={scrollToTop}
										>
											Scroll top
										</Button>
									</Box>
								)}
							</InfiniteScroll>
						</Box>
					)}
				</Box>
				<Box sx={sx.modalFooter}>
					<Button
						variant="contained"
						sx={sx.closeButton}
						onClick={handleCloseAddingModal}
					>
						Close
					</Button>
				</Box>
			</Box>
		</SwipeableDrawer>
	);
};

export default memo(AddingListingsModal);
