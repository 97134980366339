import { useEffect, useState } from 'react';
import ProfileAPI from '../../services/profileAPI';
import { off, onValue, ref } from 'firebase/database';
import { db } from '../../firebase/config';

const useFavoriteListings = ({ UID }) => {
	const [favoriteListingsIds, setFavoriteListingsIds] = useState([]);

	useEffect(() => {
		if (!favoriteListingsIds?.length) {
			ProfileAPI.getFavoriteListings(UID).then((favoriteListings) => {
				setFavoriteListingsIds(favoriteListings || []);
			});
		}

		const favoriteListingsRef = ref(db, `favoriteListings/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const favoriteListings = Object.keys(data || {});

			setFavoriteListingsIds(favoriteListings || []);
		};

		onValue(favoriteListingsRef, onDataChange);

		return () => {
			off(favoriteListingsRef, onDataChange);
		};
	}, [UID, setFavoriteListingsIds]);
	return { favoriteListingsIds };
};

export default useFavoriteListings;
