import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { memo } from 'react';

const ChangelogModal = ({ showChangelogModal, setShowChangelogModal, mainMdContent }) => {
	return (
		<Modal
			open={showChangelogModal}
			onClose={() => setShowChangelogModal(false)}
			disableScrollLock={false}
		>
			<Box
				sx={{
					position: 'fixed',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xs: '95%', sm: '90%', md: '80%' },
					maxWidth: '900px',
					height: 'fit-content',
					maxHeight: '90%',
					overflowY: 'hidden',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					borderRadius: '10px',
					backgroundColor: (theme) => theme.palette.bg.main,
				}}
			>
				<Box
					sx={{
						position: 'fixed',
						top: '0px',
						left: '0px',
						zIndex: '11',
						padding: '10px 15px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						backgroundColor: (theme) => theme.palette.bg.main,
						borderBottom: '1px solid #eee',
					}}
				>
					<Typography
						variant="h1"
						sx={{
							fontSize: '18px',
							fontWeight: 'bold',
						}}
					>
						VERSION UPDATE
					</Typography>
					<IconButton onClick={() => setShowChangelogModal(false)}>
						<CloseOutlined />
					</IconButton>
				</Box>
				<Box
					sx={{
						padding: '20px',
						margin: '61px 0 67px 0',
						width: '100%',
						height: 'fit-content',
						minHeight: 'calc(100% - 61px - 67px)',
						maxHeight: 'calc(100% - 61px - 67px)',
						position: 'relative',
						left: '0px',
						overflowY: 'auto',
						overflowX: 'hidden',
						img: {
							height: 'auto',
							maxWidth: '100%',
							objectFit: 'contain',
						},
					}}
				>
					<ReactMarkdown>{mainMdContent}</ReactMarkdown>
				</Box>
				<Box
					sx={{
						position: 'fixed',
						bottom: '0px',
						left: '0px',
						padding: '15px',
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						borderTop: '1px solid #eee',
						background: (theme) => theme.palette.bg.main,
					}}
				>
					<Button
						onClick={() => setShowChangelogModal(false)}
						variant="contained"
					>
						Close
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default memo(ChangelogModal);
