import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { propertyTypes } from '../../../utils/listing/listingFilter';
import { memo } from 'react';

//@@viewOn:private
const defaultCardSx = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	height: '40px',
	gap: '10px',
	borderRadius: '14px !important',
	border: (theme) => `2px solid ${theme.palette.stroke.gray} !important`,
	background: (theme) => theme.palette.bg.main,
	color: (theme) => theme.palette.text.primary,
	maxWidth: '174px',
	minWidth: '148px',
	width: '40%',
};

const activeCardSx = {
	background: '#110F68 !important',
	border: '2px solid transparent',
	color: (theme) => theme.palette.bg.main,
};
//@@viewOff:private

const PropertyChooseGroup = ({ chosenPropertyTypes, setFieldValue, name }) => {
	//@@viewOn:handlers
	const handleChange = (_, newValues) => {
		setFieldValue(name, newValues);
	};
	//@@viewOff:handlers

	return (
		<ToggleButtonGroup
			value={chosenPropertyTypes}
			onChange={handleChange}
			name={name}
			aria-label="property-type-group"
			sx={{
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				alignItems: 'flex-start',
				gap: { xs: '11px 8px', sm: '11px 8px' },
			}}
		>
			{propertyTypes.map(({ type, label, icon }) => {
				const isChosen = chosenPropertyTypes.includes(type);
				return (
					<ToggleButton
						disableRipple={true}
						key={type}
						aria-label={type}
						value={type}
						sx={{ ...defaultCardSx, ...(isChosen && activeCardSx) }}
					>
						<Box
							sx={{
								width: '19px',
								weight: '19px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<img
								style={{
									minHeight: '100%',
									minWidth: '100%',
									filter: isChosen ? 'invert(100%)' : 'none',
								}}
								src={icon}
								alt={type}
							/>
						</Box>
						<Typography
							variant="body1"
							sx={{
								color: isChosen ? (theme) => theme.palette.bg.main : (theme) => theme.palette.text.primary,
								textAlign: 'start',
								lineHeight: '14px',
								textTransform: 'none',
							}}
						>
							{label}
						</Typography>
					</ToggleButton>
				);
			})}
		</ToggleButtonGroup>
	);
};

export default memo(PropertyChooseGroup);
