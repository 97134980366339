export const sx = {
	container: {
		minHeight: 'calc(100vh - 70px)',
		height: 'fit-content',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		position: 'absolute',
		padding: { xs: '10px 10px 30px 10px', sm: '10px 20px 30px 20px' },
		top: '70px',
		left: 0,
		right: 0,
		bottom: 0,
		overflowY: 'auto',
		backgroundColor: (theme) => theme.palette.bg.main,
		zIndex: 1,
	},
	title: {
		margin: '15px 0 5px 0',
		color: (theme) => theme.palette.text.deepTertiary,
		fontSize: '18px',
		fontWeight: '700',
		width: '100%',
		maxWidth: '1156px',
	},
};
