import { Box, Button, Typography } from '@mui/material';
import serhantEmail from '../../../../assets/logos/email-logo.svg';
import serhantFooter from '../../../../assets/logos/footer-bottom-logo.svg';
import { sx } from './email.sx';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { memo } from 'react';

const EmailPreview = ({ text, image = null }) => {
	return (
		<Box sx={sx.container}>
			<Box sx={{ padding: '20px' }}>
				<Box
					sx={{
						margin: '25px 0 38px 0',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<img
						src={serhantEmail}
						alt="SERHANT"
					/>
				</Box>
				<Box sx={sx.image(image)} />
				<Typography
					sx={{
						marginTop: '34px',
						color: '#3A3A3A',
						fontSize: '12px',
						fontWeight: '500',
					}}
				>
					{text}
				</Typography>
			</Box>
			<Box sx={sx.footerEmail}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={serhantFooter}
						alt="Serhant footer"
					/>
				</Box>
				<Typography
					sx={{
						marginTop: '12px',
						color: (theme) => theme.palette.bg.main,
						fontSize: '12px',
						fontWeight: '600',
					}}
				>
					2023, Serhant Inc.
				</Typography>
				<Typography
					sx={{
						marginTop: '5px',
						color: (theme) => theme.palette.bg.main,
						textAlign: 'center',
						fontSize: '12px',
						opacity: '0.6',
					}}
				>
					1600 Pennsylvania Avenue NW Washington, D.C. 20500
				</Typography>
				<Box sx={sx.footerSocialMedia}>
					<Facebook sx={{ width: 24, height: 24, color: (theme) => theme.palette.bg.main }} />
					<Twitter sx={{ width: 24, height: 24, color: (theme) => theme.palette.bg.main }} />
					<Instagram sx={{ width: 24, height: 24, color: (theme) => theme.palette.bg.main }} />
				</Box>
				<Button sx={sx.unsubscribeButton}>Unsubscribe</Button>
			</Box>
		</Box>
	);
};

export default memo(EmailPreview);
