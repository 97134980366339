import { auth } from './config';
import { signInWithPopup, OAuthProvider, signOut } from 'firebase/auth';

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
	prompt: 'consent',
	tenant: '78533c6a-7050-42e0-81e7-c21fcab6bf16',
});

class Authorization {
	static async loginWithMicrosoft() {
		signInWithPopup(auth, provider)
			.then(async (result) => {
				const accessToken = OAuthProvider.credentialFromResult(result).accessToken;
				const UID = result.user.uid;
				fetch(`${process.env.REACT_APP_API_BACKEND_URL}/setUserDetails?UID=${UID}&accessToken=${accessToken}`, {
					method: 'GET',
					redirect: 'follow',
				}).catch((error) => console.error('error', error));
			})
			.catch(() => {
				console.error('error');
			});
	}

	static async logout() {
		try {
			await signOut(auth);
		} catch (error) {
			console.log(error);
		}
	}
}

export default Authorization;
