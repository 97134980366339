import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import ProfileListingItem from '../../components/ProfilePage/ProfileListingItem/ProfileListingItem';
import { sx } from './listingItemPage.sx';
import Loader from '../../components/UI/Loader/Loader';

const ListingItemPage = ({
	activeListing,
	listings,
	handleToggleLikeListingItem,
	isMobile,
	listingsIsLoading,
	detailedListingData,
	detailedListingIsLoading,
}) => {
	return (
		<Box sx={sx.container}>
			<Typography
				variant="h2"
				sx={sx.title}
			>
				{activeListing?.UnparsedAddress}
			</Typography>
			{listingsIsLoading ? (
				<Box sx={{ height: '600px', width: '100%' }}>
					<Loader
						disableShrink={true}
						sx={{ height: '100%' }}
					/>
				</Box>
			) : (
				<ProfileListingItem
					activeListing={activeListing}
					listings={listings}
					handleToggleLikeListingItem={handleToggleLikeListingItem}
					isMobile={isMobile}
					isLiked={activeListing?.isLiked || false}
					detailedListingData={detailedListingData}
					detailedListingIsLoading={detailedListingIsLoading}
				/>
			)}
		</Box>
	);
};

export default memo(ListingItemPage);
