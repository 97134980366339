import { memo } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { sx } from './filterListingsChips.sx';

const FilterListingsChips = ({
	regions,
	filterOptions,
	filterChipsList,
	handleDeleteFilterField,
	isForMainPage = false,
}) => {
	if (!(regions?.length > 0 || Object.values(filterOptions || {}).filter((item) => !!item).length > 0)) {
		return null;
	}
	return (
		<Box sx={sx.container}>
			<Box
				sx={{
					...sx.chipsContainer,
					...(isForMainPage
						? {
								padding: {
									xs: '0 20px 0 20px',
									lg: '0 110px 0 110px',
								},
						  }
						: { padding: '20px 28px 20px 20px' }),
				}}
			>
				<Typography
					sx={{
						fontSize: '16px',
						fontWeight: '700',
					}}
					variant="body1"
				>
					Filters:
				</Typography>

				<Box sx={sx.chips}>
					{filterChipsList.map((filterField) => {
						const { city, field, label } = filterField;
						let valueWithFormat;
						if (city) {
							valueWithFormat = '';
						} else {
							valueWithFormat = typeof filterOptions[field] === 'number' ? ': ' + filterOptions[field] : '';
						}

						return (
							<Chip
								sx={sx.chipItem}
								key={label}
								label={`${label}${valueWithFormat}`}
								variant="outlined"
								onDelete={() => handleDeleteFilterField(field || filterField)}
							/>
						);
					})}
				</Box>
			</Box>
			<Box sx={sx.endGradient} />
		</Box>
	);
};

export default memo(FilterListingsChips);
