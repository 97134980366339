export const sx = {
	chatsList: {
		margin: { xs: '11px auto 0 auto', md: '11px 0 0 0' },
		width: 'fit-content',
		justifyContent: 'center',
		display: 'grid',
		gridTemplateColumns: { xs: '1fr', md: 'repeat(2, minmax(280px, 526px))' },
		gap: '17px',
	},
	chatListItem: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '526px',
		padding: '9px',
		borderRadius: '8px',
		border: (theme) => `1px solid ${theme.palette.bg.light}`,
		background: 'rgba(246, 248, 249, 0.50)',
	},
	chatLabel: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: 'normal',
	},
	initialPrompt: {
		marginTop: '10px',
		color: (theme) => theme.palette.text.quinternary,
		fontSize: '11px',
		lineHeight: '15px',
		maxHeight: '75px',
		overflowY: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: { xs: '100%' },
		overflow: 'hidden',
	},
	chatLink: {
		color: (theme) => theme.palette.bg.brand,
		fontSize: '11px',
		fontWeight: '500',
		lineHeight: '15px',
	},
	chatLength: {
		marginTop: '19px',
		color: (theme) => theme.palette.text.lightText,
		fontSize: '11px',
		lineHeight: '15px',
	},
};
