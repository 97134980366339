import { db } from '../firebase/config';
import { get, ref, child, update, set } from 'firebase/database';

/**
 * A utility class for interacting with the Firebase Realtime Database
 * to manage chat-related data.
 */
export default class ChatAPI {
	/**
	 * Fetches all chat history for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<Array<object>>} - An array of chat objects.
	 */
	static async getChats(UID) {
		const chatsRef = ref(db, `chatHistory/${UID}/`);
		const chatsSnapshot = await get(chatsRef);
		const chatsData = chatsSnapshot.val();

		return Object.values(chatsData || {}).map((chat) => chat);
	}

	/**
	 * Fetches a specific chat by its ID.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @param {string} chatId - The ID of the chat.
	 * @returns {Promise<object|null>} - The chat object, or null if not found.
	 */
	static async getChatById(UID, chatId) {
		const chat = await get(ref(db, `chatHistory/${UID}/${chatId}`));
		return chat.val();
	}

	/**
	 * Creates a new chat thread.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @param {object} chatThread - The chat thread object to be created.
	 * @returns {Promise<object>} - The created chat thread object.
	 */
	static async createChat(UID, chatThread) {
		await set(child(ref(db), `chatHistory/${UID}/${chatThread.id}`), chatThread);
		return chatThread;
	}

	/**
	 * Updates an existing chat thread.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @param {object} chatThread - The chat thread object to be updated.
	 * @returns {Promise<void>}
	 */
	static async updateChat(UID, chatThread) {
		await update(ref(db, `chatHistory/${UID}/${chatThread.id}`), chatThread);
	}

	/**
	 * Deletes all chat threads for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<void>}
	 */
	static async deleteAllChatThreads(UID) {
		await set(ref(db, `chatHistory/${UID}`), {});
	}
}
