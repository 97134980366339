import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import ListingItem from '../../components/CreateContentPage/ListingItem/ListingItem';
import { socialMediaButtonsProps } from '../../utils/creationContent/socialMediaTypes';
import { AddBoxOutlined } from '@mui/icons-material';
import sx from './createContentPage.sx';
import SocialMediaToggleCard from '../../components/CreateContentPage/SocialMediaToggleCard/SocialMediaToggleCard';
import { memo, useCallback, useMemo } from 'react';
import PreviewBlock from '../../components/CreateContentPage/PreviewBlocks/PreviewBlock';
import GenerationContentModal from '../../components/Modals/GenerationContentModal/GenerationContentModal';
import GeneratedResponseCard from '../../components/CreateContentPage/GeneratedResponseCard/GeneratedResponseCard';
import AddingListingsModal from '../../components/Modals/AddingListingsModal/AddingListingsModal';
import { getRandomPromptAdjective, promptAdjectives } from '../../utils/creationContent/promptAdjectives';
import ChosenListingsSkeleton from '../../components/Skeletons/ChosenListingsSkeleton';
import GeneratedResponseSkeleton from '../../components/Skeletons/GeneratedCardSkeleton';
import Loader from '../../components/UI/Loader/Loader';
import { useCreateContentPage } from './CreateContentPageProvider';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/config';

const CreateContentPage = () => {
	//@@viewOn:hooks
	const {
		socialMediaType,
		setSocialMediaType,
		showContentChoice,
		setShowContentChoice,
		showListings,
		setShowListings,
		prevListings,
		initialPrompt,
		generatedResponses,
		setInitialPrompt,
		handleCreatePost,
		handleRegenerateResponse,
		isLoading,
		chosenItem,
		chosenGeneratedResponse,
		setChosenGeneratedResponse,
		setIsAddingModalOpen,
		promptAdjective,
		setPromptAdjective,
		setChosenItem,
		isLoadingListings,
		isMobile,
	} = useCreateContentPage();
	//@@viewOff:hooks

	//@@viewOn:handlers
	const chosenItemPhoto = useMemo(() => {
		const chosenItemObj = prevListings.find((listing) => listing.Id === chosenItem);
		return chosenItemObj?.Media[0];
	}, [chosenItem, prevListings]);

	const handleSocialMediaToggle = useCallback(
		(type) => {
			setSocialMediaType(type);
		},
		[setSocialMediaType],
	);

	const isLoadingListingsMemoized = useMemo(() => isLoadingListings, [isLoadingListings]);
	const chosenGeneratedResponseMemoized = useMemo(
		() => ({
			...chosenGeneratedResponse,
			text:
				typeof chosenGeneratedResponse?.text === 'string'
					? chosenGeneratedResponse?.text
					: chosenGeneratedResponse?.text.AIresponse,
		}),
		[chosenGeneratedResponse],
	);

	const handleAddListingClick = () => {
		logEvent(analytics, 'open_add_listing_modal');
		setIsAddingModalOpen(true);
	};
	//@@viewOff:handlers

	return (
		<>
			<AddingListingsModal />
			<GenerationContentModal isOpen={isLoading} />
			<Box sx={sx.container}>
				<Box sx={sx.content}>
					<Box sx={sx.listingsBlock}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								borderBottom: '1px solid #D6D8E5',
								margin: { xs: '0 0 0 14px', md: '0 14px' },
							}}
						>
							<Typography
								variant="h3"
								sx={{ ...sx.blockLabel, borderBottom: 'none' }}
							>
								Listings
							</Typography>
							{isMobile && (
								<Button
									disableRipple={true}
									onClick={() => setShowListings((prev) => !prev)}
									variant="text"
									sx={sx.buttons.hideButton}
								>
									{showListings ? 'Hide' : 'Show'}
								</Button>
							)}
						</Box>
						{showListings && (
							<>
								{isLoadingListingsMemoized ? (
									<ChosenListingsSkeleton />
								) : (
									<Box sx={sx.chosenListingWrapper}>
										{prevListings.map((listing) => {
											const isChosen = chosenItem === listing.Id;
											return (
												<ListingItem
													setChosenItem={setChosenItem}
													isChosen={isChosen}
													listing={listing}
													key={listing.Id}
												/>
											);
										})}
									</Box>
								)}
							</>
						)}
						<Button
							variant="contained"
							disabled={isLoading}
							onClick={handleAddListingClick}
							sx={sx.buttons.addListingButton}
							endIcon={<AddBoxOutlined sx={{ width: '22px', height: '22px' }} />}
						>
							Add listing
						</Button>
					</Box>
					<Box sx={sx.createContentBlock}>
						<Typography
							variant="h3"
							sx={sx.blockLabel}
						>
							Create Content
						</Typography>
						<Box sx={{ padding: { xs: '0', md: '0 15px' } }}>
							<Box sx={{ borderTop: 'none', paddingTop: '15px', marginBottom: '25px' }}>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginLeft: { xs: '0', md: '6px' },
									}}
								>
									<Typography
										variant="h3"
										sx={sx.contentTypeLabel}
									>
										Content type
									</Typography>
									{!isMobile && (
										<Button
											variant="text"
											disableRipple={true}
											sx={sx.hideMediaChoiceButton}
											onClick={() => setShowContentChoice(!showContentChoice)}
										>
											{showContentChoice ? 'Hide' : 'Show'}
										</Button>
									)}
								</Box>
								{showContentChoice && !isMobile && (
									<Box sx={sx.mediaChoiceBlock}>
										{socialMediaButtonsProps.map((type) => (
											<SocialMediaToggleCard
												handleSocialMediaToggle={handleSocialMediaToggle}
												card={type}
												isActive={type.type === socialMediaType}
												key={type.type}
											/>
										))}
									</Box>
								)}
								{isMobile && (
									<Select
										MenuProps={{ disableScrollLock: true }}
										defaultValue={socialMediaButtonsProps[0].label}
										sx={sx.inputs.mediaChoiceSelect.sx}
										inputProps={sx.inputs.mediaChoiceSelect.inputProps}
										value={socialMediaType}
										onChange={(e) => handleSocialMediaToggle(e.target.value)}
									>
										{socialMediaButtonsProps.map((type) => {
											const value = type.label.toLowerCase();
											const capitalizedString = value.charAt(0).toUpperCase() + value.slice(1);

											return (
												<MenuItem
													value={type.type}
													key={type.type}
												>
													{capitalizedString}
												</MenuItem>
											);
										})}
									</Select>
								)}
							</Box>
							<Box sx={{ borderTop: '1px solid #D6D8E5', paddingTop: '15px', marginBottom: '25px' }}>
								<Typography
									variant="h3"
									sx={sx.contentTypeLabel}
								>
									Initial prompt
								</Typography>
								<TextField
									multiline
									minRows={5}
									value={initialPrompt}
									onChange={(e) => setInitialPrompt(e.target.value)}
									InputProps={sx.inputs.promptTextarea.inputProps}
									sx={sx.inputs.promptTextarea.sx}
								/>
								<Box sx={sx.contentControlsBlock}>
									<Box sx={sx.contentControlsButtonsBlock}>
										<Button
											variant="contained"
											sx={sx.buttons.generateFromPromptButton}
											onClick={() => handleCreatePost(promptAdjective)}
											disabled={isLoading}
										>
											Generate from prompt
										</Button>
										<Button
											disabled={isLoading}
											variant="outlined"
											onClick={() => handleCreatePost(getRandomPromptAdjective())}
											sx={sx.buttons.randomizeButton}
										>
											Randomize
										</Button>
									</Box>
									<Select
										value={promptAdjective}
										onChange={(e) => setPromptAdjective(e.target.value)}
										displayEmpty
										renderValue={(selected) => {
											if (selected.length === 0) {
												return <p style={{ margin: 0 }}>Adjective</p>;
											}
											return selected[0].toUpperCase() + selected.substring(1);
										}}
										MenuProps={{ disableScrollLock: true }}
										disabled={isLoading}
										sx={sx.inputs.promptTypeSelect.sx}
										inputProps={sx.inputs.promptTypeSelect.inputProps}
									>
										<MenuItem
											disabled
											value=""
										>
											Prompt adjective
										</MenuItem>
										{promptAdjectives.map(({ value, label }) => (
											<MenuItem
												value={value}
												key={value}
											>
												{label}
											</MenuItem>
										))}
									</Select>
								</Box>
							</Box>
							{!isMobile && (
								<Box sx={{ borderTop: '1px solid #D6D8E5', paddingTop: '15px', marginBottom: '25px' }}>
									<Typography
										variant="h3"
										sx={{ ...sx.contentTypeLabel, marginBottom: '15px' }}
									>
										Generated responses
									</Typography>
									{isLoadingListingsMemoized && <GeneratedResponseSkeleton />}
									{generatedResponses.map((response) => (
										<GeneratedResponseCard
											handleRegenerateResponse={handleRegenerateResponse}
											setChosenGeneratedResponse={setChosenGeneratedResponse}
											response={response}
											key={response.id}
										/>
									))}
								</Box>
							)}
						</Box>
					</Box>
					<Box sx={sx.previewContentBlock}>
						<Box
							sx={{
								margin: { xs: '0 14px', md: '0 15px 0 10px' },
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								borderBottom: '1px solid #D6D8E5',
							}}
						>
							<Typography
								variant="h3"
								sx={{
									...sx.blockLabel,
									borderBottom: 'none',
								}}
							>
								Preview
							</Typography>
						</Box>
						<Box
							sx={{
								padding: '10px 14px 0 14px',
								marginBottom: '20px',
							}}
						>
							{isLoadingListingsMemoized ? (
								<Loader sx={{ height: '300px' }} />
							) : (
								<>
									{chosenGeneratedResponseMemoized ? (
										<PreviewBlock
											type={socialMediaType}
											image={chosenItemPhoto}
											text={chosenGeneratedResponseMemoized.text}
										/>
									) : (
										<Box sx={sx.noContentBlock}>No A.I. Generated Content yet</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default memo(CreateContentPage);
