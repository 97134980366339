export const sx = {
	container: {
		minHeight: 'calc(100vh - 70px)',
		position: 'absolute',
		top: '70px',
		left: 0,
		right: 0,
		bottom: 0,
		overflowY: 'auto',
		backgroundColor: (theme) => theme.palette.bg.main,
		zIndex: 1,
	},
	content: {
		margin: '33px 0',
		minHeight: '100%',
	},
	activeViewContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		margin: '0 auto',
		padding: '20px',
	},
	label: {
		paddingLeft: { xs: '20px', sm: '60px', xl: '20px' },
		color: (theme) => theme.palette.text.deepTertiary,
		fontSize: '18px',
		fontWeight: '700',
		width: '100%',
		maxWidth: '1100px',
	},
};
