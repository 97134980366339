import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';

const sx = {
	regenerateResponseButton: {
		display: 'flex',
		width: '230px',
		height: '46px',
		margin: '0 auto 16px auto',
		padding: '10px 21px',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderRadius: '8px',
		background: (theme) => theme.palette.bg.main,
	},
	chatControls: {
		width: '100%',
		maxWidth: '799px',
		paddingRight: '20px',
		height: '210px',
		background: 'transparent',
		position: 'relative',
	},
};

const ChatMessagesSkeleton = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'space-between',
				alignItems: 'flex-start',
				width: '100%',
				height: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '18px',
					padding: { xs: '0 11px 20px 0', md: '0 20px 20px 0' },
					overflowY: 'hidden',
					width: '100%',
					height: '100%',
					maxHeight: 'calc(100% - 210px)',
				}}
			>
				{[1, 2, 3, 4, 5].map((index) => (
					<Box
						key={index}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							gap: '20px',
							width: '100%',
						}}
					>
						<Skeleton
							variant="circular"
							animation="wave"
							width={31}
							height={31}
							sx={{
								minWidth: '31px',
								minHeight: '31px',
							}}
						/>
						<Skeleton
							animation="wave"
							variant="rectangular"
							height={100}
							sx={{
								flexGrow: 1,
								borderRadius: '12px',
								width: '100%',
								maxWidth: '600px',
								marginRight: '42px',
							}}
						/>
					</Box>
				))}
			</Box>
			<Box sx={sx.chatControls}>
				<Skeleton
					animation="wave"
					variant="rectangular"
					sx={sx.regenerateResponseButton}
				/>
				<Skeleton
					variant="rectangular"
					animation="wave"
					sx={{
						width: '100%',
						height: '148px',
						borderRadius: '4px',
					}}
				/>
			</Box>
		</Box>
	);
};

export default memo(ChatMessagesSkeleton);
