import { useProfilePage } from '../../../pages/ProfilePage/ProfilePageProvider';
import { sx } from './profileGeneratedImages.sx';
import { Box } from '@mui/material';
import { memo } from 'react';
import Loader from '../../UI/Loader/Loader';
import GeneratedImageItem from '../GeneratedImageItem/GeneratedImageItem';
import ProfileEmptyView from '../ProfileEmptyView/ProfileEmptyView';
import { ImageNotSupported } from '@mui/icons-material';
import { useDocumentTitle } from 'usehooks-ts';

const ProfileGeneratedImages = () => {
	const { generatedImages, generatedImagesIsLoading, handleToggleLikeGeneratedImage } = useProfilePage();
	useDocumentTitle(`Profile Generated Images | S.GPT`);

	return (
		<>
			{generatedImagesIsLoading && (
				<Loader
					disableShrink={true}
					sx={{ height: '600px', width: '100%' }}
				/>
			)}
			<Box sx={sx.generatedItemsList}>
				{!generatedImagesIsLoading && (
					<>
						{(generatedImages || []).map((generatedItem) => (
							<GeneratedImageItem
								handleToggleLikeGeneratedImage={handleToggleLikeGeneratedImage}
								generatedItem={generatedItem}
								key={generatedItem.id}
							/>
						))}
					</>
				)}
			</Box>
			{!generatedImages?.length && (
				<ProfileEmptyView
					text="No generated images found"
					Icon={ImageNotSupported}
				/>
			)}
		</>
	);
};

export default memo(ProfileGeneratedImages);
