export const sx = {
	container: {
		padding: '20px 0 30px 0',
		background: (theme) => theme.palette.bg.main,
		borderRadius: '12px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
		boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
	},
	image: (imageProp) => ({
		marginTop: '15px',
		...(imageProp && { backgroundImage: `url(${imageProp})` }),
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100%',
		minHeight: '420px',
	}),
};
