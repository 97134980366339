import { useEffect, useState } from 'react';
import EditingImagesAPI from '../../services/editingImagesAPI';
import { toast } from 'react-toastify';

const useGenerateImage = ({
	setItemForEditing,
	itemForEditing,
	setInitialItemForEditing,
	setIsItemGenerated,
	setGeneratedItemsList,
	UID,
}) => {
	const [isGeneratingImage, setIsGeneratingImage] = useState(false);
	const [progress, setProgress] = useState(0);
	const [messageId, setMessageId] = useState(null);
	const [loadbalancerId, setLoadbalancerId] = useState(null);

	const [filtersForCreation, setFiltersForCreation] = useState(null);

	useEffect(() => {
		let isMounted = true;
		let timeout = 10000;
		let timestampStart = new Date();

		const generateResult = async () => {
			if (!messageId) return;

			if (isMounted) {
				setIsGeneratingImage(true);
			}

			if (new Date() - timestampStart > 30000 && timeout > 5000) {
				timeout = 5000;
			}

			const data = await EditingImagesAPI.getGenerationResult(messageId, loadbalancerId);
			const generatedImages = data?.response?.imageUrls;
			const isFinishedWithError = !data?.response?.imageUrls?.length;

			if (isMounted) {
				if (data?.progress === 100 && isFinishedWithError) {
					setIsGeneratingImage(false);
					setMessageId(null);
					setLoadbalancerId(null);

					const errorMessage = (data?.response?.description || data?.response?.content).slice(0, 200);
					toast.error(errorMessage);
					return;
				}

				if (data?.progress === 100) {
					setInitialItemForEditing(itemForEditing);
					setItemForEditing(generatedImages[0]);
					setIsItemGenerated(true);
					setIsGeneratingImage(false);
					setMessageId(null);
					setLoadbalancerId(null);

					setGeneratedItemsList(generatedImages);
					setProgress(0);

					timeout = 10000;

					EditingImagesAPI.saveGeneratedImagesResponse(filtersForCreation, generatedImages, UID);
				} else if (progress === 'incomplete') {
					setIsGeneratingImage(false);
					setMessageId(null);
					setLoadbalancerId(null);

					toast.error('We are sorry, but our service has high demand. Please try again later');
				} else {
					if (data?.progress !== 0) {
						setProgress(data?.progress);
						timeout = data?.progress > 50 ? 2000 : 4000;
					}

					await wait(timeout);

					generateResult();
				}
			}
		};

		generateResult();

		return () => {
			setIsGeneratingImage(false);
			isMounted = false;
		};
	}, [messageId, setItemForEditing]);

	const startImageGeneration = async (msg, filters) => {
		try {
			setIsGeneratingImage(true);
			const response = await EditingImagesAPI.generateNewPhoto(msg);

			if (response.success) {
				setMessageId(response.messageId);
				setLoadbalancerId(response.loadBalanceId);
				setFiltersForCreation(filters);
			}
		} catch (e) {
			toast.error('Some error has occurred, try later');
		}
	};

	return {
		isGeneratingImage,
		progress,
		startImageGeneration,
	};
};

export default useGenerateImage;

async function wait(timeout) {
	return new Promise((resolve) => setTimeout(resolve, timeout));
}
