/**
 * Formats a number by adding commas as thousands separators.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number with commas as thousands separators.
 */
export default function makeNumberWithComas(number) {
	const numberString = String(number);
	const parts = numberString.split('.');
	const integerPart = parts[0];

	const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.length > 1 ? numberWithCommas + '.' + parts[1] : numberWithCommas;
}
