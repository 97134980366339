import logo from '../../assets/logos/logoGray.svg';
import { Box, Link } from '@mui/material';
import { memo } from 'react';

//@@viewOn:private
const navLinkItemSx = {
	typography: 'body2',
	textDecoration: 'none',
	color: (theme) => theme.palette.text.primary,
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '12px',
	lineHeight: '17px',
	letterSpacing: '0.17px',
};
//@@viewOff:private

const Footer = () => {
	return (
		<Box
			sx={{
				opacity: 'inherit',
				transition: 'opacity 2s ease-in-out',
				height: '151px',
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
			}}
		>
			<Box
				sx={{
					borderTop: '1px solid gray',
					width: '100%',
					height: '68px',
					margin: { xs: '0 20px', lg: '0 96px' },
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}
			>
				<Box
					sx={{
						width: '89px',
						height: '26px',
						display: 'flex',
						img: {
							maxWidth: '100%',
							maxHeight: '100%',
						},
					}}
				>
					<img
						src={logo}
						alt="logo"
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: 'fit-content',
						gap: '10px',
						marginBottom: '9px',
					}}
				>
					<Link
						to="/terms"
						sx={navLinkItemSx}
					>
						Terms
					</Link>
					<Link
						sx={navLinkItemSx}
						to="/privacy"
					>
						Privacy
					</Link>
					<Link
						sx={navLinkItemSx}
						to="/guide"
					>
						How to use
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(Footer);
