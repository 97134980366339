export const promptAdjectives = [
	{ value: 'funny', label: 'Funnier' },
	{ value: 'luxurious', label: 'Luxurious' },
	{ value: 'casual', label: 'Casual' },
	{ value: 'bold', label: 'Bolder' },
	{ value: 'whimsical', label: 'Whimsical' },
	{ value: 'professional', label: 'Professional' },
	{ value: 'longer', label: 'Longer' },
	{ value: 'short', label: 'Shorter' },
];

/**
 * Retrieves a random prompt adjective from the available list.
 *
 * @returns {string} A randomly selected prompt adjective.
 */
export const getRandomPromptAdjective = () => {
	const randomIndex = Math.floor(Math.random() * promptAdjectives.length);
	return promptAdjectives[randomIndex].value;
};
