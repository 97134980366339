import { axiosInstance } from './axios';

/**
 * A utility class for interacting with the API to manage room listings-related data.
 */
export default class RoomsAPI {
	static endpoint = process.env.REACT_APP_API_BACKEND_URL;

	/**
	 * Fetches room listings using the provided request body.
	 *
	 * @param {object} reqBody - The request body containing filter options.
	 * @returns {Promise<Array<object>>} - An array of room listing objects.
	 */
	static async getRoomsPost(reqBody = {}) {
		const response = await axiosInstance.post(`${this.endpoint}/getListings`, reqBody);
		const data = await response.data;
		return data.length ? data : Object.values(data) || [];
	}

	static async getListingItemById(id) {
		const response = await axiosInstance.post(`${this.endpoint}/callProxySLAPI?id=${id}`);
		return await response.data;
	}
}
