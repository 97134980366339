/**
 * Retrieves context messages from a chat thread with an option to exclude the last assistant message.
 *
 * @param {Object} chatThread - The chat thread containing the messages.
 * @param {boolean} [isRegenerating=false] - Flag indicating whether the context is being regenerated without the last assistant message.
 * @returns {Array} An array of context messages.
 */
export const getContextMessages = (chatThread, isRegenerating = false) => {
	let chatString = chatThread.chatString;

	if (isRegenerating) {
		if (chatString[chatString.length - 1].role === 'assistant') {
			chatString.splice(chatString.length - 1, 1);
		}
	}

	const firstElement = chatString[0];

	if (chatString.length > 10) {
		const lastNineElements = chatString.slice(-9);
		return [firstElement, ...lastNineElements];
	} else {
		return chatString;
	}
};
