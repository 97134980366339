import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase/config';
import DataResolver from '../Layout/DataResolver';

const AuthorizationContext = createContext(null);

const AuthorizationLayer = ({ children }) => {
	//@@viewOn:hooks
	const location = useLocation();
	const navigate = useNavigate();
	const [userData, setUserData] = useState(undefined);
	//@@viewOff:hooks

	//@@viewOn:effects
	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				setUserData(user);
				navigate('/login');
			} else {
				setUserData({
					UID: user.uid,
					email: user.email,
					displayName: user.displayName,
					photoURL: user.photoURL,
				});
			}
		});
	}, [location.pathname]);
	//@@viewOn:effects

	const contextValue = useMemo(() => {
		return { ...userData };
	}, [userData]);

	return (
		<DataResolver
			data={userData}
			loading={userData === undefined}
			error={null}
		>
			<AuthorizationContext.Provider value={contextValue}>{children}</AuthorizationContext.Provider>
		</DataResolver>
	);
};

const useAuthorization = () => {
	const context = useContext(AuthorizationContext);
	if (context === undefined) {
		throw new Error('useAuthorization must be used within a AuthorizationLayer');
	}
	return context;
};

export default memo(AuthorizationLayer);
export { useAuthorization };
