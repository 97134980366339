import { axiosInstance } from './axios';
import { get, ref, set } from 'firebase/database';
import { db } from '../firebase/config';

/**
 * A utility class for interacting with the API to manage creation-related data.
 */
export default class CreationAPI {
	static endpoint = process.env.REACT_APP_API_BACKEND_URL;

	/**
	 * Creates a social media post using the provided request body.
	 *
	 * @param {object} reqBody - The request body containing post details.
	 * @returns {Promise<object>} - The response data of the created post.
	 */
	static async createSocialPost(reqBody = {}) {
		const response = await axiosInstance.post(`${this.endpoint}/createSocialPost`, reqBody);
		return await response.data;
	}

	/**
	 * Fetches chosen listings for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<Array<object>>} - An array of chosen listings.
	 */
	static async getChosenListings(UID) {
		const chosenListingsRef = ref(db, `chosenListings/${UID}/`);
		const chosenListingsSnapshot = await get(chosenListingsRef);

		const listings = chosenListingsSnapshot.val();
		if (!listings) return [];

		return listings?.length ? listings : Object.values(listings);
	}

	/**
	 * Updates the list of chosen listings for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @param {Array<object>} chosenListingsList - The updated list of chosen listings.
	 * @returns {Promise<void>}
	 */
	static async updateChosenListingsList(UID, chosenListingsList) {
		await set(ref(db, `chosenListings/${UID}/`), chosenListingsList);
	}

	/**
	 * Deletes all chosen listings for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<void>}
	 */
	static async deleteAllChosenListing(UID) {
		await set(ref(db, `chosenListings/${UID}`), {});
	}

	/**
	 * Handles the process of choosing a listing.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @param {object} room - The room listing to be chosen.
	 * @returns {Promise<void>}
	 */
	static async handleChooseListing(UID, room) {
		const filteredMedia = room.Media.length > 1 ? room.Media.slice(1, 5) : room.Media;
		try {
			const chosenListings = (await CreationAPI.getChosenListings(UID)) || [];
			const newListingItem = { ...room, Media: filteredMedia, generatedResponses: [] };

			const hasExistingItem = chosenListings.some((listing) => listing.Id === newListingItem.Id);
			if (!hasExistingItem) {
				if (chosenListings.length >= 5) {
					await CreationAPI.updateChosenListingsList(UID, [...chosenListings.slice(1), newListingItem]);
				} else {
					await CreationAPI.updateChosenListingsList(UID, [...chosenListings, newListingItem]);
				}
			}
		} catch (error) {
			console.log(error);
			return error;
		}
	}
}
