export const sx = {
	controllers: {
		position: 'relative',
		margin: { xs: '75px 20px 0 20px', lg: '83px 110px 0 110px' },
		height: 'fit-content',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: '10px',
		borderBottom: (theme) => `1px solid ${theme.palette.stroke.custom2}`,
	},
	addingListingsControllers: {
		margin: '0 20px',
		width: 'calc(100% - 40px)',
		position: 'relative',
		height: 'fit-content',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: '10px',
		borderBottom: (theme) => `1px solid ${theme.palette.stroke.custom2}`,
	},
	regionSelect: {
		sx: {
			fieldset: {
				border: (theme) => `1px solid ${theme.palette.stroke.custom1} !important`,
			},
		},
		inputProps: {
			sx: {
				width: '124px',
				height: '35px',
				borderRadius: '8px',
				div: {
					svg: {
						display: 'none !important',
					},
				},
			},
		},
		inputLabelProps: {
			sx: {
				color: (theme) => `${theme.palette.text.quinternary} !important`,
				position: 'relative',
				top: { xs: '13px', md: '15px' },
			},
			classes: {
				shrink: {
					bottom: '-23px !important',
				},
			},
		},
	},
	filterButtonsWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		justifyContent: 'center',
		gap: '9px',
	},
	filterButton: {
		alignItems: 'center',
		padding: '8px 13px',
		width: 'max-content',
		height: '36px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom1} !important`,
		boxShadow: (theme) => `0px 4px 4px ${theme.palette.stroke.custom7}`,
		borderRadius: '6px',
		color: (theme) => theme.palette.text.quinternary,
	},
};
