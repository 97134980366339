import { useEffect, useLayoutEffect, useMemo } from 'react';
import useScreen from '../useScreenSize/useScreenSize';
import { useQuery } from '@tanstack/react-query';
import { getFilterOptionsBody } from '../../utils/listing/getPropertyTypesForFilter';
import RoomsAPI from '../../services/roomsAPI';
import { toast } from 'react-toastify';
import useDebounce from '../useDebounce/useDebounce';

const useHandleListings = ({
	regions,
	rooms,
	currentPage,
	filterOptions,
	setCurrentPage,
	setRooms,
	showOnlyAgentsListings,
	favoriteListingsIds = [],
}) => {
	const screenSize = useScreen();
	const isBigScreen = useMemo(() => screenSize === 'xl' || screenSize === 'l', [screenSize]);
	const listingsPerPage = useMemo(() => (isBigScreen ? 20 : 10), [isBigScreen]);

	const memoizedFiltersAndRegions = useMemo(() => {
		return {
			filterOptions,
			regions,
			showOnlyAgentsListings,
		};
	}, [filterOptions, regions]);

	const debouncedParams = useDebounce(memoizedFiltersAndRegions, 700);

	const { isLoading, data, error, isFetching } = useQuery({
		queryKey: ['getListingsRooms', debouncedParams],
		queryFn: async () => {
			let filters;

			if (!debouncedParams.filterOptions) {
				filters = getFilterOptionsBody(null);
			}

			const body = {
				...(debouncedParams.filterOptions ? debouncedParams.filterOptions : filters),
				regionsSelected: debouncedParams.regions || [],
				showOnlyAgentsListings: debouncedParams.showOnlyAgentsListings,
			};
			return RoomsAPI.getRoomsPost(body);
		},
		onSuccess: (data) => {
			setCurrentPage(1);
			setRooms(data.slice(0, currentPage * listingsPerPage));
		},
		onError: (error) => {
			const errorMessage = error.toString();
			toast.error('Could not get listings');
			console.log('error', errorMessage);
		},
	});

	useLayoutEffect(() => {
		setCurrentPage(1);
	}, [location.pathname, showOnlyAgentsListings]);

	useEffect(() => {
		if (isLoading || isFetching || rooms.length >= data?.length || data === undefined) return;

		const startIndex = (currentPage - 1) * listingsPerPage;
		const endIndex = currentPage * listingsPerPage;

		if (currentPage === 1) {
			setRooms(data.slice(startIndex, endIndex));
		} else {
			setRooms((prevRooms) => [...prevRooms, ...data.slice(startIndex, endIndex)]);
		}
	}, [currentPage]);

	const userListings = useMemo(() => {
		return data?.filter((room) => favoriteListingsIds.includes(room.Id));
	}, [favoriteListingsIds, data]);

	return { isLoading, isFetching, data, userListings, error };
};

export default useHandleListings;
