import { Box, Button, Typography } from '@mui/material';
import { memo } from 'react';

//@@viewOn:private
const textSx = {
	color: (theme) => theme.palette.text.primary,
	fontSize: '24px',
};
//@@viewOff:private

const SquareChoosingBlock = ({ squareLabel = '', squareValue, setFieldValue, name }) => {
	//@@viewOn:handlers
	const handleChange = (value) => {
		setFieldValue(name, value);
	};
	//@@viewOff:handlers

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				flexWrap: 'wrap',
				gap: '10px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				}}
			>
				<Typography
					variant="body1"
					sx={{
						...textSx,
						fontSize: '18px',
					}}
				>{`${squareLabel} square footage`}</Typography>
				<Typography
					variant="body1"
					sx={{
						...textSx,
						fontSize: '14px',
						lineHeight: '16px',
						opacity: '0.4',
						maxWidth: '300px',
					}}
				>
					{`The ${squareLabel.toLowerCase()} square footage of the property`}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: '268px',
					gap: '17px',
				}}
			>
				<Button
					disabled={squareValue <= 0}
					disableRipple={true}
					onClick={() => handleChange(+squareValue - 200)}
					sx={{
						...textSx,
						display: 'flex',
						width: '53px',
						height: '53px',
						padding: '12px 0px 17px 0px',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '90px',
						border: (theme) => `1px solid ${theme.palette.stroke.gray}`,
						background: (theme) => theme.palette.bg.main,
						fontWeight: '300',
					}}
				>
					-
				</Button>
				<Box
					sx={{
						display: 'flex',
						minWidth: '77px',
						justifyContent: 'center',
					}}
				>
					<Typography sx={textSx}>{squareValue}</Typography>
					<Typography
						sx={{
							...textSx,
							color: (theme) => theme.palette.text.muted,
							fontSize: '16px',
						}}
					>
						Sqft
					</Typography>
				</Box>
				<Button
					disableRipple={true}
					onClick={() => handleChange(+squareValue + 200)}
					sx={{
						...textSx,
						display: 'flex',
						width: '53px',
						height: '53px',
						padding: '12px 0px 17px 0px',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '90px',
						border: (theme) => `1px solid ${theme.palette.stroke.gray}`,
						background: (theme) => theme.palette.bg.main,
						fontWeight: '300',
					}}
				>
					+
				</Button>
			</Box>
		</Box>
	);
};

export default memo(SquareChoosingBlock);
