import { useProfilePage } from '../../../pages/ProfilePage/ProfilePageProvider';
import Loader from '../../UI/Loader/Loader';
import { Box } from '@mui/material';
import { sx } from './profileListings.sx';
import { memo } from 'react';
import RoomMainListItem from '../../MainPage/RoomMainListItem/RoomMainListItem';
import { DomainDisabled } from '@mui/icons-material';
import ProfileEmptyView from '../ProfileEmptyView/ProfileEmptyView';
import { useDocumentTitle } from 'usehooks-ts';

const ProfileListings = () => {
	const { listingsIsLoading, favoriteListings } = useProfilePage();
	useDocumentTitle(`Profile Favorite Listings | S.GPT`);

	return (
		<>
			{listingsIsLoading && (
				<Loader
					disableShrink={true}
					sx={{ height: '600px', width: '100%' }}
				/>
			)}
			<Box sx={sx.generatedItemsList}>
				{!listingsIsLoading && (
					<>
						{(favoriteListings || []).map((listing) => {
							return (
								<RoomMainListItem
									isUsedInProfilePage={true}
									isLiked={true}
									room={listing}
									key={listing.Id}
								/>
							);
						})}
					</>
				)}
			</Box>
			{!favoriteListings?.length && !listingsIsLoading && (
				<ProfileEmptyView
					text="No favorite listings found"
					Icon={DomainDisabled}
				/>
			)}
		</>
	);
};

export default memo(ProfileListings);
