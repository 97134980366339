import { Box, Typography } from '@mui/material';
import heart from '../../../../assets/icons/heart-tt.png';
import message from '../../../../assets/icons/message-tt.png';
import bookmark from '../../../../assets/icons/bm-tt.png';
import share from '../../../../assets/icons/send-tt.png';
import { memo } from 'react';

const TikTokMediaActions = () => {
	const iconWrapperSx = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		opacity: '0.89',
		padding: '12px',
		width: '44px',
		height: '44px',
		backgroundColor: '#F1F1F2',
		img: {
			width: '20px',
			height: '20px',
			objectFit: 'contain',
		},
	};

	const icons = [
		{
			icon: heart,
			title: '18K',
		},
		{
			icon: message,
			title: '1076',
		},
		{
			icon: bookmark,
			title: '1023',
		},
		{
			icon: share,
			title: '290',
		},
	];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '18px',
				marginBottom: '10px',
			}}
		>
			{icons.map((iconBlock, index) => (
				<Box
					sx={{
						width: '44px',
						height: '69px',
					}}
					key={index}
				>
					<Box sx={iconWrapperSx}>
						<img
							src={iconBlock.icon}
							alt="icon"
						/>
					</Box>
					<Typography
						sx={{
							marginTop: '10px',
							color: '#6D6F76',
							fontSize: '12px',
							fontWeight: '600',
							textAlign: 'center',
						}}
					>
						{iconBlock.title}
					</Typography>
				</Box>
			))}
		</Box>
	);
};

export default memo(TikTokMediaActions);
