import { Box, IconButton, Modal, Typography } from '@mui/material';
import { sx } from './profileListingItem.sx';
import { memo } from 'react';
import Loader from '../../UI/Loader/Loader';
import { Favorite, FavoriteBorder, RemoveRedEyeOutlined } from '@mui/icons-material';
import ProgressiveImage from '../../UI/ProgressiveImage/ProgressiveImage';
import roomsIcon from '../../../assets/icons/roomsIcon.svg';
import livingRoomsIcon from '../../../assets/icons/livingRoomsIcon.svg';
import bathroomsIcon from '../../../assets/icons/bathroomsIcon.svg';
import bedroomsIcon from '../../../assets/icons/bedroomsIcon.svg';
import { useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import ListingsMap from '../../common/ListingsMap/ListingsMapContainer';
import makeNumberWithComas from '../../../utils/common/makeNumberWithComas';

const ProfileListingItem = ({
	listings,
	activeListing,
	isLiked = false,
	handleToggleLikeListingItem,
	containerSx = {},
	detailedListingData,
	detailedListingIsLoading,
}) => {
	if (activeListing === null || detailedListingIsLoading || detailedListingData === undefined) {
		return (
			<Loader
				key="detailedListingLoader"
				sx={{ height: '600px' }}
				disableShrink={true}
			/>
		);
	}

	if (activeListing === undefined && !detailedListingIsLoading && !detailedListingData) {
		return (
			<Box sx={{ ...sx.container, ...containerSx }}>
				<h1>Listing is not available</h1>
			</Box>
		);
	}

	if (!activeListing || !detailedListingData) {
		return (
			<Loader
				key="detailedListingLoader"
				sx={{ height: '600px' }}
				disableShrink={true}
			/>
		);
	}

	const {
		Id,
		ListPrice,
		Media,
		UnparsedAddress,
		BathroomsFull,
		BedroomsTotal,
		LivingArea,
		PropertySubType,
		ListingKey,
		description,
		roomsTotal,
	} = activeListing;

	const mediaList = (Media?.slice(1, 6) || []).filter((image) => !!image);
	const [isOpenViewer, setIsOpenViewer] = useState(false);

	const handleOpenViewer = () => {
		setIsOpenViewer(true);
	};

	const handleCloseViewer = () => {
		setIsOpenViewer(false);
	};

	return (
		<>
			{isOpenViewer && (
				<Modal
					open={isOpenViewer}
					onClose={() => handleOpenViewer()}
				>
					<Box>
						<Lightbox
							onClose={handleCloseViewer}
							zoomStep={0.2}
							showTitle={false}
							doubleClickZoom={2}
							images={Media}
						/>
					</Box>
				</Modal>
			)}

			<Box sx={{ ...sx.container, ...containerSx }}>
				<Box sx={sx.photosAndDetails}>
					<Box sx={sx.imagesWrapper}>
						<Box sx={sx.mainImageWrapper}>
							<img
								style={sx.mainImage}
								src={Media?.[0] || null}
								alt={UnparsedAddress}
							/>
							<Box sx={sx.mainImageActions}>
								<IconButton
									sx={sx.actionButton}
									onClick={handleOpenViewer}
								>
									<RemoveRedEyeOutlined sx={sx.actionButtonIcon} />
								</IconButton>
								<IconButton
									sx={sx.actionButton}
									onClick={() => handleToggleLikeListingItem(!isLiked, Id)}
								>
									{isLiked ? <Favorite sx={sx.actionButtonIcon} /> : <FavoriteBorder sx={sx.actionButtonIcon} />}
								</IconButton>
							</Box>
						</Box>
						<Box sx={sx.imagesGallery}>
							{mediaList.map((image, index) => (
								<Box
									sx={sx.galleryImageItem}
									key={image}
								>
									<ProgressiveImage
										src={image}
										style={sx.galleryImageItem}
										alt={UnparsedAddress + index}
										key={index}
									/>
								</Box>
							))}
						</Box>
					</Box>
					<Box sx={sx.detailsBlock}>
						<Typography sx={sx.blockTitle}>Listing Details</Typography>
						<Box sx={sx.detailsList}>
							<Box sx={sx.detailsItem}>
								<img
									src={roomsIcon}
									alt="Rooms"
								/>
								<Typography sx={sx.detailsItemLabel}>{(roomsTotal || '-') + ' Rooms'}</Typography>
							</Box>
							<Box sx={sx.detailsItem}>
								<img
									src={bedroomsIcon}
									alt="Bedrooms"
								/>
								<Typography sx={sx.detailsItemLabel}>{(BedroomsTotal || '-') + ' Bedrooms'}</Typography>
							</Box>
							<Box sx={sx.detailsItem}>
								<img
									src={bathroomsIcon}
									alt="Bathrooms"
								/>
								<Typography sx={sx.detailsItemLabel}>{(BathroomsFull || '-') + ' Bathrooms'}</Typography>
							</Box>
							<Box sx={sx.detailsItem}>
								<img
									src={livingRoomsIcon}
									alt="Living rooms"
								/>
								<Typography sx={sx.detailsItemLabel}>{'-' + ' Living rooms'}</Typography>
							</Box>
						</Box>
					</Box>
					<Box sx={sx.detailsBlock}>
						<Typography sx={sx.blockTitle}>Listing information</Typography>
						<Box sx={sx.detailsList}>
							<Box sx={sx.listingInfoItem}>
								<Typography sx={sx.infoLabel}>LISTING PRICE</Typography>
								<Typography sx={sx.infoValue}>{'$' + makeNumberWithComas(ListPrice)}</Typography>
							</Box>
							<Box sx={sx.listingInfoItem}>
								<Typography sx={sx.infoLabel}>PROPERTY SUBTYPE</Typography>
								<Typography sx={sx.infoValue}>{PropertySubType || '-'}</Typography>
							</Box>
							<Box sx={sx.listingInfoItem}>
								<Typography sx={sx.infoLabel}>LIVING AREA</Typography>
								<Typography sx={sx.infoValue}>{(LivingArea || '-') + ' Sqft'}</Typography>
							</Box>
							<Box sx={sx.listingInfoItem}>
								<Typography sx={sx.infoLabel}>LISTING ID</Typography>
								<Typography sx={sx.infoValue}>{ListingKey}</Typography>
							</Box>
						</Box>
					</Box>
					{/* TODO: Add features block, for now is disabled since api is not ready*/}
					{/*<Box sx={{ ...sx.detailsBlock, border: 'none' }}>*/}
					{/*	<Typography sx={sx.blockTitle}>Features</Typography>*/}
					{/*	<Box sx={sx.featuresList}>*/}
					{/*		<li style={sx.detailsItemLabel}>Doorman</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Elevator</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Pets</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Prewar</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Building Laundry</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Live-in Super</li>*/}
					{/*		<li style={sx.detailsItemLabel}>Building Storage</li>*/}
					{/*	</Box>*/}
					{/*</Box>*/}
				</Box>
				<Box sx={sx.mapAndDescription}>
					<Box sx={sx.mapWrapper}>
						<ListingsMap
							selectedListingItem={activeListing}
							listings={listings}
						/>
					</Box>
					<Box sx={sx.descriptionWrapper}>
						<Typography sx={sx.descriptionTitle}>The Property</Typography>
						<Typography sx={sx.descriptionText}>{description}</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default memo(ProfileListingItem);
