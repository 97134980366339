import { useMapEvent } from 'react-leaflet';

const ListingMapControls = ({ setSelectedLayer }) => {
	useMapEvent('baselayerchange', (e) => {
		setSelectedLayer(e.name);
	});
	return null;
};

export default ListingMapControls;
