export const sx = {
	header: {
		width: '100%',
		background: (theme) => theme.palette.bg.light,
		padding: {
			xs: '20px 20px 0 20px',
			sm: '20px 30px 0 30px',
			md: '20px 50px 0 50px',
			lg: ' 20px 200px 0 200px',
			xl: ' 20px 332px 0 332px',
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		gap: '18px',
	},
	headerProfile: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '11px',
	},
	profileImage: {
		width: '51px',
		height: '51px',
	},
	profileInfo: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		height: 'fit-content',
	},
	profileUserName: {
		color: (theme) => theme.palette.stroke.brand,
		fontWeight: '700',
		lineHeight: 'normal',
	},
	profileUserEmail: {
		color: '#A7B0C1',
		fontSize: '12px',
		fontWeight: '600',
		lineHeight: 'normal',
	},
	navigation: {
		padding: { xs: '11px 0 0 0px', md: '11px 0 0 45px' },
		display: 'flex',
		flexWrap: 'wrap',
	},
	navigationItem: {
		height: '29px',
		padding: '7px 18px',
		borderBottom: '1px solid transparent',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',

		span: {
			color: (theme) => theme.palette.text.quinternary,
			fontSize: '12px',
			fontWeight: '500',
			letterSpacing: 'normal',
		},
	},
	navigationItemActive: {
		padding: '0 18px',
		borderBottom: (theme) => `1px solid ${theme.palette.bg.brand}`,

		span: {
			color: (theme) => theme.palette.text.deepBrand,
			fontWeight: '700',
			fontSize: '12px',
		},
	},
};
