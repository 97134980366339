import { memo, useState } from 'react';
import { Marker, Popup, Tooltip, useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import defaultMarker from '../../../assets/map/defaultMarker.png';
import activeMarker from '../../../assets/map/activeMarker.png';
import { Stack, Typography, Box } from '@mui/material';

const labelSx = {
	color: '#4E4F69',
	fontSize: '12px',
	fontWeight: '700',
	lineHeight: '18px',
	opacity: 0.5,
	margin: 0,
};

const valueSx = {
	color: '#4E4F69',
	fontSize: '12px',
	fontWeight: '700',
	lineHeight: '16px',
	margin: 0,
	marginBottom: '3px',
};

const ListingMarker = ({ listing, isSelected = false }) => {
	const map = useMap();

	const [zoom, setZoom] = useState(map._zoom);

	useMapEvent('zoom', (e) => {
		setZoom(e.target._zoom);
	});

	const defaultIcon = L.icon({
		iconUrl: defaultMarker,
		iconSize: [zoom * 1.1, zoom * 1.1],
	});

	const activeIcon = L.icon({
		iconUrl: activeMarker,
		iconSize: [zoom * 1.3, zoom * 1.3],
	});

	if (!listing.Latitude || !listing.Longitude) {
		return null;
	}

	return (
		<Marker
			icon={isSelected ? activeIcon : defaultIcon}
			position={[listing.Latitude, listing.Longitude]}
			key={listing.Id}
		>
			<Tooltip>{listing.UnparsedAddress}</Tooltip>
			<Popup
				position={[listing.Latitude, listing.Longitude]}
				classNName="listing-popup"
				maxHeight={300}
			>
				<Stack sx={{ gap: '10px', height: '100%' }}>
					<Box>
						<Typography
							variant="h3"
							sx={labelSx}
						>
							ADDRESS
						</Typography>
						<Typography
							variant="p"
							sx={valueSx}
						>
							{`${listing.UnparsedAddress}, ${listing.StateOrProvince}`}
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="h3"
							sx={labelSx}
						>
							PROPERTY TYPE
						</Typography>
						<Typography
							variant="p"
							sx={valueSx}
						>
							{listing.PropertySubType}
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="h3"
							sx={labelSx}
						>
							LISTING PRICE
						</Typography>
						<Typography
							variant="p"
							sx={valueSx}
						>
							{`$ ${listing.ListPrice}`}
						</Typography>
					</Box>
				</Stack>
			</Popup>
		</Marker>
	);
};

export default memo(ListingMarker);
