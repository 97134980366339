import { Box, Button, IconButton, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIos, ArrowForwardIos, Favorite, FavoriteBorder } from '@mui/icons-material';
import makeNumberWithComas from '../../../utils/common/makeNumberWithComas';
import { useLocation, useNavigate } from 'react-router-dom';
import { sx } from './roomMainListItem.sx';
import CreationAPI from '../../../services/creationAPI';
import { memo } from 'react';
import ProgressiveImg from '../../UI/ProgressiveImage/ProgressiveImage';
import ProfileAPI from '../../../services/profileAPI';
import { useAuthorization } from '../../../pages/AuthorizationLayer/AuthorizationLayer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../firebase/config';

const RoomMainListItem = ({
	room,
	allowShrinking = false,
	handleAddListing,
	isUsedInProfilePage = false,
	isLiked = false,
}) => {
	//@@viewOn:statics
	const { UID } = useAuthorization();
	const { Id, City, ListPrice, Media, StateOrProvince, UnparsedAddress } = room;
	//@@viewOff:statics

	//@@viewOn:hooks
	const navigate = useNavigate();
	const location = useLocation();
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleCreatePostClick = async () => {
		logEvent(analytics, 'create_post_navigate_click');

		await CreationAPI.handleChooseListing(UID, room);
		navigate(`/create/${Id}`);
	};

	const handleListingClick = (e) => {
		logEvent(analytics, 'listing_navigate_click');

		e.stopPropagation();
		const currentUrlIncludeProfile = location.pathname.includes('/profile/');
		const url = currentUrlIncludeProfile ? `/profile/listing/${Id}` : `/listing/${Id}`;
		navigate(url);
	};

	const handleToggleLikeListings = async (e) => {
		logEvent(analytics, 'like_listing_click');
		e.stopPropagation();
		await ProfileAPI.toggleLikeFavoriteListing(!isLiked, UID, Id);
	};
	//@@viewOff:handlers

	return (
		<Box sx={isUsedInProfilePage ? sx.roomForProfilePage : allowShrinking ? sx.addModalRoom : sx.room}>
			<Box
				data-testid="listing item"
				onClick={handleListingClick}
				sx={isUsedInProfilePage ? sx.carouselWrapperInProfilePage : sx.carouselWrapper}
			>
				<Carousel
					autoPlay={false}
					animation="slide"
					indicators={true}
					swipe={true}
					height={isUsedInProfilePage ? 220 : 308}
					navButtonsAlwaysVisible={true}
					NextIcon={<ArrowForwardIos sx={{ width: '16px', height: '16px' }} />}
					PrevIcon={<ArrowBackIos sx={{ width: '16px', height: '16px' }} />}
					changeOnFirstRender={true}
					indicatorIconButtonProps={sx.carousel.indicatorIconButtonProps}
					activeIndicatorIconButtonProps={sx.carousel.activeIndicatorIconButtonProps}
					navButtonsProps={sx.carousel.navButtonsProps}
					indicatorContainerProps={sx.carousel.indicatorContainerProps}
				>
					{(Media.length > 1 ? Media.slice(1, 5) : Media).map((photo, index) => (
						<ProgressiveImg
							src={photo}
							alt={`Photo ${photo}`}
							key={index}
							style={{ width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover' }}
						/>
					))}
				</Carousel>
				<Box sx={sx.actionButtons}>
					<IconButton
						data-testid="Like button"
						sx={sx.actionButton}
						onClick={handleToggleLikeListings}
					>
						{isLiked ? <Favorite sx={sx.actionButtonIcon} /> : <FavoriteBorder sx={sx.actionButtonIcon} />}
					</IconButton>
				</Box>
			</Box>
			<Box sx={sx.roomInfo}>
				<Typography
					sx={{ ...sx.roomName, ...(isUsedInProfilePage && { fontSize: '16px' }) }}
					variant="h2"
				>
					{`${UnparsedAddress}, ${City}, ${StateOrProvince}`}
				</Typography>
				<Typography
					sx={sx.roomPrice}
					variant="body1"
				>{`$ ${makeNumberWithComas(ListPrice)}`}</Typography>
			</Box>
			{!isUsedInProfilePage && (
				<Button
					onClick={handleAddListing ? () => handleAddListing(room) : handleCreatePostClick}
					variant="outlined"
					sx={allowShrinking ? sx.addModalCreateButton : sx.roomButton}
				>
					Create Content
				</Button>
			)}
		</Box>
	);
};

export default memo(RoomMainListItem);
