const sx = {
	container: {
		marginTop: '70px',
		padding: { xs: '0', md: '0 14px 0 0' },
		background: (theme) => theme.palette.bg.light,
		height: 'calc(100% - 70px)',
		overflowY: 'auto',
	},
	content: {
		display: 'flex',
		justifyContent: { xs: 'flex-start', md: 'flex-start' },
		flexWrap: { xs: 'nowrap', md: 'wrap', lg: 'wrap', xl: 'nowrap' },
		alignItems: { xs: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' },
		width: '100%',
		flexDirection: { xs: 'column', md: 'row' },
		height: '100%',
	},
	listingsBlock: {
		padding: { xs: '0 14px 0 0', md: '0' },
		width: { xs: '100%', md: '40%', xl: '21%' },
		marginBottom: '20px',
		maxHeight: { xs: 'fit-content', md: 'calc(100% - 40px)' },
		height: { xs: 'fit-content', md: '100%' },
	},
	createContentBlock: {
		background: (theme) => theme.palette.bg.main,
		width: { xs: '100%', md: '60%', xl: '49%' },
		padding: { xs: '0 14px', md: '0' },
		border: { xs: 'none', md: '1px solid #D6D8E5' },
		borderTop: { xs: '1px solid #D6D8E5', md: 'none !important' },
	},
	previewContentBlock: {
		maxWidth: '600px',
		width: { xs: '100%', md: '55%', xl: '30%' },
		borderTop: { xs: '1px solid #D6D8E5', md: 'none' },
	},
	mediaChoiceBlock: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
		width: '100%',
		gap: '12px 7px',
		justifyContent: 'center',
		padding: '0 15px',
	},
	contentControlsBlock: {
		width: '100%',
		marginTop: '21px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		gap: '14px',
	},
	contentControlsButtonsBlock: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		gap: '14px',
	},
	chosenListingWrapper: {
		maxHeight: { xs: 'none', md: 'calc(100% - 115px)' },
		height: { xs: 'fit-content', md: 'calc(100% - 115px)' },
		overflowY: { xs: 'none', md: 'auto' },
		paddingRight: '10px',
	},
	buttons: {
		addListingButton: {
			justifyContent: 'space-between',
			margin: { xs: '11px auto 0 14px', sm: '11px 10px 0 14px', md: '30px 14px 0 14px' },
			height: '48px',
			width: '95%',
			maxWidth: '343px',
			padding: '12px 21px',
		},
		randomizeButton: {
			width: '120px',
			height: '48px',
			padding: '12px 21px',
			borderRadius: '8px',
			color: (theme) => theme.palette.text.quinternary,
		},
		generateFromPromptButton: {
			padding: '12px 21px',
			width: '199px',
			height: '48px',
		},
		hideButton: {
			color: (theme) => theme.palette.text.mutedSecondary,
			fontSize: '12px',
			textDecoration: 'underline !important',
		},
		sharePreviewButton: {
			borderRadius: '4px',
			padding: '6px 26px',
		},
	},
	inputs: {
		mediaChoiceSelect: {
			inputProps: {
				background: (theme) => theme.palette.bg.main,
				width: '112px',
				height: '24px',
				padding: '12px 21px',
				color: (theme) => theme.palette.text.quinternary,
				fontSize: '14px',
				fontWeight: '500',
				letterSpacing: '0.4px',
			},
			sx: {
				lineHeight: '24px',
				width: '100%',
				height: '48px',
				marginTop: '12px',
				borderRadius: '8px',
				fieldset: {
					border: (theme) => `1px solid ${theme.palette.stroke.custom1} !important`,
				},
			},
		},
		promptTextarea: {
			inputProps: {
				background: (theme) => theme.palette.bg.main,
			},
			sx: {
				marginTop: '12px',
				width: '100%',
				minHeight: '156px',
				fieldset: {
					border: (theme) => `1px solid ${theme.palette.stroke.custom8} !important`,
				},
			},
		},
		promptTypeSelect: {
			inputProps: {
				background: (theme) => theme.palette.bg.main,
				width: '112px',
				height: '24px',
				padding: '12px 21px',
				color: (theme) => theme.palette.text.quinternary,
				fontSize: '14px',
				fontWeight: '500',
				letterSpacing: '0.4px',
			},
			sx: {
				lineHeight: '24px',
				width: '153px',
				height: '48px',
				borderRadius: '8px',
				fieldset: {
					border: (theme) => `1px solid ${theme.palette.stroke.custom1} !important`,
				},
			},
		},
	},
	blockLabel: {
		height: { xs: 'auto', md: '60px' },
		margin: { xs: '0', md: '0 15px 0 14px' },
		padding: { xs: '10px 0', md: '20px 0 15px 0' },
		fontSize: '18px',
		lineHeight: '24px',
		fontWeight: '600',
		borderBottom: '1px solid #D6D8E5',
		color: (theme) => theme.palette.text.darkBackground,
	},
	contentTypeLabel: {
		color: (theme) => theme.palette.text.darkBackground,
		fontSize: '16px',
		fontWeight: '600',
		lineHeight: '24px,',
	},
	hideMediaChoiceButton: {
		color: (theme) => theme.palette.text.mutedSecondary,
		fontSize: '12px',
		textDecorationLine: 'underline',
	},
	generatedResponseIconButtons: {
		maxWidth: '28px',
		maxHeight: '28px',
		borderRadius: '4px',
		border: '1px solid #D6D8E5',
		background: (theme) => theme.palette.bg.light,
		padding: '6px',
	},
	generatedResponseIcons: {
		width: '16px',
		height: '16px',
		color: (theme) => theme.palette.stroke.lighter,
	},
	noContentBlock: {
		display: 'flex',
		width: '100%',
		height: '472px',
		padding: '25px',
		alignItems: 'flex-start',
		borderRadius: '12px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
		background: (theme) => theme.palette.bg.main,
		boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
		color: (theme) => theme.palette.text.muted,
		fontSize: '11px',
		fontStyle: 'italic',
		fontWeight: '500',
	},
};

export default sx;
