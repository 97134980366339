import SGPT_Blue from '../../assets/logos/SGPT_Blue.svg';
import {
	Avatar,
	Box,
	Input,
	InputAdornment,
	Typography,
	MenuItem,
	ListItemIcon,
	ClickAwayListener,
	IconButton,
	Tooltip,
} from '@mui/material';
import { AccountCircle, ArrowDropDown as ArrowDropDownIcon, Logout, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { sx } from './header.sx';
import { useEffect, useMemo, useState } from 'react';
import Authorization from '../../firebase/authorization';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import Loader from '../UI/Loader/Loader';
import { memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFilterOptionsBody } from '../../utils/listing/getPropertyTypesForFilter';
import RoomsAPI from '../../services/roomsAPI';
import { toast } from 'react-toastify';
import chatHeaderButton from '../../assets/icons/chatHeaderButton.svg';
import imagesHeaderButton from '../../assets/icons/imagesHeaderButton.svg';
import { useAppProvider } from '../../pages/Layout/LayoutProvider';
import { useAuthorization } from '../../pages/AuthorizationLayer/AuthorizationLayer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/config';

const Header = () => {
	//@@viewOn:hooks
	const { showOnlyAgentsListings, filterOptions, regions } = useAppProvider();

	const navigate = useNavigate();

	const [allRooms, setAllRooms] = useState([]);
	const [searchResults, setSearchResults] = useState([]);

	const [searchValue, setSearchValue] = useState('');
	const debouncedSearchValue = useDebounce(searchValue, 700);

	const [isSearchingState, setIsSearching] = useState(true);

	const isSearching = useMemo(() => {
		return searchValue.length > 0 && isSearchingState;
	}, [searchValue, allRooms, isSearchingState]);

	const [menuIsOpen, setMenuIsOpen] = useState(null);
	//@@viewOff:hooks

	//@@viewOn:statics
	const { UID, displayName } = useAuthorization();

	//@@viewOff:statics

	//@@viewOn:handlers
	const handleRoomClick = async (room) => {
		setSearchValue('');
		navigate(`/listing/${room.Id}`);
	};

	const handleClick = () => {
		setMenuIsOpen((prev) => !prev);
	};
	const handleClose = (e) => {
		if (e) {
			e.stopPropagation();
		}
		setMenuIsOpen(false);
	};

	const handleClickMenuItem = (e, url) => {
		logEvent(analytics, 'header_navigate_profile_click');
		e.stopPropagation();
		navigate(`/${url}`);
		setMenuIsOpen(false);
	};
	const handleLogout = () => {
		Authorization.logout();
	};

	const handleClickAway = () => {
		setIsSearching(false);
	};

	const handleFocusSearch = () => {
		logEvent(analytics, 'search_rooms_focus');
		setIsSearching(true);
	};
	//@@viewOff:handlers

	//@@viewOn:effects
	const { isLoading } = useQuery({
		queryKey: ['getListingsRooms', { filterOptions, regions, showOnlyAgentsListings }],
		queryFn: async () => {
			const filterOptions = getFilterOptionsBody(null);

			const body = {
				...filterOptions,
				regionsSelected: regions || [],
				showOnlyAgentsListings,
			};
			return RoomsAPI.getRoomsPost(body);
		},
		onSuccess: (data) => {
			setAllRooms(data.length ? data : []);
		},
		onError: (error) => {
			const errorMessage = error.toString();
			toast.error('Could not get listings');
			console.log('error', errorMessage);
		},
	});

	useEffect(() => {
		if (debouncedSearchValue.trim().length > 0) {
			const results = (allRooms || []).filter((room) => {
				return room.UnparsedAddress.toLowerCase().includes(debouncedSearchValue.toLowerCase());
			});
			setSearchResults(results);
		}
	}, [debouncedSearchValue, allRooms]);
	//@@viewOff:effects

	return (
		<Box sx={sx.header}>
			<Box
				sx={sx.headerLogo}
				onClick={UID ? () => navigate('/') : () => {}}
			>
				<img
					src={SGPT_Blue}
					alt="logo"
				/>
			</Box>
			<ClickAwayListener
				onClickAway={handleClickAway}
				mouseEvent="onMouseDown"
			>
				<Box sx={sx.headerSearch}>
					<Input
						onFocus={handleFocusSearch}
						value={searchValue}
						disabled={!UID}
						onChange={(e) => setSearchValue(e.target.value)}
						startAdornment={
							<InputAdornment
								position="start"
								sx={{ margin: 0 }}
							>
								<Search sx={{ width: '22px', height: '22px', opacity: '0.3' }} />
							</InputAdornment>
						}
						placeholder="Search by Address"
						sx={{
							...sx.searchInput,
							'& input::placeholder': {
								fontSize: '12px ',
							},
						}}
					/>
					{isSearching && (
						<Box sx={sx.searchResultsWrapper}>
							{isLoading ? (
								<Loader
									sx={{ width: '100%', height: '60px' }}
									disableShrink={true}
								/>
							) : (
								<>
									{searchResults?.map((room) => (
										<Box
											key={room.Id}
											onClick={() => handleRoomClick(room)}
											sx={sx.searchResultItem}
										>
											<Box
												sx={{
													...sx.searchResultItemImage,
													backgroundImage: `url(${room.Media[1]})`,
												}}
											/>
											<Box>
												<Typography
													sx={{
														color: (theme) => theme.palette.text.secondary,
														fontSize: '16px',
														fontWeight: '600',
													}}
												>
													{room.UnparsedAddress}
												</Typography>
												<Typography
													sx={{
														color: (theme) => theme.palette.text.gray,
														fontSize: '14px',
														fontWeight: '400',
													}}
												>
													{`$${room.ListPrice}`}
												</Typography>
											</Box>
										</Box>
									))}
									{searchResults?.length === 0 && (
										<Typography
											sx={{
												color: (theme) => theme.palette.text.gray,
												fontSize: '14px',
												fontWeight: '400',
											}}
										>
											No results found
										</Typography>
									)}
								</>
							)}
						</Box>
					)}
				</Box>
			</ClickAwayListener>
			<Box sx={sx.headerRight}>
				<Tooltip
					title="Go to Serhant A.I. chat"
					arrow
					placement="top"
				>
					<IconButton
						disabled={!UID}
						onClick={() => navigate('/chat')}
						sx={sx.aiButton}
					>
						<img
							src={imagesHeaderButton}
							alt="imagesHeaderButton"
							width={22}
							height={22}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip
					title="Go to Serhant A.I. image editor"
					arrow
					placement="top"
				>
					<IconButton
						disabled={!UID}
						onClick={() => navigate('/editing')}
						sx={{ ...sx.aiButton, marginLeft: '7px' }}
					>
						<img
							src={chatHeaderButton}
							alt="chatHeaderButton"
							width={22}
							height={22}
						/>
					</IconButton>
				</Tooltip>
				<ClickAwayListener
					onClickAway={handleClose}
					mouseEvent="onMouseDown"
				>
					<Box
						id="menu-anchor"
						onClick={UID ? handleClick : () => {}}
						sx={sx.headerProfile}
					>
						<Typography
							variant="body1"
							sx={sx.headerProfile.span}
							data-testid="login"
						>
							{displayName || 'Sign in'}
						</Typography>
						<Avatar
							sx={sx.avatar}
							variant="circular"
						/>
						<ArrowDropDownIcon sx={sx.profileAvatarIcon} />
						{menuIsOpen && (
							<Box
								onClick={() => setMenuIsOpen((prev) => !prev)}
								sx={sx.profileMenu}
							>
								<MenuItem onClick={(e) => handleClickMenuItem(e, 'profile/')}>
									<ListItemIcon>
										<AccountCircle fontSize="small" />
									</ListItemIcon>
									Profile
								</MenuItem>
								<MenuItem onClick={handleLogout}>
									<ListItemIcon>
										<Logout fontSize="small" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Box>
						)}
					</Box>
				</ClickAwayListener>
			</Box>
		</Box>
	);
};

export default memo(Header);
