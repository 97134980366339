import { SocialMediaTypes } from '../../../utils/creationContent/socialMediaTypes';
import FacebookPreview from './Facebook/FacebookPreview';
import TwitterPreview from './Twitter/TwitterPreview';
import InstagramPreview from './Instagram/InstagramPreview';
import TiktokPreview from './Tiktok/TiktokPreview';
import BlogPostPreview from './BlogPost/BlogPostPreview';
import EmailPreview from './Email/EmailPreview';
import { auth } from '../../../firebase/config';
import ListingDescription from './ListingDescription/ListingDescription';
import VideoscriptPreview from './Videoscript/VideoscriptPreview';
import { memo } from 'react';

const PreviewBlock = ({ type, image, text }) => {
	const user = auth.currentUser;
	const userDisplayName = user?.displayName;
	const profilePhoto = user?.photoURL || user?.providerData[0]?.photoURL || '';

	const componentMap = {
		[SocialMediaTypes.FACEBOOK]: FacebookPreview,
		[SocialMediaTypes.TWITTER]: TwitterPreview,
		[SocialMediaTypes.INSTAGRAM]: InstagramPreview,
		[SocialMediaTypes.TIKTOK]: TiktokPreview,
		[SocialMediaTypes.BLOG_POST]: BlogPostPreview,
		[SocialMediaTypes.EMAIL]: EmailPreview,
		[SocialMediaTypes.LISTING_DESCRIPTION]: ListingDescription,
		[SocialMediaTypes.VIDEO_SCRIPT]: VideoscriptPreview,
	};

	const Component = componentMap[type];

	return Component ? (
		<Component
			userDisplayName={userDisplayName}
			profilePhoto={profilePhoto}
			text={text}
			image={image}
		/>
	) : null;
};

export default memo(PreviewBlock);
