import { Box, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { sx } from './facebook.sx';
import { memo } from 'react';

const FacebookMediaMetrics = () => {
	return (
		<Box
			sx={{
				margin: '10px 18px 0 18px',
				paddingBottom: '21px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderBottom: (theme) => `1px solid ${theme.palette.stroke.main}`,
			}}
		>
			<Box
				sx={{
					lineHeight: '20px',
					width: '58px',
					display: 'flex',
				}}
			>
				<Circle
					key={1}
					color="blue"
					sx={{
						...sx.circle,
						color: '#0d91ef',
					}}
				/>
				<Circle
					key={2}
					color="red"
					sx={{
						...sx.circle,
						color: '#db4750',
						position: 'relative',
						right: '5px',
					}}
				/>
				<Circle
					key={3}
					color="yellow"
					sx={{
						...sx.circle,
						color: '#f1c666',
						position: 'relative',
						right: '10px',
					}}
				/>
				{` -`}
			</Box>
			<Box
				sx={{
					display: 'flex',
					gap: '16px',
					color: (theme) => theme.palette.text.slate,
					fontSize: '12px',
					lineHeight: '16px',
				}}
			>
				<Typography sx={sx.commentsAndShares}>{`- Comments `}</Typography>
				<Typography sx={sx.commentsAndShares}>{`- Shares `}</Typography>
			</Box>
		</Box>
	);
};

export default memo(FacebookMediaMetrics);
