import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { memo } from 'react';
import { analytics } from '../../../firebase/config';
import { logEvent } from 'firebase/analytics';

const Transition = forwardRef(function Transition(props, ref) {
	return (
		<Slide
			in={true}
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

const ChangelogDialog = ({ showChangelogDialog, setShowChangelogDialog, setShowChangelogModal }) => {
	const handleClose = () => {
		setShowChangelogDialog(false);
	};

	const handleDisagree = () => {
		setShowChangelogDialog(false);
		localStorage.setItem('showUpdateDialog', 'false');
	};
	const handleAgree = () => {
		logEvent(analytics, 'show_update_changelog_click');

		setShowChangelogDialog(false);
		setShowChangelogModal(true);
		localStorage.setItem('showUpdateDialog', 'false');
	};

	return (
		<Dialog
			open={showChangelogDialog}
			TransitionComponent={Transition}
			onClose={handleClose}
		>
			<DialogTitle
				variant="h1"
				sx={{
					fontWeight: 'bold',
					fontSize: '18px',
					borderBottom: '1px solid #eee',
					padding: '10px',
				}}
			>
				Show new update changelog?
			</DialogTitle>
			<DialogContent
				sx={{
					marginTop: '10px',
					padding: '10px',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-start',
				}}
			>
				<DialogContentText>We have a new update.You can check the changelog to see what has changed.</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					onClick={handleDisagree}
				>
					Don`t Show Again
				</Button>
				<Button
					variant="contained"
					onClick={handleAgree}
				>
					Show
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default memo(ChangelogDialog);
