import { Box, Button, Typography } from '@mui/material';
import { memo } from 'react';
import maintainedImage from '../../assets/images/maintaince.jpg';
import { useDocumentTitle } from 'usehooks-ts';

const MaintenancePage = ({ tabTitle, title, description }) => {
	useDocumentTitle(tabTitle || `Not found page | S.GPT`);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				width: '100%',
				height: 'calc(100vh - 70px)',
				padding: { xs: '20px', md: '20px 50px' },
			}}
		>
			<Box
				sx={{
					width: '100%',
					maxWidth: '739px',
					maxHeight: '210px',
					height: 'fit-content',
					display: 'flex',
				}}
			>
				<img
					src={maintainedImage}
					alt="maintained"
					style={{
						objectFit: 'cover',
						objectPosition: 'center',
						width: '100%',
						height: '100%',
					}}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginTop: '34px',
				}}
			>
				<Typography
					variant="h2"
					sx={{
						color: (theme) => theme.palette.text.darkBackground,
						fontSize: '32px',
						lineHeight: '42px',
						textAlign: 'center',
					}}
				>
					{title || 'Is currently under Maintenance'}
				</Typography>
				<Typography
					variant="body1"
					sx={{
						marginTop: '6px',
						maxWidth: '600px',
						color: (theme) => theme.palette.text.darkGray,
						textAlign: 'center',
						lineHeight: '20px',
					}}
				>
					{description ||
						'The ADX team is currently working on improvements to the SerhantGPT experience. Check back soon. If you need urgent help. Feel free to contact.'}
				</Typography>
				<Button
					variant="contained"
					onClick={() => (window.location.href = 'https://serhant.com')}
					sx={{
						marginTop: '21px',
					}}
				>
					Go to Serhant.com
				</Button>
			</Box>
		</Box>
	);
};

export default memo(MaintenancePage);
