import AuthorizationLayer from '../AuthorizationLayer/AuthorizationLayer';
import { createContext, useContext, useMemo, useState } from 'react';
import useScreenSize from '../../hooks/useScreenSize/useScreenSize';
import { memo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGetAllListings from '../../hooks/useGetAllListings/useGetAllListings';

const AppContextProvider = createContext(null);

const LayoutProvider = ({ children }) => {
	//@@viewOn:hooks
	const screenSize = useScreenSize();
	const isMobile = useMemo(() => screenSize === 'xs' || screenSize === 's', [screenSize]);

	const [currentPage, setCurrentPage] = useState(1);
	const [rooms, setRooms] = useState([]);

	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [isAddingModalOpen, setIsAddingModalOpen] = useState(false);

	const [regions, setRegions] = useState(null);
	const [filterOptions, setFilterOptions] = useState(null);
	const [showOnlyAgentsListings, setShowOnlyAgentsListings] = useState(false);

	const [initialSavedFilter, setInitialSavedFilter] = useState(null);

	useGetAllListings();

	//@@viewOff:hooks

	//@@viewOn:context
	const contextValue = useMemo(() => {
		return {
			isFilterModalOpen,
			setIsFilterModalOpen,
			isAddingModalOpen,
			setIsAddingModalOpen,
			regions,
			setRegions,
			filterOptions,
			setFilterOptions,
			isMobile,
			currentPage,
			setCurrentPage,
			rooms,
			setRooms,
			showOnlyAgentsListings,
			setShowOnlyAgentsListings,
			initialSavedFilter,
			setInitialSavedFilter,
		};
	}, [
		isFilterModalOpen,
		setIsFilterModalOpen,
		isAddingModalOpen,
		setIsAddingModalOpen,
		regions,
		setRegions,
		filterOptions,
		setFilterOptions,
		isMobile,
		currentPage,
		setCurrentPage,
		rooms,
		setRooms,
		showOnlyAgentsListings,
		setShowOnlyAgentsListings,
		initialSavedFilter,
		setInitialSavedFilter,
	]);
	//@@viewOff:context

	return (
		<>
			<AppContextProvider.Provider value={contextValue}>
				<AuthorizationLayer>{children}</AuthorizationLayer>
			</AppContextProvider.Provider>
			<ToastContainer
				position="top-right"
				autoClose={6000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

const useAppProvider = () => {
	const context = useContext(AppContextProvider);
	if (!context) {
		throw new Error('useAppProvider must be used within a LayoutProvider');
	}
	return context;
};

export default memo(LayoutProvider);
export { useAppProvider };
