import { Box } from '@mui/material';
import { useProfilePage } from './ProfilePageProvider';
import { sx } from './profilePage.sx';
import ProfilePageHeader from '../../components/ProfilePage/ProfilePageHeader/ProfilePageHeader';
import { PROFILE_NAVIGATION } from '../../utils/profile/activeViews';
import { useMemo, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const ProfilePage = () => {
	//@@viewOn:hooks
	const {
		user,
		activeListing,
		allListings,
		setActiveListing,
		isMobile,
		handleToggleLikeListingItem,
		detailedListingData,
		detailedListingIsLoading,
	} = useProfilePage();
	const profileLocation = useLocation().pathname.split('/')[2];

	const getViewLabel = useMemo(() => {
		if (profileLocation === PROFILE_NAVIGATION.CHATS.value) {
			return 'Chats';
			// } else if (profileLocation === PROFILE_NAVIGATION.SAVED_FILTERS.value) {
			// 	return 'Saved Filters';
		} else if (profileLocation === PROFILE_NAVIGATION.GENERATED_IMAGES.value) {
			return 'Generated Images';
		} else if (profileLocation === PROFILE_NAVIGATION.LISTINGS.value) {
			return 'Listings';
		} else if (activeListing) {
			return activeListing.UnparsedAddress + ',' + activeListing.StateOrProvince;
		}
	}, [profileLocation, activeListing]);
	//@@viewOff:hooks

	return (
		<Box sx={sx.container}>
			<ProfilePageHeader user={user} />
			<Box sx={sx.content}>
				<Box sx={sx.activeViewContainer}>
					<Box sx={sx.label}>{getViewLabel}</Box>
					<Outlet
						context={{
							activeListing: activeListing,
							listings: allListings,
							setActiveListing: setActiveListing,
							handleToggleLikeListingItem,
							isMobile,
							detailedListingData,
							detailedListingIsLoading,
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(ProfilePage);
