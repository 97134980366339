import { Box, Typography } from '@mui/material';
import { sx } from './instagram.sx';
import InstagramActionsIcons from './InstagramActionsIcons';
import { SocialMediaTypes } from '../../../../utils/creationContent/socialMediaTypes';
import AvatarAndNameForPreview from '../../AvatarAndNameForPreview/AvatarAndNameForPreview';
import { memo } from 'react';

const InstagramPreview = ({ text, image = null, userDisplayName = '', profilePhoto }) => {
	return (
		<Box sx={sx.container}>
			<Box sx={{ padding: '0 18px' }}>
				<AvatarAndNameForPreview
					image={profilePhoto}
					name={userDisplayName}
					status="New York, new York"
					socialMediaType={SocialMediaTypes.INSTAGRAM}
				/>
			</Box>
			<Box sx={sx.image(image)} />
			<InstagramActionsIcons />
			<Box
				sx={{
					padding: '0 20px',
					marginTop: '15px',
					color: '#3A3A3A',
					fontSize: '12px',
					fontWeight: '500',
				}}
			>
				<Box>
					<Typography
						variant="h4"
						sx={{
							color: '#3A3A3A',
							fontSize: ' 12px',
							fontWeight: '700',
							display: 'inline',
						}}
					>
						{userDisplayName}
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: '#3A3A3A',
							fontSize: ' 12px',
							lineHeight: '14px',
							display: 'inline',
							marginInlineStart: '5px',
						}}
					>
						{text}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default memo(InstagramPreview);
