import { Box, Link, Typography } from '@mui/material';
import { useProfilePage } from '../../../pages/ProfilePage/ProfilePageProvider';
import { sx } from './profileChats.sx';
import ProfileEmptyView from '../ProfileEmptyView/ProfileEmptyView';
import { SpeakerNotesOff } from '@mui/icons-material';
import { useDocumentTitle } from 'usehooks-ts';
import { memo } from 'react';
import Loader from '../../UI/Loader/Loader';

const ProfileChats = () => {
	const { chatThreads, chatThreadsIsLoading } = useProfilePage();
	useDocumentTitle(`Profile Chats | S.GPT`);

	return (
		<>
			{chatThreadsIsLoading && (
				<Loader
					disableShrink={true}
					sx={{ height: '600px', width: '100%' }}
				/>
			)}
			<Box sx={sx.chatsList}>
				{(chatThreads || []).map((chatThread) => (
					<Box
						key={chatThread.id}
						sx={sx.chatListItem}
					>
						<Typography sx={sx.chatLabel}>INITIAL PROMPT</Typography>
						<Typography sx={sx.initialPrompt}>{chatThread.chatString[1].content}</Typography>
						<Link
							href={`/chat/${chatThread.id}`}
							underline="always"
							onClick={(e) => e.stopPropagation()}
							sx={sx.chatLink}
						>
							view conversation
						</Link>

						<Typography sx={sx.chatLength}>{`${chatThread.chatString.length - 1} Responses`}</Typography>
					</Box>
				))}
			</Box>
			{!chatThreads?.length && (
				<ProfileEmptyView
					text="No existing chats found"
					Icon={SpeakerNotesOff}
				/>
			)}
		</>
	);
};

export default memo(ProfileChats);
