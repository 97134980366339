import { memo } from 'react';
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet';
import ListingMarker from './ListingMarker';
import { useLocalStorage } from 'usehooks-ts';
import ListingMapControls from './ListingMapControls';
import Loader from '../../UI/Loader/Loader';

const newYorkCoordinates = [40.7128, -74.006];

const ListingsMapContainer = ({ listings, selectedListingItem }) => {
	const mapStyles = {
		default: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
		CartoDB: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
		Stadia: 'https://tiles.stadiamaps.com/tiles/stamen_toner/{z}/{x}/{y}{r}.png',
	};

	const localMapLayerName = localStorage.getItem('mapLayerName');
	const [selectedLayer, setSelectedLayer] = useLocalStorage('mapLayerName', localMapLayerName || 'default');

	if (!selectedListingItem) {
		return (
			<Loader
				sx={{ height: '100%', width: '100%' }}
				disableShrink={true}
			/>
		);
	}

	return (
		<MapContainer
			style={{
				height: '100%',
				width: '100%',
				borderRadius: '8px',
			}}
			scrollWheelZoom={true}
			wheelPxPerZoomLevel={50}
			center={[
				selectedListingItem?.Latitude || newYorkCoordinates[0],
				selectedListingItem?.Longitude || newYorkCoordinates[1],
			]}
			zoom={15}
			minZoom={4}
		>
			<ListingMapControls
				selectedLayer={selectedLayer}
				setSelectedLayer={setSelectedLayer}
			/>
			<LayersControl position="topright">
				{Object.keys(mapStyles).map((styleKey) => (
					<LayersControl.BaseLayer
						key={styleKey}
						name={styleKey}
						checked={selectedLayer === styleKey}
					>
						<TileLayer
							url={mapStyles[styleKey]}
							maxZoom={styleKey === 'Stadia' ? 14 : 20}
						/>
					</LayersControl.BaseLayer>
				))}
			</LayersControl>
			{listings.map((listing) => (
				<ListingMarker
					listing={listing}
					key={listing.Id}
					isSelected={false}
				/>
			))}
			{selectedListingItem && (
				<ListingMarker
					listing={selectedListingItem}
					key={selectedListingItem.Id}
					isSelected={true}
				/>
			)}
		</MapContainer>
	);
};

export default memo(ListingsMapContainer);
