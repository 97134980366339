import { useRef, useState, memo } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';

const FileInput = ({ image, setImage, handleFileChange, containerSx = {} }) => {
	//@@viewOn:hooks
	const fileInputRef = useRef(null);
	const [isDragging, setIsDragging] = useState(false);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleFileRemove = () => {
		setImage(null);
	};

	const handleDragEnter = (event) => {
		event.preventDefault();
		setIsDragging(true);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		setIsDragging(false);

		const selectedFile = event.dataTransfer.files[0];
		setImage(selectedFile);
	};
	//@@viewOff:handlers

	return (
		<Box
			sx={{
				width: { xs: '100%', sm: '350px' },
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '16px',
				padding: '24px',
				borderRadius: '8px',
				border: (theme) => `2px dashed ${theme.palette.stroke.darkest}`,
				backgroundColor: isDragging ? (theme) => theme.palette.bg.light : 'transparent',
				outline: 'none',
				cursor: 'pointer',
				transition: 'background-color 0.2s',
				...containerSx,
			}}
			onDragEnter={handleDragEnter}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			tabIndex={0}
		>
			{image ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<IconButton
						data-testid="delete-button"
						onClick={handleFileRemove}
						sx={{ padding: '0' }}
					>
						<Delete />
					</IconButton>
					<Typography>{image.name}</Typography>
				</Box>
			) : (
				<>
					<IconButton onClick={() => fileInputRef.current.click()}>
						<CloudUpload />
					</IconButton>
					<Button
						onClick={() => fileInputRef.current.click()}
						sx={{
							width: '128px',
							height: '40px',
							borderRadius: '6px',
							border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
							background: (theme) => theme.palette.bg.light,
							color: (theme) => theme.palette.text.senary,
							fontWeight: '600',
							lineHeight: '20px',
							padding: '0',
						}}
					>
						Upload image
					</Button>
					<Typography>or drop files to upload</Typography>
					<input
						data-testid="file-input"
						type="file"
						ref={fileInputRef}
						style={{ display: 'none' }}
						onChange={handleFileChange}
					/>
				</>
			)}
		</Box>
	);
};

export default memo(FileInput);
