export const sx = {
	container: {
		position: 'relative',
		width: '100%',
	},
	chipsContainer: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		gap: '10px',
		minHeight: '78px',
		width: 'fit-content',
		minWidth: '100%',
		maxWidth: '100%',
		background: (theme) => theme.palette.bg.light,
		boxShadow: '-5px 0px 4px 0px rgba(0, 0, 0, 0.09) inset',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	chips: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '14px',
	},
	chipItem: {
		display: 'flex',
		padding: '7px 15px',
		alignItems: 'center',
		gap: '13px',
		borderRadius: '90px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom5}`,
		background: (theme) => theme.palette.bg.lighter,
		fontSize: '16px',
		fontWeight: '500',
		lineHeight: '24px',
		letterSpacing: '0.4px',
	},
	endGradient: {
		display: { xs: 'block', md: 'none' },
		zIndex: '12',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '25px',
		height: '100%',
		background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(189,189,189,0.8) 100%)',
	},
};
