import { useQuery } from '@tanstack/react-query';
import RoomsAPI from '../../services/roomsAPI';

const useGetDetailedListing = ({ listingId }) => {
	const { data, isLoading, isError, isFetching } = useQuery({
		queryKey: ['detailedListing', listingId],
		queryFn: async () => {
			if (!listingId) {
				return null;
			}
			const data = await RoomsAPI.getListingItemById(listingId);
			return (await data?.value[0]) || null;
		},
		staleTime: 10 * 60 * 1000,
		cacheTime: 10 * 60 * 1000,
	});

	return { detailedListingData: data, detailedListingIsLoading: isLoading || isFetching, isError };
};

export default useGetDetailedListing;
