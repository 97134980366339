export const filterModalSx = {
	modal: {
		maxWidth: { xs: 'calc(100% - 8px)', sm: '95%', md: '634px' },
		maxHeight: '90%',
		width: '100%',
		height: 'fit-content',
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: (theme) => theme.palette.bg.main,
		boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.25)',
		overflowY: 'scroll',
		borderRadius: '12px',
	},
	modalHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 19px',
		height: '63px',
		borderBottom: '1px solid #D9D9D9',
	},
	headerTitle: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '18px',
		fontWeight: '700',
	},
	modalContent: {
		padding: '0 20px',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
	},
	filterBlock: {
		width: '100%',
		marginTop: '24px',
		paddingBottom: '30px',
		borderBottom: '1px solid #D9D9D9',
	},
	filterBlockTitle: {
		marginBottom: '13px',
		color: '#D9D9D9',
		fontSize: '20px',
		fontWeight: '600',
		textTransform: 'none !important',
	},
	modalFooter: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '97px',
		padding: '0 22px',
		marginTop: '40px',
	},
	squareBlock: {
		marginTop: '20px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '40px',
	},
	bedsAndBathsLabel: {
		color: '#777',
		fontSize: '16px',
		marginBottom: '7px',
	},
	clearModalButton: {
		color: (theme) => theme.palette.text.primary,
		textDecorationLine: 'underline',
	},
	searchModalButton: {
		display: 'flex',
		padding: '12px 21px',
		justifyContent: 'center',
		alignItems: 'center',
		color: (theme) => theme.palette.bg.main,
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '24px',
		borderRadius: '4px',
		background: (theme) => `${theme.palette.bg.brand} !important`,
	},
	minAndMaxWrapper: (error) => ({
		display: 'flex',
		maxWidth: '278px',
		width: '100%',
		padding: '8px 10px 15px 19px',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '7px',
		borderRadius: '14px',
		border: (theme) => (error ? `1px solid ${theme.palette.errors.main}` : '1px solid #DDD'),
		background: (theme) => theme.palette.bg.main,
	}),
	minAndMaxLabel: {
		color: (theme) => theme.palette.text.muted,
	},
	minAndMaxInput: {
		minWidth: '100%',
		height: '24px',
		color: (theme) => theme.palette.text.primary,
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '24px',
		letterSpacing: '0.4px',
		'&:before': {
			position: 'relative',
			paddingRight: '8px',
			bottom: '1px',
			content: '"$"',
			color: (theme) => theme.palette.text.primary,
		},
	},
	popperErrorBlock: {
		maxWidth: '90%',
		background: (theme) => theme.palette.bg.errorBg,
		borderRadius: '8px',
		border: (theme) => `1px solid ${theme.palette.errors.main}`,
	},
	popperContainer: {
		padding: '14px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	popperLabel: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		gap: '8px',
		alignItems: 'center',
		color: (theme) => theme.palette.errors.darker,
	},
	popperErrorIcon: {
		width: '16px',
		height: '16px',
		color: (theme) => theme.palette.errors.darker,
	},
};
