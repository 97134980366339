import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { sx } from './chatControls.sx';
import sendMessageIcon from '../../../assets/icons/sendMessageIcon.svg';
import RegenerateResponseIcon from '../../../iconsComponents/RegenerateResponseIcon';
import { sendingChatRequestValidation } from '../../../utils/validations/sendingChatRequestValidation';
import Lottie from 'react-lottie';
import generatingSmallAnimation from '../../../assets/animations/generation-small-animation.json';
import { useChatPage } from '../../../pages/ChatPage/ChatPageProvider';
import { memo, useCallback } from 'react';

//@@viewOn:private
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: generatingSmallAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};
//@@viewOff:private

const ChatControls = () => {
	//@@viewOn:hooks
	const { handleSendMessage, isMessageLoading, handleRegenerateMessage, activeChatThread } = useChatPage();
	//@@viewOff:hooks

	//@@viewOn:statics
	const initialFormValues = {
		prompt: '',
	};
	//@@viewOff:statics

	//@@viewOn:handlers
	const handleSubmitSending = useCallback(
		(values, setFieldValue) => {
			handleSendMessage(values.prompt);
			setFieldValue('prompt', '');
		},
		[handleSendMessage],
	);

	const formik = useFormik({
		initialValues: initialFormValues,
		validationSchema: sendingChatRequestValidation,
		onSubmit: (values) => handleSubmitSending(values, formik.setFieldValue),
	});

	const { handleSubmit, handleChange, values, errors } = formik;

	const handleKeyDown = useCallback(
		(event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				handleSubmit();
			}
		},
		[handleSubmit],
	);
	//@@viewOff:handlers

	return (
		<form
			onSubmit={handleSubmit}
			style={sx.chatControls}
		>
			<Button
				sx={isMessageLoading ? { ...sx.regenerateResponseButton, alignItems: 'center' } : sx.regenerateResponseButton}
				disabled={isMessageLoading || !activeChatThread}
				onClick={handleRegenerateMessage}
				endIcon={
					isMessageLoading ? (
						<Lottie
							options={defaultOptions}
							height={30}
							width={30}
						/>
					) : (
						<RegenerateResponseIcon />
					)
				}
			>
				Regenerate response
			</Button>
			<TextField
				name="prompt"
				value={values.prompt}
				onChange={handleChange}
				multiline
				sx={sx.newMessageInput.sx}
				inputProps={sx.newMessageInput.inputProps}
				onKeyDown={handleKeyDown}
				placeholder="Type your question here..."
				rows={5}
				variant="outlined"
			/>
			<Tooltip title="Send">
				<IconButton
					sx={sx.sendMessageButton}
					style={{ ...((isMessageLoading || !!errors?.prompt) && { opacity: '60%' }) }}
					type="submit"
					disabled={isMessageLoading || !!errors?.prompt}
				>
					<img
						src={sendMessageIcon}
						alt="send"
					/>
				</IconButton>
			</Tooltip>
		</form>
	);
};

export default memo(ChatControls);
