import { Box, Stack, Typography } from '@mui/material';
import makeNumberWithComas from '../../../utils/common/makeNumberWithComas';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import ProgressiveImage from '../../UI/ProgressiveImage/ProgressiveImage';

const ListingItem = ({ listing, isChosen = false, setChosenItem }) => {
	//@@viewOn:hooks
	const navigate = useNavigate();
	//@@viewOff:hooks

	//@@viewOn:statics
	const { Id, City, ListPrice, Media, StateOrProvince, UnparsedAddress } = listing;
	//@@viewOff:statics

	//@@viewOn:handlers
	const handleChooseItem = () => {
		navigate(`/create/${Id}`);
		setChosenItem(Id);
	};
	//@@viewOff:handlers

	return (
		<Box
			onClick={handleChooseItem}
			sx={{
				width: '100%',
				height: '125px',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				background: (theme) => theme.palette.bg.main,
				marginTop: '23px',
				paddingRight: '8px',
				paddingLeft: '15px',
				borderRight: isChosen ? '5px solid #131175' : '5px solid transparent',
				cursor: 'pointer',
			}}
		>
			<Box sx={{ width: '96px', height: '102px' }}>
				<ProgressiveImage
					src={Media[0]}
					style={{
						width: '96px',
						height: '102px',
						borderRadius: '12px',
						objectFit: 'cover',
						objectPosition: 'center',
					}}
					alt={Id}
				/>
			</Box>
			<Stack
				justifyContent="flex-start"
				direction="column"
				sx={{
					maxHeight: '125px',
					height: 'fit-content',
					overflow: 'hidden',
					margin: '3px 0 0 15px',
				}}
			>
				<Typography
					variant="h3"
					fontWeight="bold"
					sx={{
						color: (theme) => theme.palette.text.secondary,
						fontSize: '18px',
						fontWeight: '700',
						textTransform: 'uppercase',
					}}
				>
					{`${UnparsedAddress}, ${City}, ${StateOrProvince}`}
				</Typography>
				<Typography
					variant="h3"
					fontWeight="bold"
					sx={{
						color: (theme) => theme.palette.text.tertiary,
						fontWeight: '600',
						textTransform: 'uppercase',
					}}
				>
					{`$ ${makeNumberWithComas(ListPrice)}`}
				</Typography>
			</Stack>
		</Box>
	);
};

export default memo(ListingItem);
