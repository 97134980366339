import { useEffect } from 'react';
import EditingImagesAPI from '../../services/editingImagesAPI';
import { off, onValue, ref } from 'firebase/database';
import { db } from '../../firebase/config';

const useGetImagesPresets = ({ UID, presets, setPresets, setPresetsIsLoading }) => {
	useEffect(() => {
		// Effect for Managing Presets
		if (!presets?.length) {
			EditingImagesAPI.getPresets(UID).then((presets) => {
				setPresets(presets || []);
			});
		}

		const presetsRef = ref(db, `presets/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const newPresets = Object.values(data || {});

			setPresets(newPresets || []);
			setPresetsIsLoading(false);
		};

		onValue(presetsRef, onDataChange);

		return () => {
			off(presetsRef, onDataChange);
		};
	}, [UID, setPresets, setPresetsIsLoading]);
};

export default useGetImagesPresets;
