import ListingItemPage from './ListingItemPage';
import { memo } from 'react';
import { useListingsItemPage } from './ListingItemPageProvider';

const ListingItemPageRouteProvider = () => {
	const {
		activeListing,
		isMobile,
		allListings,
		listingsIsLoading,
		handleToggleLikeListingItem,
		detailedListingData,
		detailedListingIsLoading,
	} = useListingsItemPage();

	return (
		<ListingItemPage
			listings={allListings}
			listingsIsLoading={listingsIsLoading}
			handleToggleLikeListingItem={handleToggleLikeListingItem}
			activeListing={activeListing}
			isMobile={isMobile}
			detailedListingData={detailedListingData}
			detailedListingIsLoading={detailedListingIsLoading}
		/>
	);
};

export default memo(ListingItemPageRouteProvider);
