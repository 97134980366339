import { axiosInstance } from './axios';

/**
 * A utility class for interacting with the API to manage common data.
 */
export default class CommonAPI {
	static endpoint = process.env.REACT_APP_API_BACKEND_URL;

	static async getChangelog() {
		try {
			const res = await axiosInstance.get(this.endpoint + '/callProxy');
			return res.data;
		} catch (error) {
			throw new Error('Error fetching new version update');
		}
	}
}
