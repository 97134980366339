export const sx = {
	header: {
		position: 'fixed',
		top: '0',
		left: '0',
		zIndex: '100',
		width: '100%',
		height: '70px',
		padding: { xs: '0 40px 0 20px', sm: '0 20px 0 20px', md: '0 20px', lg: '0 110px 0 86px' },
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: '1px solid #d6d8e5',
		background: (theme) => theme.palette.bg.main,
	},
	headerLogo: {
		cursor: 'pointer',
		width: '89px',
		height: '26px',
		display: 'flex',
		img: {
			maxWidth: '100%',
			maxHeight: '100%',
		},
	},
	headerSearch: {
		maxWidth: '636px',
		minWidth: '152px',
		width: '40%',
		height: '43px',
		justifySelf: 'center',
		display: { xs: 'none', sm: 'block' },
	},
	searchResultsWrapper: {
		position: { xs: 'fixed', md: 'relative' },
		top: { xs: '64px', md: '4px' },
		left: { xs: '0', md: '0' },
		right: { xs: '0', md: '0' },
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '10px',
		maxHeight: '600px',
		overflowY: 'auto',
		maxWidth: '636px',
		minWidth: { xs: '300px', md: '400px' },
		width: { xs: '96%', sm: '90%', md: '100%' },
		padding: '15px 12px',
		borderRadius: '6px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom6}`,
		background: (theme) => theme.palette.bg.main,
	},
	searchResultItem: {
		width: '100%',
		height: '60px',
		paddingBottom: '6px',
		borderBottom: '1px solid #E0E0E0',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '13px',
		cursor: 'pointer',
	},
	searchResultItemImage: {
		width: '54px',
		height: '54px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		borderRadius: '4px',
	},
	searchInput: {
		width: '100%',
		height: '100%',
		border: (theme) => `1px solid ${theme.palette.stroke.custom8}`,
		paddingLeft: '10px',
		boxShadow: '0px 4px 4px rgba(234, 240, 243, 0.72)',
		borderRadius: '90px',
		'&:before, &:after': {
			display: 'none',
		},
		'&::placeholder': {
			fontSize: '12px',
			color: (theme) => theme.palette.stroke.brand,
		},
	},
	aiButton: {
		width: '42px',
		height: '42px',
		padding: '10px',
		borderRadius: '90px',
		background: (theme) => theme.palette.bg.light,
	},
	headerRight: {
		display: 'flex',
		width: 'max-content',
		margin: '0',
	},
	headerProfile: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		maxWidth: '372px',
		marginLeft: { xs: '8px', sm: '14px' },
		cursor: 'pointer',
		position: 'relative',
		span: {
			cursor: 'pointer',
			minWidth: '100px',
			display: { xs: 'none', md: 'block' },
			color: (theme) => theme.palette.stroke.brand,
			fontWeight: '600',
			fontSize: '12px',
			lineHeight: 'normal',
			margin: '0',
			paddingInlineStart: '12px',
			borderLeft: (theme) => `1px solid ${theme.palette.stroke.custom4}`,
		},
	},
	profileMenu: {
		background: (theme) => theme.palette.bg.main,
		width: 200,
		overflow: 'visible',
		filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
		transition: 'opacity 207ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 138ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
		mt: 1.5,
		transformOrigin: '200px 0px',
		position: 'absolute',
		top: 38,
		right: 20,
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			right: 14,
			width: 10,
			height: 10,
			bgcolor: 'background.paper',
			transform: 'translateY(-50%) rotate(45deg)',
			zIndex: 11,
		},
	},
	avatar: {
		cursor: 'pointer',
		width: '31px',
		height: '31px',
		marginLeft: { xs: '0', sm: '15px' },
		img: {
			maxWidth: '100%',
			maxHeight: '100%',
		},
	},
	profileAvatarIcon: {
		display: { xs: 'none', md: 'block' },
		marginLeft: '3px',
	},
};
