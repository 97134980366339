import { Box } from '@mui/material';
import { memo } from 'react';

const VideoscriptPreview = () => {
	return (
		<Box
			sx={{
				padding: '20px 10px 38px 18px',
				background: (theme) => theme.palette.bg.main,
				borderRadius: '12px',
				border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
				boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
			}}
		>
			Sorry for the inconvenience, this functionality is in development
		</Box>
	);
};

export default memo(VideoscriptPreview);
