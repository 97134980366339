import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const RegenerateResponseIcon = ({ sx = {} }) => {
	return (
		<SvgIcon sx={sx}>
			<g
				id="Frame"
				clipPath="url(#clip0_6_90)"
			>
				<g id="Group">
					<path
						id="Vector"
						d="M6.96603 5.29588H10.026L11.2132 7.35226H13.596L15.126 10.0023L13.596 12.6524H11.2107L10.026 14.7043H6.96603L5.43604 12.0543L6.62202 10.0001L5.43604 7.94596L6.96603 5.29588ZM9.3405 6.48321H7.65152L6.80701 7.94592L7.64899 9.40425H9.343L10.1849 7.94592L9.3405 6.48321ZM13.755 10.0023L12.9105 8.53955H11.2216L10.3771 10.0023L11.2216 11.465H12.9105L13.755 10.0023ZM7.65152 13.517H9.3405L10.185 12.0543L9.34304 10.5959H7.64903L6.80705 12.0543L7.65152 13.517Z"
						fill="#606895"
					/>
					<path
						id="Vector_2"
						d="M5.22668 16.0144C9.72581 19.6616 16.6285 16.9538 17.5613 11.3375L17.0777 11.7283L16.3315 10.8048L18.3604 9.1652L20 11.1942L19.0765 11.9404L18.7364 11.5195C16.8828 20.9583 3.89655 21.4104 1.38914 12.1172L2.54232 11.8346C2.94399 13.4734 3.8973 14.9579 5.22668 16.0144ZM14.7733 3.98565C10.2742 0.33848 3.37147 3.04627 2.43874 8.6626L2.9223 8.27185L3.66854 9.19532L1.63963 10.8349L0 8.80599L0.923508 8.05971L1.2636 8.48058C3.11723 -0.958206 16.1034 -1.41031 18.6109 7.88292L17.4577 8.16558C17.056 6.52666 16.1027 5.04218 14.7733 3.98565Z"
						fill="#606895"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_6_90">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="matrix(1 0 0 -1 0 20)"
					/>
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default memo(RegenerateResponseIcon);
