export const sx = {
	container: {
		padding: { xs: '0 10px', md: '0 20px', lg: '0 90px' },
		width: '100%',
		minHeight: 'calc(100vh - 70px - 270px)',
		margin: '33px auto 0 auto',
	},
	listingsCountLabel: {
		fontSize: '18px',
		color: '#000000',
	},
	chatGPTButton: {
		display: 'none',
		borderRadius: '50%',
		background: (theme) => theme.palette.bg.brand,
		padding: '8px',
		minWidth: 42,
		minHeight: 42,
		position: 'fixed',
		bottom: 15,
		right: 10,
		zIndex: 10,
		'@media (max-width: 550px)': {
			display: 'flex',
		},
		'&:hover': {
			background: (theme) => theme.palette.bg.brand,
		},
	},
	roomsList: {
		listStyle: 'none',
		margin: '0 auto',
		padding: 0,
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fit, 291px)',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '34px 20px',
		width: '100%',
	},
	endMessageBox: {
		width: '100%',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '30px',
		marginBottom: { xs: '20px', md: '0px' },
	},
};
