import { propertyTypeMap } from './listingFilter';

/**
 * Retrieves an object with property type filter values based on the provided filter values.
 *
 * @param {Object} values - The filter values containing property type information.
 * @returns {Object} An object with property type filter values.
 */
export function getPropertyTypesForFilter(values) {
	const propertyTypes = {
		isCondo: values.propertyTypes.includes(propertyTypeMap.CONDO),
		isCoOp: values.propertyTypes.includes(propertyTypeMap.COOP),
		isTownhouse: values.propertyTypes.includes(propertyTypeMap.TOWNHOUSE),
		isMultiFamily: values.propertyTypes.includes(propertyTypeMap.MULTI_FAMILY),
		isSingleFamilyResidence: values.propertyTypes.includes(propertyTypeMap.SINGLE_FAMILY_RESIDENCE),
		isDuplex: values.propertyTypes.includes(propertyTypeMap.MULTI_LEVEL),
		isTriplex: values.propertyTypes.includes(propertyTypeMap.MULTI_LEVEL),
		isMixedUse: values.propertyTypes.includes(propertyTypeMap.MIXED_USE),
		isApartment: values.propertyTypes.includes(propertyTypeMap.APARTMENT),
		isOffice: values.propertyTypes.includes(propertyTypeMap.OFFICE),
	};

	return Object.fromEntries(Object.entries(propertyTypes).map(([key, value]) => [key, value ? true : false]));
}

/**
 * Constructs the body of filter options based on the provided filter values.
 *
 * @param {Object} values - The filter values to build the filter options body.
 * @returns {Object} The filter options body object.
 */
export function getFilterOptionsBody(values) {
	if (!values) {
		return {
			isCondo: false,
			isCoOp: false,
			isTownhouse: false,
			isMultiFamily: false,
			isSingleFamilyResidence: false,
			isDuplex: false,
			isTriplex: false,
			isMixedUse: false,
			isApartment: false,
			isOffice: false,
			maxBaths: 0,
			minBaths: 0,
			maxBeds: 0,
			minBeds: 0,
			maxPrice: 0,
			minPrice: 0,
			minSquareFeet: 0,
			maxSquareFeet: 0,
		};
	}

	return {
		...getPropertyTypesForFilter(values),
		maxBaths: values.bathroomsCount === 'any' ? 0 : Number(values.bathroomsCount),
		minBaths: 0,
		maxBeds: values.bedsCount === 'any' ? 0 : Number(values.bedsCount),
		minBeds: 0,
		maxPrice: parseInt(values.maxPrice) || 0,
		minPrice: parseInt(values.minPrice) || 0,
		minSquareFeet: values.minSquare || 0,
		maxSquareFeet: values.maxSquare || 0,
	};
}
