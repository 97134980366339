import { Box, Button, Typography } from '@mui/material';
import RoomMainListItem from '../../components/MainPage/RoomMainListItem/RoomMainListItem';
import { sx } from './mainPage.sx';
import MainPageListingsSkeleton from '../../components/Skeletons/MainPageListingsSkeleton';
import ListingFilters from '../../components/MainPage/ListingFilters/ListingFilters';
import { useMainPageProvider } from './MainPageProvider';
import { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../components/UI/Loader/Loader';
import FilterListingsChips from '../../components/common/FilterListingsChips/FilterListingsChips';

const MainPage = () => {
	//@@viewOn:hooks
	const {
		rooms = [],
		isFetching,
		regions,
		setRegions,
		totalListings,
		setCurrentPage,
		isFilterModalOpen,
		setIsFilterModalOpen,
		handleResetFiltersAndRegions,
		filterChipsList,
		filterOptions,
		handleDeleteFilterField,
		isMobile,
		showOnlyAgentsListings,
		setShowOnlyAgentsListings,
		favoriteListingsIds,
		userListings,
	} = useMainPageProvider();
	//@@viewOff:hooks

	//@@viewOn:handlers

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	//@@viewOff:handlers

	return (
		<>
			<ListingFilters
				isFilterModalOpen={isFilterModalOpen}
				setIsFilterModalOpen={setIsFilterModalOpen}
				regions={regions}
				filterOptions={filterOptions}
				setRegions={setRegions}
				isFetching={isFetching}
				isUsedInMainPage={true}
				handleResetFiltersAndRegions={handleResetFiltersAndRegions}
				isMobile={isMobile}
				showOnlyAgentsListings={showOnlyAgentsListings}
				setShowOnlyAgentsListings={setShowOnlyAgentsListings}
			/>
			<FilterListingsChips
				filterOptions={filterOptions}
				regions={regions}
				handleDeleteFilterField={handleDeleteFilterField}
				filterChipsList={filterChipsList}
				isForMainPage={true}
			/>
			{isFetching ? (
				<MainPageListingsSkeleton />
			) : (
				<Box
					sx={sx.container}
					id="scrollableDiv"
				>
					<Box sx={{ marginTop: '26px' }}>
						<Box sx={{ marginLeft: '20px' }}>
							<Typography
								variant="h1"
								fontWeight="bold"
								sx={sx.listingsCountLabel}
							>
								{`You currently have ${totalListings} listings available`}
							</Typography>
						</Box>
						<Box sx={{ margin: { xs: '25px 0 0 0', md: '25px 0 20px 0', position: 'relative' } }}>
							<InfiniteScroll
								key="infinite-scroll-main-page"
								loadMore={() => setCurrentPage((prev) => prev + 1)}
								initialLoad={false}
								hasMore={showOnlyAgentsListings ? false : rooms?.length < totalListings}
								threshold={400}
								useWindow={true}
								loader={
									!showOnlyAgentsListings ? (
										<Loader
											key="infinite-scroll-loader"
											sx={{ height: '60px' }}
										/>
									) : null
								}
							>
								<Box sx={sx.roomsList}>
									{(showOnlyAgentsListings ? userListings : rooms).map((room) => {
										const isLiked = favoriteListingsIds.find((listing) => listing === room.Id);

										return (
											<RoomMainListItem
												room={room}
												key={room.Id}
												isLiked={isLiked}
												isUsedInProfilePage={false}
											/>
										);
									})}
								</Box>
							</InfiniteScroll>
							{(showOnlyAgentsListings ? userListings : rooms)?.length >= totalListings && (
								<Box sx={sx.endMessageBox}>
									<Button
										variant="contained"
										onClick={scrollToTop}
									>
										Scroll top
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};

export default memo(MainPage);
