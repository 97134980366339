export const sx = {
	container: {
		width: '100%',
		maxWidth: '1156px',
		display: 'flex',
		flexDirection: { xs: 'column', md: 'row' },
		padding: { xs: '5px 5px 30px 5px', sm: '17px 17px 40px 17px' },
		gap: '20px',
		marginTop: '12px',
		borderRadius: '8px',
		border: { xs: 'none', sm: (theme) => `1px solid ${theme.palette.stroke.main}` },
		background: (theme) => theme.palette.bg.main,
		boxShadow: '0px 2px 4px 0px #EAF0F3',
	},
	photosAndDetails: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	imagesWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		gap: '9px',
		paddingBottom: '25px',
		borderBottom: (theme) => `1px solid  ${theme.palette.stroke.custom11}`,
	},
	mainImageWrapper: {
		width: '100%',
		minHeight: '360px',
		maxHeight: { xs: '454px', md: '372.7px' },
		height: '100%',
		position: 'relative',
		display: 'flex',
	},
	mainImage: {
		width: '100%',
		maxWidth: '100%',
		borderRadius: '8px',
		objectFit: 'cover',
		objectPosition: 'center',
	},
	imagesGallery: {
		display: 'flex',
		width: '100%',
		gap: '7px',
		maxWidth: '100%',
		minHeight: '87px',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	galleryImageItem: {
		width: '81px',
		height: '87px',
		borderRadius: '7px',
	},
	mainImageActions: {
		position: 'absolute',
		top: '12px',
		right: '10px',
		zIndex: 11,
		display: 'flex',
		gap: '6px',
	},
	actionButton: {
		padding: '4px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '10px',
		borderRadius: '50%',
		background: 'rgba(0, 0, 0, 0.10)',
		flexShrink: 0,
		width: '25px',
		height: '25px',
	},
	actionButtonIcon: {
		width: '17px',
		height: '17px',
		color: (theme) => theme.palette.bg.main,
	},
	detailsBlock: {
		marginTop: '14px',
		width: '100%',
		paddingBottom: '25px',
		borderBottom: (theme) => `1px solid  ${theme.palette.stroke.custom11}`,
	},
	blockTitle: {
		color: '#4E4F69',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		marginBottom: '10px',
	},
	detailsList: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		gap: '13px',
	},
	detailsItem: {
		borderRadius: '6px',
		border: (theme) => `1px solid ${theme.palette.stroke.main}`,
		display: 'flex',
		width: { xs: '45%', md: '156px' },
		maxWidth: '156px',
		padding: '12px 13px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '3px',
	},
	detailsItemLabel: {
		color: '#4E4F69',
		fontSize: '12px',
		fontWeight: '600',
		lineHeight: '16px',
		overflow: 'hidden',
	},
	listingInfoItem: {
		display: 'flex',
		width: '45%',
		maxWidth: '245px',
		padding: '12px 13px',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: '3px',
		borderRadius: '6px',
		border: (theme) => `1px solid ${theme.palette.stroke.main}`,
		overflow: 'hidden',
		'@media (max-width: 450px)': {
			maxWidth: 'none',
			width: '100%',
		},
	},
	infoLabel: {
		color: '#4E4F69',
		fontSize: '12px',
		fontWeight: '700',
		lineHeight: '16px',
		opacity: 0.5,
	},
	infoValue: {
		color: '#4E4F69',
		fontSize: '12px',
		fontWeight: '700',
		lineHeight: '16px',
	},
	featuresList: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		width: '100%',
		flexWrap: 'wrap',
		gap: '9px 40px',
		maxHeight: '125px',
	},
	featuresListItem: {
		width: '40%',
	},
	mapAndDescription: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	mapWrapper: {
		height: { xs: '337px', sm: '437px', md: '337px' },
		width: '100%',
	},
	descriptionWrapper: {
		marginTop: '21px',
		width: '100%',
	},
	descriptionTitle: {
		color: '#2F3047',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
	},
	descriptionText: {
		marginTop: '18px',
		maxWidth: { xs: '90%', md: '450px' },
		color: '#4E4F69',
		fontSize: '12px',
		lineHeight: '18px',
	},
};
