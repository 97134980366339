import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { sx } from './savePresetModal.sx';
import { Close, HourglassTopOutlined } from '@mui/icons-material';
import FileInput from '../../UI/FileInput/FileInput';
import { useFormik } from 'formik';
import AdvancedFiltersFormFields from '../../EditingImagesPage/BasicFiltersFormFields/BasicFiltersFormFields';
import { useEffect, useMemo } from 'react';
import progressiveImage from '../../UI/ProgressiveImage/ProgressiveImage';
import {
	defaultAdvancedValues,
	getAdvancedFiltersFormValues,
} from '../../../utils/editingImages/getAdvancedFiltersFormValues';
import { toast } from 'react-toastify';
import Loader from '../../UI/Loader/Loader';
import { memo } from 'react';

const SavePresetModal = ({ open, handleClose, handleSavePreset, activePreset = null, isSavingPreset }) => {
	//@@viewOn:statics
	const initialFormValues = {
		image: null,
		name: '',
		...defaultAdvancedValues,
	};

	const activePresetInitialValues = useMemo(
		() => ({
			image: activePreset?.thumbnail,
			name: activePreset?.name,
			...getAdvancedFiltersFormValues(activePreset),
		}),
		[activePreset],
	);
	//@@viewOff:statics

	//@@viewOn:handlers
	const handleSubmitSavingPreset = (values) => {
		handleSavePreset(values);
	};

	const formik = useFormik({
		initialValues: activePreset ? activePresetInitialValues : initialFormValues,
		onSubmit: handleSubmitSavingPreset,
	});

	const { handleSubmit, setFieldValue, values, errors } = formik;

	const handleFileChange = (e) => {
		const file = e.target.files[0];

		if (!file) {
			toast.error('Image was not provided');
			return;
		}

		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
			toast.error('Image type is not supported');
			return;
		}
		setFieldValue('image', file);
	};

	const previewImage = useMemo(() => {
		if (values?.image && typeof values?.image === 'string') {
			return values.image;
		} else if (values?.image && !(typeof values?.image === 'string')) {
			return URL.createObjectURL(values.image);
		} else if (activePreset?.thumbnail) {
			return activePreset?.thumbnail;
		} else {
			return progressiveImage;
		}
	}, [activePreset, values.image]);
	//@@viewOff:handlers

	//@@viewOn:effects
	useEffect(() => {
		async function setInitialValues() {
			if (activePreset) await formik.setValues(activePresetInitialValues, false);
		}

		setInitialValues();
	}, [activePreset]);
	//@@viewOff:effects

	return (
		<Modal
			open={open}
			onClose={handleClose}
			keepMounted={false}
		>
			<form
				onSubmit={handleSubmit}
				data-testid="save-preset-modal"
			>
				<Box sx={sx.container}>
					<Box sx={sx.header}>
						<Typography sx={sx.headerTitle}>Edit Preset</Typography>
						<Close
							data-testid="close-icon"
							onClick={handleClose}
							sx={{ cursor: 'pointer' }}
						/>
					</Box>
					<Box sx={sx.content}>
						<Box sx={sx.section}>
							<Typography sx={sx.sectionTitle}>Preset Name</Typography>
							<TextField
								name="name"
								value={values.name}
								onChange={(e) => setFieldValue('name', e.target.value)}
								error={!!errors.name}
								helperText={errors.name}
								sx={sx.nameInput}
								InputProps={sx.nameInputProps}
							/>
						</Box>
						<Box sx={sx.formGroup}>
							<AdvancedFiltersFormFields
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								itemsPositioningInRaw={true}
							/>
						</Box>
						<Box sx={sx.previewWrapper}>
							<Box sx={sx.section}>
								<Typography sx={sx.sectionTitle}>Preset Image</Typography>
								<FileInput
									image={values.image}
									setImage={(item) => setFieldValue('image', item)}
									handleFileChange={handleFileChange}
								/>
								<Typography sx={sx.fileUploadInfo}>JPG, GIF or PNG</Typography>
							</Box>
							{(values.image || activePreset?.thumbnail) && (
								<>
									{isSavingPreset ? (
										<Loader
											data-testid="loader"
											sx={{ height: '100%' }}
											disableShrink={true}
										/>
									) : (
										<Box sx={sx.previewSection}>
											<img
												src={previewImage}
												alt="preview"
												style={{ width: '100%' }}
											/>
										</Box>
									)}
								</>
							)}
						</Box>
					</Box>
					<Box sx={sx.footer}>
						<Button
							onClick={handleClose}
							disabled={isSavingPreset}
							variant="outlined"
							sx={sx.footerButton}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={isSavingPreset}
							variant="contained"
							sx={sx.footerButton}
							endIcon={
								isSavingPreset && <HourglassTopOutlined sx={{ paddingLeft: '5px', height: '20px', width: '20px' }} />
							}
						>
							{activePreset ? 'Update Preset' : 'Save Preset'}
						</Button>
					</Box>
				</Box>
			</form>
		</Modal>
	);
};

export default memo(SavePresetModal);
