import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';

const GeneratedResponseSkeleton = () => (
	<Box
		sx={{
			width: '100%',
			padding: '17px 15px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			gap: '20px',
			marginBottom: '10px',
			border: (theme) => `1px solid ${theme.palette.stroke.main}`,
			borderRadius: '4px',
		}}
	>
		<Box
			sx={{
				width: '100%',
			}}
		>
			<Skeleton
				variant="text"
				height={20}
			/>
			<Skeleton
				variant="text"
				height={20}
			/>
			<Skeleton
				variant="text"
				height={20}
			/>
			<Skeleton
				variant="text"
				height={20}
			/>
		</Box>
		<Box
			sx={{
				display: 'flex',
				gap: '7px',
				minWidth: '98px',
				height: '28px',
			}}
		>
			<Skeleton
				variant="rounded"
				width={28}
				height={28}
			/>
			<Skeleton
				variant="rounded"
				width={28}
				height={28}
			/>
			<Skeleton
				variant="rounded"
				width={28}
				height={28}
			/>
		</Box>
	</Box>
);

export default memo(GeneratedResponseSkeleton);
