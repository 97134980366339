import { toast } from 'react-toastify';
import RoomsAPI from '../../services/roomsAPI';
import { useQuery } from '@tanstack/react-query';
import { getFilterOptionsBody } from '../../utils/listing/getPropertyTypesForFilter';

const useGetAllListings = () => {
	const {
		isLoading: listingsIsLoading,
		data: allListings,
		error: allListingsError,
	} = useQuery({
		queryKey: ['getListingsRooms', { filterOptions: null, regions: null, showOnlyAgentsListings: false }],
		queryFn: async () => {
			const filterOptions = getFilterOptionsBody(null);

			return await RoomsAPI.getRoomsPost({
				...filterOptions,
				regionsSelected: [],
				showOnlyAgentsListings: false,
			});
		},
		onError: (error) => {
			toast.error('Could not get your favorite listings, try again');
			console.log(error);
		},
	});

	return { listingsIsLoading, allListings, allListingsError };
};

export default useGetAllListings;
