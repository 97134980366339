const REGIONS = [
	{ label: 'Manhattan', city: 'manhattan' },
	{ label: 'Brooklyn', city: 'brooklyn' },
	{ label: 'Staten Island', city: 'statenIsland' },
	{ label: 'Bronx', city: 'bronx' },
	{ label: 'Queens', city: 'queens' },
	{ label: 'Long Island', city: 'longIsland' },
	{ label: 'Upstate New York', city: 'upStateNewYork' },
	{ label: 'Connecticut', city: 'connecticut' },
	{ label: 'Florida', city: 'florida' },
	{ label: 'Pennsylvania', city: 'pennsylvania' },
	{ label: 'New Jersey', city: 'newJersey' },
	{ label: 'Hamptons', city: 'hamptons' },
];

export { REGIONS };
