export const filterFieldsOptions = {
	photoCompositionStyle: {
		label: 'Photo Composition Style',
		value: 'photoCompositionStyle',
		options: [
			{ value: 'Editorial Style Photo', label: 'Editorial Style Photo' },
			{ value: 'Architectural', label: 'Architectural' },
			{ value: 'Minimalist', label: 'Minimalist' },
			{ value: 'HDR (High Dynamic Range)', label: 'HDR (High Dynamic Range)' },
			{ value: 'Commercial', label: 'Commercial' },
			{ value: 'Real-estate', label: 'Real-estate' },
		],
		tooltipTitle: 'Choose the photo composition style',
		buttonsWithPaddings: true,
	},
	cameraAngle: {
		label: 'Camera Angle',
		value: 'cameraAngle',
		options: [
			{ value: 'High Angle', label: 'High Angle' },
			{ value: 'Eye-Level', label: 'Eye-Level' },
			{ value: 'Low Angle', label: 'Low Angle' },
			{ value: "Bird's Eye View", label: "Bird's Eye View" },
		],
		tooltipTitle: 'Choose the camera angle',
		buttonsWithPaddings: true,
	},
	aspectRatio: {
		label: 'Aspect Ratio',
		value: 'aspectRatio',
		options: [
			{ value: '3:2', label: '3:2' },
			{ value: '16:9', label: '16:9' },
			{ value: '4:3', label: '4:3' },
			{ value: '1:1', label: '1:1' },
			{ value: '5:4', label: '5:4' },
		],
		tooltipTitle: 'Choose aspect ratio of the images',
		buttonsWithPaddings: false,
		isParam: true,
	},
	roomType: {
		label: 'Room Type',
		value: 'roomType',
		options: [
			{ value: 'Bedroom', label: 'Bedroom' },
			{ value: 'Living Room', label: 'Living Room' },
			{ value: 'Kitchen', label: 'Kitchen' },
			{ value: 'Bathroom', label: 'Bathroom' },
			{ value: 'Dining Room', label: 'Dining Room' },
			{ value: 'Home Office', label: 'Home Office' },
			{ value: 'Utility/Storage Room', label: 'Utility/Storage Room' },
			{ value: 'Entertainment/Recreation', label: 'Entertainment/Recreation' },
			{ value: 'Outdoor Space', label: 'Outdoor Space' },
			{ value: 'Specialty', label: 'Specialty' },
		],
	},
	timeOfDay: {
		label: 'Time of Day',
		value: 'timeOfDay',
		options: [
			{ value: 'Afternoon', label: 'Afternoon' },
			{ value: 'Morning', label: 'Morning' },
			{ value: 'Evening', label: 'Evening' },
			{ value: 'Midday', label: 'Midday' },
			{ value: 'Night', label: 'Night' },
		],
		tooltipTitle: 'Choose the time of day',
		buttonsWithPaddings: true,
	},
	engine: {
		label: 'Engine',
		value: 'engine',
		options: [{ value: 'midjourney', label: 'Midjourney' }],
		tooltipTitle: 'Choose an engine for generating images',
		buttonsWithPaddings: false,
		isParam: true,
	},
	version: {
		label: 'Version',
		value: 'version',
		options: [
			{ value: '1', label: '1' },
			{ value: '2', label: '2' },
			{ value: '3', label: '3' },
			{ value: '3.5', label: '3.5' },
			{ value: '4', label: '4' },
			{ value: '5', label: '5' },
			{ value: '5.1', label: '5.1' },
			{ value: '5.2', label: '5.2' },
		],
		tooltipTitle: 'Choose a version for generating images',
		buttonsWithPaddings: false,
		isParam: true,
	},
	chaos: {
		label: 'Chaos',
		value: 'chaos',
		options: [
			{ value: '0', label: '0' },
			{ value: '25', label: '25' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		],
		tooltipTitle: 'Choose a chaos for generating images',
		buttonsWithPaddings: false,
		isParam: true,
	},
	weird: {
		label: 'Weird',
		value: 'weird',
		options: [
			{ value: '0', label: '0' },
			{ value: '250', label: '250' },
			{ value: '500', label: '500' },
			{ value: '1000', label: '1000' },
			{ value: '2000', label: '2000' },
			{ value: '3000', label: '3000' },
		],
		tooltipTitle: 'Choose a weirdness for generating images',
		buttonsWithPaddings: false,
		isParam: true,
	},
	quality: {
		label: 'Quality',
		value: 'quality',
		options: [
			{ value: 0.25, label: 0.25 },
			{ value: 0.5, label: 0.5 },
			{ value: 1, label: 1 },
		],
		tooltipTitle: 'Choose a quality for generating images',
		buttonsWithPaddings: false,
		isParam: true,
	},
};
