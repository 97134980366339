import { Box } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { sx } from './twitter.sx';
import TwitterActionsIcons from './TwitterActionsIcons';
import AvatarAndNameForPreview from '../../AvatarAndNameForPreview/AvatarAndNameForPreview';
import { SocialMediaTypes } from '../../../../utils/creationContent/socialMediaTypes';
import { memo } from 'react';

const TwitterPreview = ({ text, image = null, userDisplayName = '', profilePhoto }) => {
	return (
		<Box sx={sx.container}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					paddingRight: '14px',
				}}
			>
				<AvatarAndNameForPreview
					image={profilePhoto}
					name={userDisplayName}
					status="New York, new York"
					socialMediaType={SocialMediaTypes.TWITTER}
				/>
				<MoreHoriz />
			</Box>
			<Box
				sx={{
					marginTop: '20px',
					color: '#3A3A3A',
					fontSize: '12px',
					fontWeight: '500',
				}}
			>
				{text}
			</Box>
			<Box sx={sx.image(image)} />
			<TwitterActionsIcons />
		</Box>
	);
};

export default memo(TwitterPreview);
