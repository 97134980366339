import { useOutletContext } from 'react-router-dom';
import ProfileListingItem from './ProfileListingItem';
import { memo } from 'react';

const ProfileListingOutletProvider = () => {
	const {
		setActiveListing,
		listings,
		activeListing,
		isMobile,
		handleToggleLikeListingItem,
		detailedListingData,
		detailedListingIsLoading,
	} = useOutletContext();

	return (
		<ProfileListingItem
			activeListing={activeListing}
			listings={listings}
			isLiked={activeListing?.isLiked || false}
			setActiveListing={setActiveListing}
			isMobile={isMobile}
			handleToggleLikeListingItem={handleToggleLikeListingItem}
			detailedListingData={detailedListingData}
			detailedListingIsLoading={detailedListingIsLoading}
		/>
	);
};

export default memo(ProfileListingOutletProvider);
