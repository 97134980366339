export const sx = {
	container: {
		padding: '20px 10px 38px 18px',
		background: (theme) => theme.palette.bg.main,
		borderRadius: '12px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
		boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
	},

	image: (imageProp) => ({
		margin: '15px 0 0 6px',
		...(imageProp && { backgroundImage: `url(${imageProp})` }),
		borderRadius: '12px',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		minHeight: '308px',
	}),
};
