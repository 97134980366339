import useGetDetailedListing from '../useGetDetailedListing/useGetDetailedListing';
import { useEffect } from 'react';

const useHandleDetailedListingItem = ({ listingId, allListings, favoriteListingsIds, setActiveListing }) => {
	const { detailedListingData, detailedListingIsLoading } = useGetDetailedListing({ listingId });

	useEffect(() => {
		if (listingId) {
			const currentActiveListing = allListings?.find((listing) => listing.Id === listingId);

			if (!currentActiveListing || !detailedListingData) {
				setActiveListing(undefined);
				return;
			}

			if (listingId !== currentActiveListing.Id) {
				setActiveListing(undefined);
				return;
			}

			const activeListingsWithFilteredMedia =
				currentActiveListing?.Media?.length > 1
					? {
							isLiked: favoriteListingsIds.includes(currentActiveListing.Id),
							...currentActiveListing,
							...(currentActiveListing && { Media: currentActiveListing?.Media.slice(1, 6) }),
							description: detailedListingData?.PublicRemarks,
							roomsTotal: detailedListingData?.RoomsTotal,
					  }
					: {
							isLiked: favoriteListingsIds.includes(currentActiveListing.Id),
							...currentActiveListing,
							...(currentActiveListing && { Media: currentActiveListing?.Media }),
							description: detailedListingData?.PublicRemarks,
							roomsTotal: detailedListingData?.RoomsTotal,
					  };
			setActiveListing(activeListingsWithFilteredMedia);
		}
	}, [listingId, allListings, favoriteListingsIds, detailedListingData, detailedListingIsLoading]);

	return { detailedListingData, detailedListingIsLoading };
};

export default useHandleDetailedListingItem;
