import { Box, Button } from '@mui/material';
import { ChatBubbleOutlineRounded, ReplyOutlined, ThumbUpOffAlt } from '@mui/icons-material';
import { sx } from './facebook.sx';
import FacebookMediaMetrics from './FacebookMediaMetrics';
import { SocialMediaTypes } from '../../../../utils/creationContent/socialMediaTypes';
import AvatarAndNameForPreview from '../../AvatarAndNameForPreview/AvatarAndNameForPreview';
import { memo } from 'react';

const FacebookPreview = ({ text, image = null, userDisplayName = '', profilePhoto }) => {
	return (
		<Box sx={sx.container}>
			<Box sx={{ padding: '0 18px' }}>
				<AvatarAndNameForPreview
					image={profilePhoto}
					name={userDisplayName}
					status="2hr ago"
					socialMediaType={SocialMediaTypes.FACEBOOK}
				/>
			</Box>
			<Box
				sx={{
					padding: '0 18px',
					marginTop: '18px',
					color: '#3A3A3A',
					fontSize: '12px',
					fontWeight: '500',
				}}
			>
				{text}
			</Box>
			<Box sx={sx.image(image)} />
			<FacebookMediaMetrics />
			<Box
				sx={{
					margin: '18px auto 0 auto',
					padding: '0 18px',
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Button
					disableRipple={true}
					sx={sx.actionButton}
					startIcon={<ThumbUpOffAlt sx={{ marginRight: '6px' }} />}
				>
					Like
				</Button>
				<Button
					disableRipple={true}
					sx={sx.actionButton}
					startIcon={<ChatBubbleOutlineRounded sx={{ marginRight: '6px' }} />}
				>
					Comment
				</Button>
				<Button
					disableRipple={true}
					sx={sx.actionButton}
					startIcon={<ReplyOutlined sx={{ transform: 'rotateY(180deg)', marginRight: '6px' }} />}
				>
					Share
				</Button>
			</Box>
		</Box>
	);
};

export default memo(FacebookPreview);
