import { Skeleton, Box, Stack, Typography } from '@mui/material';
import { memo } from 'react';

const ChosenListingsSkeleton = () => (
	<Box sx={{ padding: '0 14px' }}>
		{[1, 2, 3, 4].map((item) => (
			<Box
				key={item}
				sx={{
					width: '100%',
					height: '125px',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					background: (theme) => theme.palette.bg.main,
					marginTop: '23px',
					paddingRight: '8px',
					borderRight: '5px solid transparent',
					cursor: 'pointer',
				}}
			>
				<Skeleton
					variant="rectangular"
					width={96}
					height={102}
					sx={{
						minWidth: '96px',
						minHeight: '102px',
						borderRadius: '12px',
						marginRight: '15px',
					}}
				/>
				<Stack
					justifyContent="flex-start"
					direction="column"
					sx={{
						width: '100%',
						maxHeight: '125px',
						height: 'fit-content',
						overflow: 'hidden',
						margin: '3px 0 0 15px',
					}}
				>
					<Typography
						variant="h3"
						fontWeight="bold"
						sx={{
							color: (theme) => theme.palette.text.secondary,
							fontSize: '18px',
							fontWeight: '700',
							textTransform: 'uppercase',
						}}
					>
						<Skeleton
							variant="text"
							width="90%"
						/>
					</Typography>
					<Typography
						variant="h3"
						fontWeight="bold"
						sx={{
							color: (theme) => theme.palette.text.tertiary,
							fontWeight: '600',
							textTransform: 'uppercase',
						}}
					>
						<Skeleton
							variant="text"
							width={100}
						/>
					</Typography>
				</Stack>
			</Box>
		))}
	</Box>
);

export default memo(ChosenListingsSkeleton);
