import Facebook from '../../assets/socialMediaLogos/facebook.svg';
import Twitter from '../../assets/socialMediaLogos/Twitter.svg';
import Instagram from '../../assets/socialMediaLogos/Instagram.svg';
import Tiktok from '../../assets/socialMediaLogos/toggle-tiktok.svg';
import BlogPost from '../../assets/socialMediaLogos/blogpost.svg';
import VideoScript from '../../assets/socialMediaLogos/video-script.svg';
import ListingDescription from '../../assets/socialMediaLogos/list-desc.svg';
import Email from '../../assets/socialMediaLogos/email.svg';

const SocialMediaTypes = {
	FACEBOOK: 'FACEBOOK',
	TWITTER: 'TWITTER',
	INSTAGRAM: 'INSTAGRAM',
	TIKTOK: 'TIKTOK',
	BLOG_POST: 'BLOG_POST',
	VIDEO_SCRIPT: 'VIDEO_SCRIPT',
	LISTING_DESCRIPTION: 'LISTING_DESCRIPTION',
	EMAIL: 'EMAIL',
};

const socialMediaButtonsProps = [
	{
		type: SocialMediaTypes.FACEBOOK,
		image: Facebook,
		label: SocialMediaTypes.FACEBOOK.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.TWITTER,
		image: Twitter,
		label: SocialMediaTypes.TWITTER.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.INSTAGRAM,
		image: Instagram,
		label: SocialMediaTypes.INSTAGRAM.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.TIKTOK,
		image: Tiktok,
		label: SocialMediaTypes.TIKTOK.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.BLOG_POST,
		image: BlogPost,
		label: SocialMediaTypes.BLOG_POST.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.VIDEO_SCRIPT,
		image: VideoScript,
		label: SocialMediaTypes.VIDEO_SCRIPT.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.LISTING_DESCRIPTION,
		image: ListingDescription,
		label: SocialMediaTypes.LISTING_DESCRIPTION.replace(/_/g, ' '),
	},
	{
		type: SocialMediaTypes.EMAIL,
		image: Email,
		label: SocialMediaTypes.EMAIL.replace(/_/g, ' '),
	},
];

export { SocialMediaTypes, socialMediaButtonsProps };
