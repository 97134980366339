import { Box, Card } from '@mui/material';
import { memo } from 'react';

//@@viewOn:private
const commonSx = {
	display: 'flex',
	width: '100%',
};

const cardSx = {
	width: '100%',
	height: '84px',
	padding: '8px 9px 12px 12px',
	borderRadius: '8px',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
};
//@@viewOff:private

const SocialMediaToggleCard = ({ card, isActive = false, handleSocialMediaToggle }) => {
	return (
		<Card
			onClick={() => handleSocialMediaToggle(card.type)}
			sx={{
				...commonSx,
				...cardSx,
				border: (theme) => (isActive ? `2px solid ${theme.palette.bg.brand}` : `1px solid #D0D0D0`),
			}}
		>
			<Box
				sx={{
					...commonSx,
					justifyContent: 'flex-end',
					height: 24,
					img: {
						width: 24,
						height: 24,
						objectFit: 'contain',
					},
				}}
			>
				{card.image && (
					<img
						src={card.image}
						style={{ width: 18, height: 18 }}
						alt={card.type}
					/>
				)}
			</Box>
			<Box
				sx={{
					...commonSx,
					color: (theme) => theme.palette.text.primary,
					fontSize: '14px',
					fontWeight: '700',
					letterSpacing: '0.4px',
					textTransform: 'uppercase',
				}}
			>
				{card.label}
			</Box>
		</Card>
	);
};

export default memo(SocialMediaToggleCard);
