export const PROFILE_NAVIGATION = {
	LISTINGS: {
		value: '',
		label: 'Listings',
	},
	GENERATED_IMAGES: {
		value: 'images',
		label: 'Generated Images',
	},
	CHATS: {
		value: 'chats',
		label: 'Chats',
	},
	SAVED_FILTERS: {
		value: 'filters',
		label: 'Saved Filters',
	},
};
