import { sx } from './chatThreads.sx';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { AddBoxOutlined, CloseOutlined, Delete } from '@mui/icons-material';
import { createTimeCategoriesForChats } from '../../../utils/chat/createTimeCategoriesForChats';
import { useMemo } from 'react';
import { useChatPage } from '../../../pages/ChatPage/ChatPageProvider';
import { memo } from 'react';

const ChatThreads = ({ containedSx = {} }) => {
	//@@viewOn:hooks
	const {
		activeChatThread,
		chatThreads = [],
		handleChooseChatThread,
		isMobile = false,
		handleToggleChatThreads,
		handleCreateNewChatThread,
		handleDeleteAllChatThreads,
	} = useChatPage();
	//@@viewOff:handlers

	//@@viewOn:statics
	const dateChatCategories = useMemo(() => {
		return createTimeCategoriesForChats(chatThreads);
	}, [chatThreads]);

	const filteredDateChatCategories = useMemo(() => {
		// eslint-disable-next-line no-unused-vars
		return Object.entries(dateChatCategories).filter(([_, chatThreads]) => chatThreads.length > 0);
	}, [dateChatCategories]);
	//@@viewOff:statics

	return (
		<Box
			sx={{
				...sx.chatThreadsWrapper,
				...containedSx,
			}}
		>
			<Box sx={sx.content}>
				<Box sx={sx.titleBlock}>
					<Typography sx={sx.titleLabel}>Chat threads</Typography>
					{isMobile && (
						<IconButton onClick={() => handleToggleChatThreads(false)}>
							<CloseOutlined />
						</IconButton>
					)}
				</Box>

				<Box sx={sx.chatThreads}>
					{filteredDateChatCategories.map(([date, chats]) => (
						<Box key={date}>
							<Typography
								variant="body1"
								sx={sx.timeGroupLabel}
							>
								{date}
							</Typography>
							{chats.map((chatThread) => (
								<Box
									key={chatThread.id}
									onClick={() => handleChooseChatThread(chatThread.id)}
									sx={sx.chatThreadItem}
								>
									<Typography
										variant="body1"
										sx={{
											...(chatThread.id === activeChatThread?.id && sx.activeChatThreadItem),
											...sx.chatThreadItemName,
										}}
									>
										{chatThread.chatName.split(' ').slice(0, 6).join(' ').replace(/"/g, '')}
									</Typography>
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
			<Box sx={sx.chatThreadsControls}>
				<Button
					sx={sx.clearThreadsButton}
					variant="outlined"
					endIcon={<Delete />}
					onClick={handleDeleteAllChatThreads}
				>
					Clear threads
				</Button>
				<Button
					sx={sx.createNewChatButton}
					variant="contained"
					endIcon={<AddBoxOutlined sx={{}} />}
					onClick={handleCreateNewChatThread}
				>
					Create new chat
				</Button>
			</Box>
		</Box>
	);
};

export default memo(ChatThreads);
