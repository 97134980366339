import React, { createContext, memo, useCallback, useContext, useMemo } from 'react';
import useHandleListings from '../../hooks/useHandleListings/useHandleListings';
import { useAppProvider } from '../Layout/LayoutProvider';
import { makeFilterChipsList } from '../../utils/listing/makeFilterChipsList';
import useFavoriteListings from '../../hooks/useFavoriteListings/useFavoriteListings';
import { useDocumentTitle } from 'usehooks-ts';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';

const MainPageContext = createContext(null);

const MainPageProvider = ({ children }) => {
	//@@viewOn:hooks
	const { UID } = useAuthorization();

	useDocumentTitle(`Home | S.GPT`);

	const {
		currentPage,
		rooms,
		setCurrentPage,
		setRooms,
		isFilterModalOpen,
		setIsFilterModalOpen,
		regions,
		setRegions,
		filterOptions,
		setFilterOptions,
		isMobile,
		showOnlyAgentsListings,
		setShowOnlyAgentsListings,
	} = useAppProvider();

	const { favoriteListingsIds } = useFavoriteListings({ UID });

	const filterChipsList = useMemo(() => makeFilterChipsList(filterOptions, regions || []), [filterOptions, regions]);

	const { data, isLoading, isFetching, userListings, error } = useHandleListings({
		filterOptions,
		currentPage,
		regions,
		rooms,
		setCurrentPage,
		setRooms,
		showOnlyAgentsListings,
		favoriteListingsIds,
	});

	const totalListings = useMemo(() => {
		if (showOnlyAgentsListings) {
			return data?.filter((listing) => favoriteListingsIds.includes(listing.Id)).length || 0;
		}
		return data?.length || 0;
	}, [data, showOnlyAgentsListings, favoriteListingsIds]);

	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleResetFiltersAndRegions = useCallback(() => {
		setFilterOptions(null);
		setRegions([]);
		setShowOnlyAgentsListings(false);
	}, [setRooms, setRegions, setShowOnlyAgentsListings]);

	const handleDeleteFilterField = useCallback(
		(filterField) => {
			if (filterField.city) {
				const newRegions = regions?.filter((region) => region !== filterField.city);
				setRegions(newRegions);
				return;
			}

			const updatedFilterOptions = { ...filterOptions };
			typeof updatedFilterOptions[filterField] === 'number'
				? (updatedFilterOptions[filterField] = 0)
				: (updatedFilterOptions[filterField] = false);

			setFilterOptions(updatedFilterOptions);
		},
		[regions, filterOptions],
	);
	//@@viewOn:handlers

	//@@viewOn:context
	const contextValue = useMemo(
		() => ({
			error,
			rooms,
			regions,
			setRegions,
			currentPage,
			setCurrentPage,
			isFilterModalOpen,
			setIsFilterModalOpen,
			isFetching: isLoading || isFetching,
			totalListings,
			handleResetFiltersAndRegions,
			filterChipsList,
			filterOptions,
			handleDeleteFilterField,
			isMobile,
			showOnlyAgentsListings,
			setShowOnlyAgentsListings,
			favoriteListingsIds,
			userListings,
			UID,
		}),
		[
			error,
			rooms,
			regions,
			setRegions,
			currentPage,
			setCurrentPage,
			isFilterModalOpen,
			setIsFilterModalOpen,
			isFetching,
			data,
			filterOptions,
			handleResetFiltersAndRegions,
			filterChipsList,
			handleDeleteFilterField,
			isMobile,
			showOnlyAgentsListings,
			setShowOnlyAgentsListings,
			favoriteListingsIds,
			userListings,
			UID,
		],
	);
	//@@viewOff:context

	return <MainPageContext.Provider value={contextValue}>{children}</MainPageContext.Provider>;
};

const useMainPageProvider = () => {
	const context = useContext(MainPageContext);
	if (!context) {
		throw new Error('useMainPageProvider must be used within a MainPageProvider');
	}
	return context;
};

export default memo(MainPageProvider);
export { useMainPageProvider };
