import { filterFieldsOptions } from './filterFieldsOptions';

function formatParam(key, value) {
	return value !== undefined && value !== null ? `--${key} ${value}` : '';
}

export const createImagineMessageFromValues = (imgUrl, filters) => {
	const { photoCompositionStyle, roomType, chaos, weird, quality, version, aspectRatio, stylize } = filters;
	const obligatoryFields = [
		'photoCompositionStyle',
		'roomType',
		'engine',
		'aspectRatio',
		'chaos',
		'quality',
		'version',
		'weird',
		'stylize',
	];

	let msg = `${imgUrl || ''} ${photoCompositionStyle || 'Editorial Style Photo'}, ${roomType || 'Room'}, `;

	const params = [
		formatParam('stylize', stylize),
		formatParam('chaos', chaos),
		formatParam('quality', quality),
		formatParam('version', version),
		formatParam('weird', weird),
		formatParam('aspect', aspectRatio),
	]
		.filter(Boolean)
		.join(' ');

	Object.entries(filters).forEach(([key, value]) => {
		if (!filterFieldsOptions[key]?.isParam) {
			if (value && !obligatoryFields.includes(key)) {
				msg += value + ', ';
			}
		}
	});

	return msg + params;
};
