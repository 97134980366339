export const initialPrompts = {
	FACEBOOK: 'facebook post',
	TWITTER: 'twitter post',
	INSTAGRAM: 'instagram post',
	TIKTOK: 'tiktok post',
	BLOG_POST: 'blog post',
	VIDEO_SCRIPT: 'video script',
	LISTING_DESCRIPTION: 'listing description',
	EMAIL: 'email blast',
	AD_COPY: 'ad copy',
};

export const platformsPrompts = {
	TWITTER: {
		platformPrompt: 'Twitter Post',
		model: 'davinci3',
		temperature: 0.5,
		max_tokens: 500,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
	INSTAGRAM: {
		platformPrompt: 'Instagram Post',
		model: 'davinci3',
		temperature: 0.5,
		max_tokens: 500,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
	FACEBOOK: {
		platformPrompt: 'Facebook Post',
		model: 'davinci3',
		temperature: 0.5,
		max_tokens: 500,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},

	TIKTOK: {
		platformPrompt: 'TikTok Post',
		model: 'davinci3',
		temperature: 0.5,
		max_tokens: 500,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},

	EMAIL: {
		platformPrompt: 'Email Blast',
		model: 'davinci3',
		temperature: 0.5,
		max_tokens: 1000,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
	VIDEO_SCRIPT: {
		platformPrompt: 'Video Script',
		model: 'davinci3',
		temperature: 0.7,
		max_tokens: 2048,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
	AD_COPY: (res) => ({
		platformName: 'Ad Copy',
		platformPrompt: 'Ad Copy',
		responseTitle: `Ad Copy for ${res.data.value[0].UnparsedAddress}`,
		model: 'davinci3',
		temperature: 0.7,
		max_tokens: 2048,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	}),
	BLOG_POST: {
		platformPrompt: 'Blog Post',
		model: 'davinci3',
		temperature: 0.7,
		max_tokens: 2048,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
	LISTING_DESCRIPTION: {
		platformPrompt: 'Listing Description',
		model: 'davinci3',
		temperature: 0.7,
		max_tokens: 2048,
		top_p: 1,
		frequency_penalty: 0,
		presence_penalty: 0,
	},
};
