import { filterModalSx } from './filterModal.sx';
import { Box, Button, Fade, IconButton, Input, Modal, Popper, Typography } from '@mui/material';
import { CloseOutlined, Error } from '@mui/icons-material';
import PropertyChooseGroup from './PropertyChooseGroup';
import BedsAndBathsChooseGroup from './BedsAndBathsChooseGroup';
import SquareChoosingBlock from './SquareChoosingBlock';
import { useFormik } from 'formik';
import { listingFilterValidation } from '../../../utils/validations/listingFilterValidation';
import { memo, useCallback, useRef } from 'react';
import { getFilterOptionsBody } from '../../../utils/listing/getPropertyTypesForFilter';
import { useAppProvider } from '../../../pages/Layout/LayoutProvider';
import { handleChangeNumericInput } from '../../../utils/common/handleChangeNumericInput';

//@@viewOn:private
const defaultPopperOptions = {
	transition: true,
	disablePortal: true,
	modifiers: [
		{
			name: 'flip',
			enabled: false,
		},
		{
			name: 'offset',
			options: {
				offset: [0, -20],
			},
		},
	],
	sx: filterModalSx.popperErrorBlock,
};
//@@viewOff:private

const FilterModal = ({ setFilterModalOpen, isOpen }) => {
	//@@viewOn:statics
	const initialFormValues = {
		propertyTypes: [],
		bedsCount: 'any',
		bathroomsCount: 'any',
		minPrice: 0,
		maxPrice: 0,
		minSquare: 0,
		maxSquare: 0,
	};
	//@@viewOff:statics

	//@@viewOn:hooks
	const bedAndBathroomsAnchor = useRef(null);
	const priceAnchor = useRef(null);
	const squareAnchor = useRef(null);
	const { setFilterOptions, isMobile } = useAppProvider();
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleSubmitFilters = useCallback(
		(values) => {
			const filterBody = getFilterOptionsBody(values);

			setFilterOptions(filterBody);
			setFilterModalOpen(false);
		},
		[setFilterModalOpen, setFilterOptions],
	);

	const formik = useFormik({
		initialValues: initialFormValues,
		validationSchema: listingFilterValidation,
		onSubmit: handleSubmitFilters,
	});

	const { handleSubmit, setFieldValue, values, errors, resetForm } = formik;
	//@@viewOff:handlers

	return (
		<Modal
			keepMounted
			open={isOpen}
			onClose={() => setFilterModalOpen(false)}
		>
			<Box
				sx={filterModalSx.modal}
				data-testid="filter-modal"
			>
				<Box sx={filterModalSx.modalHeader}>
					<Typography sx={filterModalSx.headerTitle}>Filters</Typography>
					<IconButton onClick={() => setFilterModalOpen(false)}>
						<CloseOutlined />
					</IconButton>
				</Box>
				<form
					onSubmit={handleSubmit}
					onReset={resetForm}
					style={filterModalSx.modalContent}
				>
					<Box
						sx={filterModalSx.filterBlock}
						ref={bedAndBathroomsAnchor}
					>
						<Typography
							variant="h4"
							sx={filterModalSx.headerTitle}
						>
							Beds & bathrooms
						</Typography>
						<Box sx={{ marginTop: '13px' }}>
							<Typography
								sx={filterModalSx.bedsAndBathsLabel}
								variant="body1"
							>
								Beds
							</Typography>
							<BedsAndBathsChooseGroup
								isMobile={isMobile}
								name={'bedsCount'}
								valueCount={values.bedsCount}
								setFieldValue={setFieldValue}
								handleChangeNumericInput={(e) => handleChangeNumericInput(e, 'bedsCount', setFieldValue)}
							/>
						</Box>
						<Box sx={{ marginTop: '13px' }}>
							<Typography
								sx={filterModalSx.bedsAndBathsLabel}
								variant="body1"
							>
								Bathrooms
							</Typography>
							<BedsAndBathsChooseGroup
								isMobile={isMobile}
								name={'bathroomsCount'}
								valueCount={values.bathroomsCount}
								setFieldValue={setFieldValue}
								handleChangeNumericInput={(e) => handleChangeNumericInput(e, 'bathroomsCount', setFieldValue)}
							/>
						</Box>
					</Box>
					<Box
						ref={priceAnchor}
						variant="h4"
						sx={filterModalSx.filterBlock}
					>
						<Typography sx={filterModalSx.headerTitle}>Price range</Typography>
						<Box
							sx={{
								marginTop: '13px',
								height: '78px',
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '8px',
							}}
						>
							<Box sx={filterModalSx.minAndMaxWrapper(errors.minPrice)}>
								<Typography sx={filterModalSx.minAndMaxLabel}>Minimum</Typography>
								<Input
									placeholder="Minimum price"
									disableUnderline={true}
									sx={filterModalSx.minAndMaxInput}
									name="minPrice"
									value={`${values.minPrice}`}
									onChange={(e) => handleChangeNumericInput(e, 'minPrice', setFieldValue)}
								/>
							</Box>
							<Box
								sx={{
									display: { xs: 'none', sm: 'block' },
									width: '22px',
									height: '1px',
									background: '#DDD',
								}}
							/>
							<Box sx={filterModalSx.minAndMaxWrapper(errors.maxPrice)}>
								<Typography sx={filterModalSx.minAndMaxLabel}>Maximum</Typography>
								<Input
									placeholder="Maximum price"
									name={'maxPrice'}
									disableUnderline={true}
									sx={filterModalSx.minAndMaxInput}
									value={`${values.maxPrice}`}
									onChange={(e) => handleChangeNumericInput(e, 'maxPrice', setFieldValue)}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						variant="h4"
						sx={filterModalSx.filterBlock}
					>
						<Typography sx={filterModalSx.headerTitle}>Property type</Typography>
						<Box
							sx={{
								marginTop: '13px',
								width: '100%',
							}}
						>
							<PropertyChooseGroup
								name={'propertyTypes'}
								chosenPropertyTypes={values.propertyTypes}
								setFieldValue={setFieldValue}
							/>
						</Box>
					</Box>
					<Box
						variant="h4"
						sx={filterModalSx.filterBlock}
						ref={squareAnchor}
					>
						<Typography sx={filterModalSx.headerTitle}>Square footage</Typography>
						<Box sx={filterModalSx.squareBlock}>
							<SquareChoosingBlock
								squareLabel="Minimum"
								squareValue={values.minSquare}
								name={'minSquare'}
								setFieldValue={setFieldValue}
							/>
							<SquareChoosingBlock
								squareLabel="Maximum"
								squareValue={values.maxSquare}
								name={'maxSquare'}
								setFieldValue={setFieldValue}
							/>
						</Box>
					</Box>
					<Box sx={filterModalSx.modalFooter}>
						<Button
							type="reset"
							sx={filterModalSx.clearModalButton}
						>
							Clear
						</Button>
						<Button
							type="submit"
							disabled={Object.values(errors).some((error) => error)}
							sx={filterModalSx.searchModalButton}
						>
							Search listings
						</Button>
					</Box>
				</form>
				{(errors.bedsCount || errors.bathroomsCount) && (
					<Popper
						open={!!(errors.bedsCount || errors.bathroomsCount)}
						anchorEl={bedAndBathroomsAnchor?.current}
						{...defaultPopperOptions}
					>
						{({ TransitionProps }) => (
							<Fade
								{...TransitionProps}
								timeout={350}
							>
								<Box sx={filterModalSx.popperContainer}>
									<Box sx={filterModalSx.popperLabel}>
										<Error sx={filterModalSx.popperErrorIcon} />
										<div>{errors.bedsCount ? 'Beds count' : 'Bathrooms count'}</div>
									</Box>
									<Typography sx={{ color: (theme) => theme.palette.text.senary, paddingLeft: '24px' }}>
										{errors.bedsCount || errors.bathroomsCount}
									</Typography>
								</Box>
							</Fade>
						)}
					</Popper>
				)}
				{(errors.minPrice || errors.maxPrice) && (
					<Popper
						open={!!(errors.minPrice || errors.maxPrice)}
						anchorEl={priceAnchor?.current}
						{...defaultPopperOptions}
					>
						{({ TransitionProps }) => (
							<Fade
								{...TransitionProps}
								timeout={350}
							>
								<Box sx={filterModalSx.popperContainer}>
									<Box sx={filterModalSx.popperLabel}>
										<Error sx={filterModalSx.popperErrorIcon} />
										<div>{errors.minPrice ? 'Minimum price' : 'Maximum price'}</div>
									</Box>
									<Typography sx={{ color: (theme) => theme.palette.text.senary, paddingLeft: '24px' }}>
										{errors.minPrice || errors.maxPrice}
									</Typography>
								</Box>
							</Fade>
						)}
					</Popper>
				)}
				{(errors.minSquare || errors.maxSquare) && (
					<Popper
						open={!!(errors.minSquare || errors.maxSquare)}
						anchorEl={squareAnchor?.current}
						{...defaultPopperOptions}
					>
						{({ TransitionProps }) => (
							<Fade
								{...TransitionProps}
								timeout={350}
							>
								<Box sx={filterModalSx.popperContainer}>
									<Box sx={filterModalSx.popperLabel}>
										<Error sx={filterModalSx.popperErrorIcon} />
										<div>{errors.minSquare ? 'Minimum square footage' : 'Maximum square footage'}</div>
									</Box>
									<Typography sx={{ color: (theme) => theme.palette.text.senary, paddingLeft: '24px' }}>
										{errors.minSquare || errors.maxSquare}
									</Typography>
								</Box>
							</Fade>
						)}
					</Popper>
				)}
			</Box>
		</Modal>
	);
};

export default memo(FilterModal);
