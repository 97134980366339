import { Box, Skeleton } from '@mui/material';
import { sx } from '../MainPage/RoomMainListItem/roomMainListItem.sx';
import useScreen from '../../hooks/useScreenSize/useScreenSize';
import { useMemo, memo } from 'react';

const MainPageListingsSkeleton = () => {
	//@@viewOn:hooks
	const screenSize = useScreen();
	const isBigScreen = useMemo(() => screenSize === 'xl' || screenSize === 'l', [screenSize]);
	const listingsPerPage = useMemo(() => (isBigScreen ? 20 : 10), [isBigScreen]);
	//@@viewOff:hooks

	//@@viewOn:statics
	const itemsArray = new Array(listingsPerPage).fill(0);
	//@@viewOff:statics

	return (
		<Box
			sx={{
				padding: { xs: '0 10px', md: '0 20px', lg: '0 90px' },
				width: '100%',
				maxWidth: '100%',
				overflow: 'hidden',
				minHeight: '100vh',
				margin: '33px auto 30px auto',
			}}
		>
			<Box sx={{ height: '24px', margin: '26px 0px 25px 20px', width: '100%', maxWidth: '400px' }}>
				<Skeleton
					animation="wave"
					sx={{
						width: { xs: 'calc(100% - 30px)', md: 'calc(100% - 40px)' },
						height: 40,
					}}
				/>
			</Box>

			<Box
				sx={{
					listStyle: 'none',
					margin: '0 auto',
					padding: 0,
					display: 'grid',
					gridTemplateColumns: 'repeat(auto-fit, 291px)',
					justifyContent: 'center',
					alignItems: 'flex-start',
					gap: '34px 20px',
					width: '100%',
				}}
			>
				{itemsArray.map((_, index) => {
					return (
						<Box
							sx={sx.room}
							key={index}
						>
							<Box sx={sx.carouselWrapper}>
								<Skeleton
									animation="wave"
									variant="rectangular"
									width="100%"
									height={308}
									sx={{ borderRadius: '12px' }}
								/>
							</Box>
							<Box sx={sx.roomInfo}>
								<Skeleton
									animation="wave"
									variant="text"
									width="100%"
									height={34}
									sx={sx.roomName}
								/>
								<Skeleton
									animation="wave"
									variant="text"
									width="60%"
									height={24}
									sx={sx.roomPrice}
								/>
							</Box>
							<Skeleton
								sx={{ borderRadius: '90px' }}
								animation="wave"
								width={291}
								height={40}
								variant="rounded"
							>
								<Skeleton
									animation="wave"
									variant="text"
									width="100%"
									height={40}
								/>
							</Skeleton>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default memo(MainPageListingsSkeleton);
