import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SocialMediaTypes } from '../../utils/creationContent/socialMediaTypes';
import UseQueryActions from '../../hooks/useQueryActions/useQueryActions';
import { initialPrompts } from '../../utils/creationContent/platformPrompts';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CreationAPI from '../../services/creationAPI';
import { db } from '../../firebase/config';
import { off, onValue, ref } from 'firebase/database';
import { useAppProvider } from '../Layout/LayoutProvider';
import { toast } from 'react-toastify';
import { useDocumentTitle } from 'usehooks-ts';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase/config';

const CreateContentPageContext = createContext(null);

const CreateContentPageProvider = ({ children }) => {
	//@@viewOn:statics
	const { UID } = useAuthorization();

	//@@viewOff:statics

	//@@viewOn:hooks
	const { isMobile, isAddingModalOpen, setIsAddingModalOpen } = useAppProvider();

	const { id } = useParams();
	const { createSocialPost } = UseQueryActions();

	const { data, isLoading, isError } = createSocialPost;

	const [initialPrompt, setInitialPrompt] = useState(initialPrompts.FACEBOOK);
	const [socialMediaType, setSocialMediaType] = useState(SocialMediaTypes.FACEBOOK);
	const [promptAdjective, setPromptAdjective] = useState('');
	const [showContentChoice, setShowContentChoice] = useState(true);
	const [showListings, setShowListings] = useState(true);

	const [chosenListings, setChosenListings] = useState([]);
	const [generatedResponses, setGeneratedResponses] = useState([]);
	const [chosenItem, setChosenItem] = useState(id);
	const [chosenGeneratedResponse, setChosenGeneratedResponse] = useState(
		generatedResponses ? generatedResponses[0] : '',
	);
	const [isLoadingListings, setIsLoadingListings] = useState(true);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleResponseUpdate = useCallback(
		async (prompt, promptAdjective, id, regenerate = false) => {
			const generatedData = await createSocialPost.mutateAsync({
				prompt: regenerate ? prompt : initialPrompt,
				promptAdjective,
				listingId: chosenItem,
				postType: socialMediaType,
			});

			if (generatedData) {
				const chosenListings = await CreationAPI.getChosenListings(UID);
				const index = chosenListings.findIndex((listing) => listing.Id === chosenItem);

				if (index !== -1) {
					let updatedResponses;
					if (regenerate) {
						const updatedResponseIndex = chosenListings[index].generatedResponses.findIndex(
							(response) => response.id === id,
						);
						if (updatedResponseIndex !== -1) {
							updatedResponses = chosenListings[index].generatedResponses.map((response) => {
								if (response.id === id) {
									return {
										...response,
										text: generatedData,
									};
								}
								return response;
							});
						}
					} else {
						updatedResponses = [
							...(chosenListings[index].generatedResponses || []),
							{
								id: uuidv4(),
								prompt: initialPrompt,
								promptAdjective,
								text: generatedData,
							},
						];

						if (updatedResponses.length > 6) {
							updatedResponses = updatedResponses.slice(updatedResponses.length - 6);
						}
					}
					const updatedListing = {
						...chosenListings[index],
						generatedResponses: updatedResponses,
					};

					const resultedChosenListings = chosenListings.map((listing) => {
						if (listing.Id === chosenItem) {
							return updatedListing;
						}
						return listing;
					});

					await CreationAPI.updateChosenListingsList(UID, resultedChosenListings);
				}
			} else {
				toast.error('Error generating content, try again later');
			}

			setInitialPrompt(initialPrompts[socialMediaType]);
		},
		[createSocialPost.mutateAsync, initialPrompt, chosenItem],
	);

	const handleCreatePost = useCallback(
		(promptAdjective) => {
			logEvent(analytics, 'create_content_post_click', {
				social_media_type: socialMediaType,
				prompt_adjective: promptAdjective,
				listing_id: chosenItem,
			});
			handleResponseUpdate(initialPrompt, promptAdjective, id, false);
		},
		[id, initialPrompt, handleResponseUpdate],
	);

	const handleRegenerateResponse = useCallback(
		(prompt, promptAdjective, id) => {
			logEvent(analytics, 'regenerate_content_post_click', {
				social_media_type: socialMediaType,
				prompt_adjective: promptAdjective,
				listing_id: chosenItem,
				generated_response_id: id,
			});

			handleResponseUpdate(prompt, promptAdjective, id, true);
		},
		[handleResponseUpdate],
	);
	//@@viewOff:handlers

	//@@viewOn:effects
	useDocumentTitle(`Create content | S.GPT`);

	useEffect(() => {
		// Loading Initial Prompt Based on Social Media Type
		setInitialPrompt(initialPrompts[socialMediaType]);
	}, [socialMediaType]);

	useEffect(() => {
		// Loading Selected Listing Item
		if (id) {
			setChosenItem(id);
		}
	}, [id]);

	useEffect(() => {
		// Loading User's Chosen Listing and Updating
		const chosenListingsRef = ref(db, `chosenListings/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const chosenListingsList = !data ? [] : data.length ? data : Object.values(data);

			const listing = chosenListingsList?.find((listing) => listing.Id === chosenItem);
			const reversedListingsList = listing?.generatedResponses?.reverse() || [];

			setChosenItem(listing?.Id);
			setGeneratedResponses(reversedListingsList || []);

			setChosenGeneratedResponse(listing?.generatedResponses ? listing?.generatedResponses[0] : null);
		};

		onValue(chosenListingsRef, onDataChange);

		return () => {
			off(chosenListingsRef, onDataChange);
		};
	}, [UID, data, chosenItem]);

	useEffect(() => {
		// Loading User's Chosen Listings
		setIsLoadingListings(true);
		const chosenListingsRef = ref(db, `chosenListings/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const chosenListingsList = !data ? [] : data.length ? data : Object.values(data);

			setChosenListings(chosenListingsList);
			setIsLoadingListings(false);
		};

		onValue(chosenListingsRef, onDataChange);

		return () => {
			off(chosenListingsRef, onDataChange);
		};
	}, [UID]);

	useEffect(() => {
		// Show listings on desktop
		if (!isMobile) {
			setShowListings(true);
		}
	}, [isMobile]);
	//@@viewOff:effects

	//@@viewOn:context
	const contextValue = useMemo(() => {
		return {
			socialMediaType,
			setSocialMediaType,
			showContentChoice,
			setShowContentChoice,
			showListings,
			setShowListings,
			promptAdjective,
			setPromptAdjective,
			initialPrompt,
			setInitialPrompt,
			generatedResponses,
			prevListings: chosenListings,
			chosenItem,
			setChosenItem,
			isLoading,
			isError,
			chosenGeneratedResponse,
			setChosenGeneratedResponse,
			isAddingModalOpen,
			setIsAddingModalOpen,
			isLoadingListings,
			handleCreatePost,
			handleRegenerateResponse,
			isMobile,
		};
	}, [
		socialMediaType,
		setSocialMediaType,
		showContentChoice,
		setShowContentChoice,
		showListings,
		setShowListings,
		promptAdjective,
		setPromptAdjective,
		initialPrompt,
		setInitialPrompt,
		generatedResponses,
		chosenListings,
		chosenItem,
		setChosenItem,
		isLoading,
		isError,
		chosenGeneratedResponse,
		setChosenGeneratedResponse,
		isAddingModalOpen,
		setIsAddingModalOpen,
		isLoadingListings,
		handleCreatePost,
		handleRegenerateResponse,
		isMobile,
	]);
	//@@viewOff:effects

	return <CreateContentPageContext.Provider value={contextValue}>{children}</CreateContentPageContext.Provider>;
};
const useCreateContentPage = () => {
	const context = useContext(CreateContentPageContext);
	if (!context) {
		throw new Error('useCreateContentPage must be used within a CreateContentPageProvider');
	}
	return context;
};

export default memo(CreateContentPageProvider);
export { useCreateContentPage };
