//@@viewOn:imports
import './styles/App.css';
import './styles/fonts.css';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayoutProvider from './pages/Layout/LayoutProvider';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from './styles/theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazy, Suspense } from 'react';
import Loader from './components/UI/Loader/Loader';
import MaintenancePage from './pages/MaintenancePage/MaintenancePage';
import ProfileListings from './components/ProfilePage/ProfileListings/ProfileListings';
import ProfileGeneratedImages from './components/ProfilePage/ProfileGeneratedImages/ProfileGeneratedImages';
import ProfileChats from './components/ProfilePage/ProfileChats/ProfileChats';
import ProfileSavedFilters from './components/ProfilePage/ProfileSavedFilters/ProfileSavedFilters';
import ProfileListingOutletProvider from './components/ProfilePage/ProfileListingItem/ProfileListingOutletProvider';
import MainPage from './pages/MainPage/MainPage';
import CreateContentPage from './pages/CreateContentPage/CreateContentPage';
import EditingPhotosPage from './pages/EditingPhotosPage/EditingPhotosPage';
import ChatPage from './pages/ChatPage/ChatPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Layout from './pages/Layout/Layout';
import ListingItemPageRouteProvider from './pages/ListingItemPage/ListingItemPageRouteProvider';

const MainPageProviderLazy = lazy(() => import('./pages/MainPage/MainPageProvider'));
const CreateContentPageProviderLazy = lazy(() => import('./pages/CreateContentPage/CreateContentPageProvider'));
const ChatPageProviderLazy = lazy(() => import('./pages/ChatPage/ChatPageProvider'));
const LoginPageLazy = lazy(() => import('./pages/LoginPage/LoginPage'));
const EditingPhotosPageProviderLazy = lazy(() => import('./pages/EditingPhotosPage/EditingPhotosPageProvider'));
const ProfilePageProviderLazy = lazy(() => import('./pages/ProfilePage/ProfilePageProvider'));
const ListingItemPageProviderLazy = lazy(() => import('./pages/ListingItemPage/ListingItemPageProvider'));
//@@viewOff:imports

//@@viewOn:private
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchInterval: false,
			refetchOnReconnect: true,
			retry: 3,
			keepPreviousData: true,
			staleTime: 10 * 1000,
			cacheTime: 3 * 60 * 1000,
		},
	},
});

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			path="/"
			hasErrorBoundary={true}
			errorElement={<MaintenancePage />}
			defer
			element={
				<Suspense
					fallback={
						<Loader
							sx={{ height: 'calc(100vh)' }}
							disableShrink={true}
						/>
					}
				>
					<LayoutProvider>
						<Layout />
					</LayoutProvider>
				</Suspense>
			}
		>
			<Route
				index
				element={
					<MainPageProviderLazy>
						<MainPage />
					</MainPageProviderLazy>
				}
			/>
			<Route
				path="create/:id"
				element={
					<CreateContentPageProviderLazy>
						<CreateContentPage />
					</CreateContentPageProviderLazy>
				}
			/>
			<Route
				path="login"
				element={<LoginPageLazy />}
			/>
			<Route
				path="editing"
				element={
					<MaintenancePage
						tabTitle={'Under Construction | S.GPT'}
						description={
							'Editing images Page is currently under construction. The ADX team is currently working on improvements to the SerhantGPT experience. Check back soon.'
						}
					/>
				}
			/>
			<Route
				path="listing/:id"
				element={
					<ListingItemPageProviderLazy>
						<ListingItemPageRouteProvider />
					</ListingItemPageProviderLazy>
				}
			/>
			<Route
				path="chat/:id"
				element={
					<ChatPageProviderLazy>
						<ChatPage />
					</ChatPageProviderLazy>
				}
			/>
			<Route
				path="chat/"
				element={
					<ChatPageProviderLazy>
						<ChatPage />
					</ChatPageProviderLazy>
				}
			/>
			w
			<Route
				path="profile/"
				element={
					<ProfilePageProviderLazy>
						<ProfilePage />
					</ProfilePageProviderLazy>
				}
			>
				<Route
					index
					element={<ProfileListings />}
				/>
				<Route
					path="images/"
					element={<ProfileGeneratedImages />}
				/>
				<Route
					path="chats/"
					element={<ProfileChats />}
				/>
				<Route
					path="listing/:id"
					element={<ProfileListingOutletProvider />}
				/>
				{/*<Route*/}
				{/*	path="filters/"*/}
				{/*	element={<ProfileSavedFilters />}*/}
				{/*/>*/}
			</Route>
			<Route
				path="*"
				element={<NotFoundPage />}
			/>
		</Route>,
	),
);
//@@viewOff:private

const App = () => (
	<div id="app">
		<ThemeProvider theme={theme}>
			<QueryClientProvider client={queryClient}>
				<RouterProvider router={router} />
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</ThemeProvider>
	</div>
);

export default App;
