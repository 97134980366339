const sx = {
	editingContent: {
		position: 'relative',
		padding: '100px 14px 20px 14px',
		width: '100%',
		minHeight: { xs: '533px', md: 'max-content' },
		height: { xs: '533px', md: '100%' },
		display: 'flex',
		flexDirection: 'column',
		alignItems: { xs: 'flex-start', md: 'center' },
		justifyContent: 'center',
		gap: '20px',
		background: (theme) => theme.palette.bg.main,
	},
	previewImage: {
		margin: '0 auto',
		alignSelf: 'center',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: { xs: '95%', sm: '90%', md: '95%', lg: '85%' },
		height: { xs: '100%', md: '90%' },
		overflowX: 'hidden',
		img: {
			maxWidth: '100%',
			height: '100%',
			objectFit: { xs: 'cover', md: 'contain' },
			objectPosition: 'center',
			objectRepeat: 'no-repeat',
		},
	},
	initialImagePreview: {
		position: 'absolute',
		top: '15px',
		right: '15px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: '93px',
		width: '93px',
		height: '93px',
		borderRadius: '8px',
		border: (theme) => `3px solid ${theme.palette.bg.main}`,
	},
	generatedItemsList: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		maxHeight: '105px',
		maxWidth: '100%',
		width: '100%',
		'@media (max-width: 900px) and (min-width: 768px)': {
			flexWrap: 'wrap',
			height: 'fit-content',
			gap: '5px',
		},
	},
	generatedItem: {
		position: 'relative',
		top: { xs: '2px', md: '0' },
		margin: '2px',
		cursor: 'pointer',
		width: '100px',
		minWidth: '100px',
		maxWidth: '100px',
		height: '100px',
		borderRadius: '8px',
		border: '4px solid transparent',
	},
	generatedItemImage: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		objectPosition: 'center',
		borderRadius: '8px',
		cursor: 'pointer',
	},
	creatingInfo: {
		position: 'absolute',
		top: '16px',
		left: '33px',
		height: '22px',
	},
	creatingInfoIcon: {
		color: (theme) => theme.palette.text.darkBackground,
		width: '22px',
		height: '22px',
		position: 'relative',
		zIndex: '5',
	},
	arrowWrapper: {
		width: '0',
		height: '100%',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
	},
	iconArrowButton: {
		background: 'rgba(255, 255, 255, 0.1)',
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: { xs: '3px', md: '5px' },
	},
	arrow: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '36px',
		height: '36px',
		padding: '0px',
		color: (theme) => theme.palette.bg.main,
	},
};

sx.generatedItemActive = {
	...sx.generatedItem,
	border: '2px solid transparent',
	boxShadow: (theme) => `0px 0px 0px 2px ${theme.palette.bg.brand}`,
};

sx.arrowButtonLeft = {
	...sx.iconArrowButton,
	left: '0',
	transform: { xs: 'translateX(0px)', md: 'translateX(10px)' },
};

sx.arrowButtonRight = {
	...sx.iconArrowButton,
	right: '0',
	transform: { xs: 'translateX(0px)', md: 'translateX(-10px)' },
};

export { sx };
