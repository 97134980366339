export const sx = {
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '14px',
		width: '100%',
		maxWidth: { xs: '600px', lg: '800px' },
	},
	userMessageContainer: {
		paddingRight: '42px',
	},
	messageItem: {
		width: 'fit-content',
		borderRadius: '8px',
		display: 'flex',
		gap: '14px',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: '8px 17px 12px 12px',
		border: (theme) => `1px solid ${theme.palette.bg.brand}`,
		background: 'rgba(19, 17, 118, 0.85)',
	},
	userMessageItem: {
		border: (theme) => `1px solid ${theme.palette.stroke.main}`,
		background: '#EFF0F8',
	},
	avatar: {
		width: '31px',
		height: '31px',
	},
	avatarForChatGPT: {
		background: (theme) => theme.palette.bg.brand,
		boxShadow: '0px 4px 10px 0px rgba(19, 17, 116, 0.40)',
		img: {
			width: '18px',
			height: '14px',
			position: 'relative',
		},
	},
	messageText: {
		color: '#fff',
		fontSize: '12px',
		lineHeight: '20px',
		margin: 0,
		textWrap: 'wrap',
		maxWidth: '100%',
	},
	copyIcon: {
		width: '28px',
		height: '28px',
		padding: '6px',
		borderRadius: '4px',
		border: (theme) => `1px solid ${theme.palette.stroke.main}`,
		background: (theme) => theme.palette.bg.light,
	},
};
