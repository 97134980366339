import { Box, IconButton } from '@mui/material';
import { sx } from './chatPage.sx';
import { Menu, Add } from '@mui/icons-material';
import MessageItem from '../../components/ChatPage/MessageItem/MessageItem';
import EmptyChat from '../../components/ChatPage/EmptyChat/EmptyChat';
import ChatThreads from '../../components/ChatPage/ChatThreads/ChatThreads';
import ChatControls from '../../components/ChatPage/ChatControls/ChatControls';
import ChatThreadsSkeleton from '../../components/Skeletons/SkeletonChatThreads';
import ChatMessagesSkeleton from '../../components/Skeletons/ChatMessagesSkeleton';
import { useChatPage } from './ChatPageProvider';
import { memo } from 'react';

const ChatPage = () => {
	//@@viewOn:hooks
	const {
		handleToggleChatThreads,
		handleCreateNewChatThread,
		chatThreadsIsLoading,
		profilePhoto,
		showChatThreads,
		activeChatThread = null,
		isLoadingChatThread,
		chatMessagesRef,
		isMobile,
	} = useChatPage();
	//@@viewOff:hooks

	//@@viewOn:statics
	const showChatThreadsForAll = isMobile ? showChatThreads : true;
	//@@viewOff:statics

	return (
		<>
			{isMobile && (
				<Box sx={sx.chatMobileThreadsControls}>
					<IconButton
						sx={{}}
						onClick={() => handleToggleChatThreads(true)}
					>
						<Menu sx={{}} />
					</IconButton>
					<IconButton onClick={() => handleCreateNewChatThread()}>
						<Add sx={{}} />
					</IconButton>
				</Box>
			)}
			<Box sx={sx.container}>
				<Box sx={sx.content}>
					{showChatThreadsForAll && <>{chatThreadsIsLoading ? <ChatThreadsSkeleton /> : <ChatThreads />}</>}
					{isLoadingChatThread ? (
						<ChatMessagesSkeleton />
					) : (
						<Box sx={sx.chat}>
							{activeChatThread ? (
								<Box
									sx={sx.chatMessages}
									ref={chatMessagesRef}
								>
									{(activeChatThread ? activeChatThread?.chatString : []).map((message) => (
										<MessageItem
											profilePhoto={profilePhoto}
											key={message.timeStamp + message.content}
											message={message}
										/>
									))}
								</Box>
							) : (
								<EmptyChat />
							)}
							<ChatControls />
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};

export default memo(ChatPage);
