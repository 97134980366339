import axios from 'axios';

const backendUrl = process.env.REACT_APP_API_BACKEND_URL;

export const axiosInstance = axios.create({
	baseURL: backendUrl,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: false,
});
