export const sx = {
	addModalRoom: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minWidth: '220px',
	},
	addModalCreateButton: {
		width: '100%',
		height: '40px',
		marginTop: '6px',
		color: (theme) => theme.palette.text.quaternary,
		border: (theme) => `1px solid ${theme.palette.stroke.light}`,
		boxShadow: (theme) => `0px 4px 4px ${theme.palette.stroke.custom7}`,
		borderRadius: '90px',
		fontWeight: '600',
	},
	roomForProfilePage: {
		display: 'flex',
		flexDirection: 'column',
		width: { xs: '100%', sm: '208px' },
		maxWidth: '391px',
		margin: '0 auto',
	},
	room: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		maxWidth: '291px',
	},
	carouselWrapper: {
		width: '100%',
		height: '308px',
		borderRadius: '12px',
		position: 'relative',
		cursor: 'pointer',
	},
	carouselWrapperInProfilePage: {
		width: '100%',
		height: '220px',
		borderRadius: '12px',
		position: 'relative',
		cursor: 'pointer',
	},
	actionButtons: {
		position: 'absolute',
		top: '12px',
		right: '11px',
		zIndex: 11,
	},
	actionButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		background: 'rgba(0, 0, 0, 0.10)',
	},
	actionButtonIcon: {
		width: '17px',
		height: '17px',
		color: (theme) => theme.palette.bg.main,
	},
	carousel: {
		indicatorIconButtonProps: {
			sx: {
				width: '10px',
				height: '10px',
				padding: '0',
				color: (theme) => theme.palette.bg.main,
				opacity: '0.3',
				margin: '0 3px',
				svg: {
					width: '10px',
					height: '10px',
				},
			},
		},
		activeIndicatorIconButtonProps: {
			style: {
				opacity: '1',
			},
		},
		navButtonsProps: {
			style: {
				background: 'transparent',
				padding: '0',
				width: '14px',
				height: '14px',
			},
		},
		indicatorContainerProps: {
			style: {
				position: 'absolute',
				bottom: '5px',
				textAlign: 'center',
				zIndex: '10',
			},
		},
	},
	roomInfo: {
		marginTop: '9px',
		display: 'flex',
		flexDirection: 'column',
		gap: '14px',
		minHeight: '110px',
	},
	roomName: {
		margin: 0,
		height: 'fit-content',
		maxHeight: '72px',
		color: (theme) => theme.palette.text.secondary,
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '24px',
		letterSpacing: '0.4px',
		textTransform: 'uppercase',
		overflow: 'hidden',
	},
	roomPrice: {
		margin: 0,
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '24px',
		letterSpacing: '0.4px',
		color: (theme) => theme.palette.text.tertiary,
		textTransform: 'uppercase',
	},
	roomButton: {
		width: '291px',
		height: '40px',
		marginTop: '6px',
		color: (theme) => theme.palette.text.quaternary,
		border: (theme) => `1px solid ${theme.palette.stroke.light}`,
		boxShadow: (theme) => `0px 4px 4px ${theme.palette.stroke.custom7}`,
		borderRadius: '90px',
		fontWeight: '600',
	},
};
