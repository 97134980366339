export const sx = {
	container: {
		position: 'relative',
		top: { xs: '0', md: '70px' },
		padding: { xs: '20px 0 20px 11px', md: '20px 0 20px 27px' },
		maxHeight: { xs: 'calc(100vh - 70px - 55px)', md: 'calc(100vh - 70px)' },
		height: 'calc(100vh - 70px)',
		overflowY: 'hidden',
		background: 'linear-gradient(220deg, (theme) => theme.palette.bg.main 0%, #FAFBFF 60.13%, #E3E7FD 100%)',
	},
	content: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		gap: '20px',
		width: '100%',
		height: '100%',
	},
	chatMobileThreadsControls: {
		marginTop: '70px',
		display: 'flex',
		width: '100%',
		height: '55px',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid #D6D8E5',
	},
	chat: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	chatMessages: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: { xs: 'center', lg: 'flex-start' },
		gap: '18px',
		padding: { xs: '0 11px 20px 0', md: '0 20px 20px 0' },
		overflowY: 'scroll',
		maxHeight: 'calc(100% - 210px - 16px)',
	},
};
