import { sx } from '../ProfileGeneratedImages/profileGeneratedImages.sx';
import { Box, IconButton, Typography, Modal } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIos, ArrowForwardIos, Favorite, FavoriteBorder, RemoveRedEyeOutlined } from '@mui/icons-material';
import ProgressiveImg from '../../UI/ProgressiveImage/ProgressiveImage';
import { memo, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';

const GeneratedImageItem = ({ generatedItem, handleToggleLikeGeneratedImage }) => {
	const [isOpenViewer, setIsOpenViewer] = useState(false);

	const handleOpenViewer = () => {
		setIsOpenViewer(true);
	};

	const handleCloseViewer = () => {
		setIsOpenViewer(false);
	};

	return (
		<>
			{isOpenViewer && (
				<Modal
					open={isOpenViewer}
					onClose={() => handleOpenViewer()}
				>
					<Box>
						<Lightbox
							onClose={handleCloseViewer}
							zoomStep={0.2}
							showTitle={false}
							doubleClickZoom={2}
							images={generatedItem.images}
						/>
					</Box>
				</Modal>
			)}
			<Box
				key={generatedItem.id}
				sx={sx.generatedItem}
			>
				<Box sx={sx.images}>
					<Box sx={sx.carouselWrapper}>
						<Carousel
							autoPlay={false}
							animation="slide"
							indicators={true}
							swipe={true}
							height={235}
							navButtonsAlwaysVisible={true}
							NextIcon={<ArrowForwardIos sx={{ width: '16px', height: '16px' }} />}
							PrevIcon={<ArrowBackIos sx={{ width: '16px', height: '16px' }} />}
							changeOnFirstRender={true}
							indicatorIconButtonProps={sx.carousel.indicatorIconButtonProps}
							activeIndicatorIconButtonProps={sx.carousel.activeIndicatorIconButtonProps}
							navButtonsProps={sx.carousel.navButtonsProps}
							indicatorContainerProps={sx.carousel.indicatorContainerProps}
						>
							{(generatedItem?.images || ['']).map((photo, index) => (
								<ProgressiveImg
									src={photo}
									alt={`Photo ${photo}`}
									key={index}
									style={{ width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover' }}
								/>
							))}
						</Carousel>
					</Box>
					<Box sx={sx.actions}>
						{generatedItem?.images && (
							<IconButton
								sx={sx.imageButton}
								onClick={handleOpenViewer}
							>
								<RemoveRedEyeOutlined sx={{ color: (theme) => theme.palette.bg.main, width: 17, height: 17 }} />
							</IconButton>
						)}
						<IconButton
							sx={sx.imageButton}
							onClick={() => handleToggleLikeGeneratedImage(!generatedItem.isLiked, generatedItem.id)}
						>
							{generatedItem.isLiked ? (
								<Favorite sx={{ color: (theme) => theme.palette.bg.main, width: 17, height: 17 }} />
							) : (
								<FavoriteBorder sx={{ color: (theme) => theme.palette.bg.main, width: 17, height: 17 }} />
							)}
						</IconButton>
					</Box>
				</Box>
				<Box sx={sx.info}>
					<Box sx={sx.detailedInfoItem}>
						<Typography sx={sx.detailedLabel}>POSITIVE PROMPT</Typography>
						<Typography sx={sx.detailedValue}>{generatedItem.positivePrompts}</Typography>
					</Box>
					<Box sx={sx.detailedInfo}>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>Engine</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.engine || '-'}</Typography>
						</Box>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>ENGINE VERSION</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.version || '-'}</Typography>
						</Box>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>ASPECT RATIO</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.aspectRatio || '-'}</Typography>
						</Box>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>CHAOS</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.chaos || '-'}</Typography>
						</Box>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>WEIRDNESS</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.weird || '-'}</Typography>
						</Box>
						<Box sx={sx.detailedInfoItem}>
							<Typography sx={sx.detailedLabel}>QUALITY</Typography>
							<Typography sx={sx.detailedValue}>{generatedItem.quality || '-'}</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default memo(GeneratedImageItem);
