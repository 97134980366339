import { createTheme } from '@mui/material';

export const theme = createTheme({
	palette: {
		info: {
			main: '#1a73e8',
		},
		bg: {
			main: '#FFFFFF',
			light: '#F6F8F9',
			lighter: '#E7E7EC',
			brand: '#131176',
			successBg: '#73AA52',
			errorBg: '#FFE9E5',
			custom1: 'rgba(232, 232, 237, 0.50)',
			custom2: '#DBDAEF',
		},
		text: {
			primary: '#000000',
			secondary: '#1F1F1F',
			tertiary: '#434343',
			quaternary: '#686D95',
			quinternary: '#43545C',
			senary: '#34364B',
			dark: '#171717',
			muted: '#787878',
			mutedSecondary: '#888BA1',
			gray: '#8D8D8D',
			slate: '#565C75',
			darkGray: '#363636',
			darkest: '#4E4E4E',
			deepBrand: '#121275',
			deepSecondary: '#24282F',
			deepTertiary: '#34373D',
			darkBackground: '#292727',
			lightText: '#A2A2A2',
		},
		stroke: {
			light: '#BCBFD6',
			lighter: '#9498B6',
			brand: '#757F92',
			custom1: '#BCC0D6',
			custom2: '#C7C7C7',
			custom3: '#C8CBDC',
			main: '#D6D8E5',
			custom4: '#D9DFEB',
			gray: '#E1E1E1',
			custom5: '#C4C4D3',
			custom6: '#A4A9C4',
			custom7: '#EAF0F3',
			custom8: '#C8CBDC',
			custom9: '#C8CFDE',
			darkest: '#707489',
			custom10: '#DBDCE8',
			custom11: '#E4E7F3',
		},
		errors: {
			main: '#FF6A57',
			darker: '#9E1200',
			darkest: '#E02810',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 480,
			md: 768,
			lg: 1000,
			xl: 1440,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				startIcon: {
					margin: 0,
				},
				root: {
					textTransform: 'none',
					textDecoration: 'none',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: '8px',
					fontSize: '14px',
					fontWeight: '500',
					lineHeight: '24px',
					letterSpacing: '0.4px',
					cursor: 'pointer',
				},
				outlined: {
					border: '1px solid #BCC0D6 !important',
					background: '#FFF !important',
					color: '#43545C !important',
				},
				contained: {
					border: 'none',
					background: '#131176 !important',
					color: ' #FFF !important',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: '14px',
					fontWeight: '500',
					lineHeight: '24px',
					letterSpacing: '0.4px',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					maxWidth: '200px',
					display: 'flex',
					padding: '8px 12px',
					alignItems: 'flex-start',
					gap: '10px',
					borderRadius: '8px',
					background: '#34364B !important',
					color: '#FFF',
					fontSize: '12px',
					fontWeight: '400',
					lineHeight: '20px',
				},
				arrow: {
					color: '#34364B !important',
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					color: 'transparent',
					height: '8px',
				},
				rail: {
					backgroundColor: '#C5C5D3',
				},
				track: {
					backgroundColor: '#4C49C2 !important',
				},
				thumb: {
					width: '5px',
					height: '18px',
					backgroundColor: '#4C49C2',
					borderRadius: '2px !important',
					border: 'none',
					boxShadow: 'none !important',
					overflow: 'hidden',
				},
			},
		},
	},
});
