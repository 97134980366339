import { Box, Input, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { memo } from 'react';

//@@viewOn:private
const toggleButtonSx = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '90px !important',
	border: '1px solid #DDD !important',
	background: (theme) => theme.palette.bg.main,
	marginRight: '9px',
	minWidth: '57px',
	maxHeight: '44px',
	padding: '10px 24px',
	textTransform: 'none',
	color: (theme) => theme.palette.text.dark,
	'&.Mui-selected, &.Mui-selected:hover': {
		color: (theme) => theme.palette.bg.main,
		backgroundColor: (theme) => theme.palette.bg.brand,
	},
};

const options = ['any', 1, 2, 3, 4];
const optionsShort = ['any', 1, 2];
const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
//@@viewOff:private

const BedsAndBathsChooseGroup = ({ valueCount, setFieldValue, name, isMobile = false, handleChangeNumericInput }) => {
	//@@viewOn:hooks
	const [lastChoosingMethod, setLastChoosingMethod] = useState('button');
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleChange = (_, newValue) => {
		if (newValue === null) {
			return;
		}
		setFieldValue(name, newValue, true);
		setLastChoosingMethod('button');
	};

	const handleFocus = () => {
		setLastChoosingMethod('input');
		setFieldValue(name, '0', false);
	};

	const handleBlur = () => {
		const value = parseInt(valueCount);
		if (!value || isNaN(value)) {
			setLastChoosingMethod('button');
			setFieldValue(name, 'any', true);
		}
	};
	//@@viewOff:handlers

	return (
		<Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '9px' }}>
			<ToggleButtonGroup
				value={valueCount}
				name={name}
				exclusive
				onChange={handleChange}
				aria-label="valueCount"
				sx={{
					maxWidth: { xs: '215px', sm: '100%', md: '100%' },
					overflowX: 'hidden',
				}}
			>
				{(isMobile ? optionsShort : options).map((option) => (
					<ToggleButton
						disableRipple={true}
						key={option}
						value={option}
						aria-label={option.toString()}
						sx={{
							...toggleButtonSx,
							...(valueCount === option && { background: '#110F68', color: (theme) => theme.palette.bg.main }),
						}}
					>
						{capitalizeFirstLetter(option.toString())}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
			<Input
				disableUnderline={true}
				onFocus={handleFocus}
				onBlur={handleBlur}
				inputProps={{ style: { textAlign: 'center' } }}
				sx={{
					...toggleButtonSx,
					...(lastChoosingMethod === 'input' && { background: '#110F68', color: (theme) => theme.palette.bg.main }),
					maxWidth: '117px',
				}}
				placeholder={'Custom'}
				value={lastChoosingMethod === 'input' ? valueCount : 'Custom'}
				onChange={(e) => handleChangeNumericInput(e, name)}
			/>
		</Box>
	);
};

export default memo(BedsAndBathsChooseGroup);
