export const sx = {
	chatThreadsWrapper: {
		position: { xs: 'fixed', md: 'static' },
		maxWidth: { xs: '100%', md: '327px', lg: '427px' },
		minHeight: { xs: 'calc(100% - 70px)', md: '100%' },
		height: { xs: 'calc(100% - 70px)', md: '100%' },
		borderRadius: { xs: '0', md: '8px' },
		border: { xs: 'none', md: '1px solid #CFD2E4' },
		maxHeight: '100%',
		width: '100%',
		top: '70px',
		left: '0',
		zIndex: '10',
		padding: '21px 20px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		gap: '10px',
		background: (theme) => theme.palette.bg.light,
		boxShadow: '0px 6px 6px 0px rgba(230, 234, 243, 0.56)',
	},
	content: {
		width: '100%',
		height: 'calc(100% - 163px)',
	},
	chatThreads: {
		width: '100%',
		height: 'calc(100% - 55px)',
		marginBottom: '20px',
		overflowY: 'auto',
	},
	chatThreadItem: {
		display: 'flex',
		height: '47px',
		padding: '6px 8px 7px 11px',
		justifyContent: 'space-between',
		minWidth: '100%',
		alignItems: 'center',
		borderRadius: '4px',
		background: (theme) => theme.palette.bg.main,
		marginBottom: '10px',
		cursor: 'pointer',
	},
	activeChatThreadItem: {
		borderRight: (theme) => `4px solid ${theme.palette.bg.brand}`,
	},
	chatThreadItemName: {
		display: 'flex',
		width: '100%',
		maxWidth: { xs: '100%', md: '370px' },
		alignItems: 'center',
		height: '34px',
		color: '#3B4251',
		fontSize: '14px',
		fontWeight: '500',
		textWrap: 'nowrap',
		overflow: 'hidden',
		paddingRight: '5px',
	},
	chatThreadsControls: {
		borderTop: '1px solid #CFD2E4',
		paddingTop: '20px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '10px',
	},
	clearThreadsButton: {
		width: '100%',
		padding: '10px 21px',
		justifyContent: 'space-between',
		color: (theme) => theme.palette.text.quinternary,
	},
	createNewChatButton: {
		width: '100%',
		padding: '10px 21px',
		justifyContent: 'space-between',
	},
	titleBlock: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '15px',
	},
	titleLabel: {
		color: '#2A416D',
		fontSize: '16px',
		fontWeight: '600',
		lineHeight: '24px',
	},
	timeGroupLabel: {
		margin: '20px 0 15px 0',
		color: '#8B8B8B',
		fontSize: '12px',
		fontWeight: '600',
	},
};
