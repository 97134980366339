import { PROFILE_NAVIGATION } from '../../../utils/profile/activeViews';
import { sx } from './profilePageHeader.sx';
import { Avatar, Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfilePageHeader = ({ user }) => {
	const navigate = useNavigate();
	const profileLocation = useLocation().pathname.split('/')[2];

	const handleChangeView = (view) => {
		navigate(`/profile/${view}`);
	};

	return (
		<Box sx={sx.header}>
			<Box sx={sx.headerProfile}>
				<Avatar
					sx={sx.profileImage}
					src={user.photoURL}
					alt={user.displayName}
					variant="circle"
				/>
				<Box sx={sx.profileInfo}>
					<Typography
						sx={sx.profileUserName}
						variant="h3"
					>
						{user.displayName}
					</Typography>
					<Typography
						sx={sx.profileUserEmail}
						variant="p"
					>
						{user.email}
					</Typography>
				</Box>
			</Box>
			<Box sx={sx.navigation}>
				<Box
					sx={{
						...sx.navigationItem,
						...(profileLocation === PROFILE_NAVIGATION.LISTINGS.value && sx.navigationItemActive),
					}}
					onClick={() => handleChangeView(PROFILE_NAVIGATION.LISTINGS.value)}
				>
					<Typography variant="p">{PROFILE_NAVIGATION.LISTINGS.label}</Typography>
				</Box>
				<Box
					sx={{
						...sx.navigationItem,
						...(profileLocation === PROFILE_NAVIGATION.GENERATED_IMAGES.value && sx.navigationItemActive),
					}}
					onClick={() => handleChangeView(PROFILE_NAVIGATION.GENERATED_IMAGES.value)}
				>
					<Typography variant="p">{PROFILE_NAVIGATION.GENERATED_IMAGES.label}</Typography>
				</Box>
				<Box
					sx={{
						...sx.navigationItem,
						...(profileLocation === PROFILE_NAVIGATION.CHATS.value && sx.navigationItemActive),
					}}
					onClick={() => handleChangeView(PROFILE_NAVIGATION.CHATS.value)}
				>
					<Typography variant="p">{PROFILE_NAVIGATION.CHATS.label}</Typography>
				</Box>
				{/*<Box*/}
				{/*	sx={{*/}
				{/*		...sx.navigationItem,*/}
				{/*		...(profileLocation === PROFILE_NAVIGATION.SAVED_FILTERS.value && sx.navigationItemActive),*/}
				{/*	}}*/}
				{/*	onClick={() => handleChangeView(PROFILE_NAVIGATION.SAVED_FILTERS.value)}*/}
				{/*>*/}
				{/*	<Typography variant="p">{PROFILE_NAVIGATION.SAVED_FILTERS.label}</Typography>*/}
				{/*</Box>*/}
			</Box>
		</Box>
	);
};

export default memo(ProfilePageHeader);
