import { REGIONS } from './regions';
import { propertyTypeMap } from './listingFilter';

/**
 * Creates a list of filter chips based on provided filter options and regions.
 *
 * @param {Object} filterOptions - The filter options used to generate the filter chips.
 * @param {string[]} regions - An array of regions to include in the filter chips.
 * @returns {Object[]} A list of filter chips, each containing field, label, and type information.
 */
export const makeFilterChipsList = (filterOptions, regions = []) => {
	if (regions.length < 1 && !filterOptions) {
		return [];
	}

	const regionsWithLabels = regions.map((region) => {
		const matchingRegion = REGIONS.find((item) => item.city === region);
		return { city: region, label: matchingRegion ? matchingRegion.label : '' };
	});

	const filterChipsList = [
		{ field: 'maxBaths', label: 'Bathrooms' },
		{ field: 'maxBeds', label: 'Beds' },
		{ field: 'minPrice', label: 'Min Price Range' },
		{ field: 'maxPrice', label: 'Max Price Range' },
		{ field: 'minSquareFeet', label: 'Min Square Feet' },
		{ field: 'maxSquareFeet', label: 'Max Square Feet' },
		{ field: 'isCondo', label: 'Condo', type: propertyTypeMap.CONDO },
		{ field: 'isCoOp', label: 'Coop', type: propertyTypeMap.COOP },
		{ field: 'isTownhouse', label: 'Townhouse', type: propertyTypeMap.TOWNHOUSE },
		{ field: 'isMultiFamily', label: 'Multi Family', type: propertyTypeMap.MULTI_FAMILY },
		{
			field: 'isSingleFamilyResidence',
			label: 'Single Family Residence',
			type: propertyTypeMap.SINGLE_FAMILY_RESIDENCE,
		},
		{ field: 'isDuplex', label: 'Duplex', type: propertyTypeMap.MULTI_LEVEL },
		{ field: 'isTriplex', label: 'Triplex', type: propertyTypeMap.MULTI_LEVEL },
		{ field: 'isMixedUse', label: 'Mixed Use', type: propertyTypeMap.MIXED_USE },
		{ field: 'isApartment', label: 'Apartment', type: propertyTypeMap.APARTMENT },
		{ field: 'isOffice', label: 'Office', type: propertyTypeMap.OFFICE },
	];

	const filteredFilterChipsList = filterChipsList.filter((item) => {
		const fieldValue = filterOptions && filterOptions[item.field];
		return !!fieldValue;
	});

	return [...filteredFilterChipsList, ...regionsWithLabels];
};
