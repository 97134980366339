import { Configuration, OpenAIApi } from 'azure-openai';

export const openaiChat = new OpenAIApi(
	new Configuration({
		apiKey: process.env.REACT_APP_API_AZURE_API_KEY,
		azure: {
			apiKey: process.env.REACT_APP_API_AZURE_API_KEY,
			endpoint: process.env.REACT_APP_API_AZURE_ENDPOINT,
			deploymentName: process.env.REACT_APP_API_AZURE_DEPLOYMENT_NAME,
		},
	}),
);
