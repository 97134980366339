import { get, ref as dbRef, set } from 'firebase/database';
import { db } from '../firebase/config';

/**
 * A utility class for interacting with the Firebase to manage profile-related data.
 */
export default class ProfileAPI {
	/**
	 * Fetches favorite listings for a specific user.
	 * @param {string}UID - The user's unique identifier.
	 * @returns {Promise<Array<object>|null>} - An array of favorite Listings ids.
	 */
	static async getFavoriteListings(UID) {
		const favoriteListingsRef = dbRef(db, `favoriteListings/${UID}/`);
		const favoriteListingsSnapshot = await get(favoriteListingsRef);
		const favoriteListingsSnapshotData = favoriteListingsSnapshot.val();

		return Object.keys(favoriteListingsSnapshotData || {});
	}

	/**
	 * @param {boolean}isLiked - Which like status should the listing have: liked or unliked.
	 * @param {string}UID - The user's unique identifier.
	 * @param {string}listingId - The listing's unique identifier.
	 * @returns {Promise<void>}
	 */
	static async toggleLikeFavoriteListing(isLiked, UID, listingId) {
		try {
			if (isLiked) {
				await set(dbRef(db, `favoriteListings/${UID}/${listingId}`), true);
			} else {
				await set(dbRef(db, `favoriteListings/${UID}/${listingId}`), null);
			}
		} catch (error) {
			return error;
		}
	}
}
