import { Box } from '@mui/material';
import { sx } from './tiktok.sx';
import { SocialMediaTypes } from '../../../../utils/creationContent/socialMediaTypes';
import AvatarAndNameForPreview from '../../AvatarAndNameForPreview/AvatarAndNameForPreview';
import TikTokMediaActions from './TikTokMediaActions';
import { memo } from 'react';

const TiktokPreview = ({ text, image = null, userDisplayName = '', profilePhoto }) => {
	return (
		<Box sx={sx.container}>
			<AvatarAndNameForPreview
				image={profilePhoto}
				name={userDisplayName}
				status="New York, new York"
				socialMediaType={SocialMediaTypes.TIKTOK}
			/>
			<Box
				sx={{
					marginTop: '16px',
					color: '#3A3A3A',
					fontSize: '12px',
					fontWeight: '500',
				}}
			>
				{text}
			</Box>
			<Box sx={sx.imageAndControllersBox}>
				<Box
					sx={{
						...sx.image(image),
						minWidth: 'calc(100% - 67px)',
						paddingBottom: '166.55%',
					}}
				/>
				<TikTokMediaActions />
			</Box>
		</Box>
	);
};

export default memo(TiktokPreview);
