import { sx } from '../ProfileListings/profileListings.sx';
import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import FadeWrapper from '../../UI/FadeWrapper/FadeWrapper';

const ProfileEmptyView = ({ text, Icon }) => {
	return (
		<Box sx={sx.emptyWrapper}>
			<FadeWrapper transitionDuration={1.5}>
				<>
					<Icon sx={{ color: 'gray', width: 100, height: 100 }} />
					<Typography
						variant="h2"
						sx={sx.emptylabel}
					>
						{text}
					</Typography>
				</>
			</FadeWrapper>
		</Box>
	);
};

export default memo(ProfileEmptyView);
