export const sx = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		width: '100vw',
		maxWidth: { xs: 'calc(100% - 5px)', md: '634px' },
		position: 'relative',
		backgroundColor: (theme) => theme.palette.bg.main,
	},
	modalHeader: {
		background: (theme) => theme.palette.bg.main,
		padding: '10px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: '54px',
		maxWidth: '634px',
		position: 'fixed',
		top: '0',
		left: '0',
		zIndex: '11',
		borderBottom: '1px solid #D9D9D9',
	},
	headerTitle: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '18px',
		fontWeight: '700',
		textTransform: 'uppercase',
	},
	modalContent: {
		overflowY: 'auto',
		position: 'relative',
		left: '0px',
		margin: '0 0 40px 0',
		width: '100%',
		maxWidth: '634px',
		height: '100%',
		maxHeight: 'calc(100vh - 54px - 80px)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		background: (theme) => theme.palette.bg.main,
	},
	roomsWrapper: {
		margin: '20px 0',
		padding: '0 15px',
		width: '100%',
		overflow: 'auto',
	},
	roomsList: {
		listStyle: 'none',
		margin: '0 auto',
		padding: 0,
		display: 'grid',
		gridTemplateColumns: 'repeat(2, minmax(220px, 300px))',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: { xs: '10px', sm: '20px' },
		width: '100%',
		'@media (max-width: 510px)': {
			gridTemplateColumns: 'repeat(1, minmax(220px, 1fr))',
		},
	},
	modalFooter: {
		position: 'fixed',
		bottom: '0',
		left: '0',
		maxWidth: '634px',
		zIndex: '11',
		width: '100%',
		height: '80px',
		borderTop: '1px solid #D9D9D9',
		display: 'flex',
		padding: '8px 20px',
		justifyContent: 'flex-end',
		alignItems: 'center',
		gap: '17px',
		background: (theme) => theme.palette.bg.main,
	},
	closeButton: {
		background: (theme) => theme.palette.text.muted,
		display: 'flex',
		padding: '12px 21px',
	},
	endMessageBox: {
		width: '100%',
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '20px',
	},
};
