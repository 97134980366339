import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import sx from '../../../pages/CreateContentPage/createContentPage.sx';
import { ContentCopy, LibraryAddCheck, RestartAlt, SwapHoriz } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { useEffect, useState, memo, useMemo } from 'react';

const GeneratedResponseCard = ({ response, setChosenGeneratedResponse, handleRegenerateResponse }) => {
	//@@viewOn:hooks
	const [isCopied, setIsCopied] = useState(false);

	const responseText = useMemo(() => {
		return typeof response?.text === 'string' ? response?.text : response?.text?.AIresponse;
	}, [response.text]);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleCopyGeneratedResponse = () => {
		setIsCopied(true);
	};
	//@@viewOff:handlers

	//@@viewOn:effects
	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 3000);
		}
	}, [isCopied]);
	//@@viewOff:effects

	return (
		<Card
			sx={{
				width: '100%',
				padding: '17px 15px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				gap: '20px',
				marginBottom: '10px',
			}}
			variant="outlined"
		>
			<Typography
				height="auto"
				variant="body1"
				sx={{
					lineHeight: '20px',
				}}
			>
				{responseText}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					gap: '7px',
					minWidth: '98px',
					height: '28px',
				}}
			>
				<Tooltip title="Regenerate">
					<IconButton
						sx={sx.generatedResponseIconButtons}
						variant="contained"
						onClick={() => handleRegenerateResponse(response.prompt, response.promptAdjective, response.id)}
					>
						<RestartAlt sx={sx.generatedResponseIcons} />
					</IconButton>
				</Tooltip>
				<CopyToClipboard
					text={responseText}
					onCopy={handleCopyGeneratedResponse}
				>
					<Tooltip title="Copy to clipboard">
						<IconButton
							sx={sx.generatedResponseIconButtons}
							variant="contained"
						>
							{isCopied ? (
								<LibraryAddCheck sx={sx.generatedResponseIcons} />
							) : (
								<ContentCopy sx={sx.generatedResponseIcons} />
							)}
						</IconButton>
					</Tooltip>
				</CopyToClipboard>
				<Tooltip title="Use in preview">
					<IconButton
						onClick={() => setChosenGeneratedResponse(response)}
						sx={sx.generatedResponseIconButtons}
						variant="contained"
					>
						<SwapHoriz sx={sx.generatedResponseIcons} />
					</IconButton>
				</Tooltip>
			</Box>
		</Card>
	);
};

export default memo(GeneratedResponseCard);
