import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CreationAPI from '../../services/creationAPI';
import useQueryActions from '../../hooks/useQueryActions/useQueryActions';
import useScreen from '../../hooks/useScreenSize/useScreenSize';
import { saveAs } from 'file-saver';
import useGenerateImage from '../../hooks/useGenerateImage/useGenerateImage';
import { toast } from 'react-toastify';
import { useAppProvider } from '../Layout/LayoutProvider';
import { useDocumentTitle } from 'usehooks-ts';
import useGetImagesPresets from '../../hooks/useGetImagesPresets/useGetImagesPresets';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';
import { createImagineMessageFromValues } from '../../utils/editingImages/createImagineMessageFromValues';
import { analytics } from '../../firebase/config';
import { logEvent } from 'firebase/analytics';

const EditingPhotosPageContext = createContext(null);

const EditingPhotosPageProvider = ({ children }) => {
	//@@viewOn:statics
	const { UID } = useAuthorization();
	//@@viewOff:statics

	//@@viewOn:hooks
	const screen = useScreen();
	const suggestionsCountForShowing = useMemo(() => {
		if (screen === 'xs') {
			return 3;
		} else if (screen === 's') {
			return 4;
		} else if (screen === 'm') {
			return 4;
		} else {
			return 5;
		}
	}, [screen]);

	const { regions, filterOptions, showOnlyAgentsListings, initialSavedFilter } = useAppProvider();

	const [isPresetModalOpen, setIsPresetModalOpen] = useState(false);
	const [activePreset, setActivePreset] = useState(null);

	const [presets, setPresets] = useState(null);
	const [presetsIsLoading, setPresetsIsLoading] = useState(true);

	const [isBasicMode, setIsBasicMode] = useState(true);

	const [isLoadingChosenListings, setIsLoadingChosenListings] = useState(false);
	const [suggestionItems, setSuggestionItems] = useState(null);

	const [initialItemForEditing, setInitialItemForEditing] = useState(null);

	const [itemForEditing, setItemForEditing] = useState(null);
	const [generatedItemsList, setGeneratedItemsList] = useState(null);
	const [isItemGenerated, setIsItemGenerated] = useState(false);

	const { getListingsRooms, saveImageToStorage, saveOrUpdatePreset } = useQueryActions();

	const { isLoading: allListingsLoading, mutateAsync: getAllListings } = getListingsRooms;

	const { mutateAsync: uploadImageToStorageAsync, isLoading: isUploadingImage } = saveImageToStorage;
	const { mutateAsync: saveOrUpdatePresetAsync, isLoading: isSavingPreset } = saveOrUpdatePreset;

	const suggestionsAreLoading = useMemo(() => {
		return !suggestionItems;
	}, [suggestionItems]);

	const { isGeneratingImage, progress, startImageGeneration } = useGenerateImage({
		setItemForEditing,
		itemForEditing,
		setInitialItemForEditing,
		setIsItemGenerated,
		setGeneratedItemsList,
		UID,
	});
	//@@viewOff:hooks

	//@@viewOn:handlers
	const getImageToShow = useCallback((item) => {
		if (typeof item === 'string') {
			if (item.includes('cdn.midjourney') || item.includes('https://firebasestorage')) {
				return item;
			}
		} else if (item?.Media) {
			return item?.Media?.[1] || item?.Media?.[0] || '';
		} else {
			return URL.createObjectURL(item);
		}
	}, []);

	const handleGenerateImage = useCallback(
		async (filters) => {
			logEvent(analytics, 'generate_image_func', {
				filters: filters,
			});

			const imgUrl =
				typeof itemForEditing === 'string' ? itemForEditing : itemForEditing?.Media?.[1] || itemForEditing?.Media?.[0];

			if (!imgUrl && filters.positivePrompts === '' && filters.negativePrompts === '') {
				toast.error('Please choose an image or write some prompts');
				return;
			}

			if (imgUrl && filters.positivePrompts === '' && filters.negativePrompts === '') {
				toast.error('Please provide prompts for your chosen image');
				return;
			}

			const msg = createImagineMessageFromValues(imgUrl, filters);
			await startImageGeneration(msg, filters);
		},
		[startImageGeneration, itemForEditing, isGeneratingImage],
	);

	const handleSavePreset = useCallback(
		async (presetValues) => {
			try {
				const updatedPreset = await saveOrUpdatePresetAsync({ presetValues, UID, activePreset });
				setActivePreset(updatedPreset);
				setIsPresetModalOpen(false);
			} catch (e) {
				const message = activePreset ? 'Could not update preset, try again' : 'Could not save preset, try again';
				toast.error(message);
			}
		},
		[setIsPresetModalOpen, activePreset, UID],
	);

	const handleClickEditPreset = useCallback(
		(preset) => {
			setActivePreset(preset);
			setIsBasicMode(false);
			setIsPresetModalOpen(true);
		},
		[setActivePreset, setIsBasicMode, setIsPresetModalOpen],
	);

	const handleResetGeneratedItem = useCallback(() => {
		setIsItemGenerated(false);
		setGeneratedItemsList(null);
	}, [setIsItemGenerated, setGeneratedItemsList]);

	const handleUndoClick = useCallback(() => {
		setItemForEditing(null);
		handleResetGeneratedItem();
	}, [setItemForEditing, handleResetGeneratedItem]);

	const handleUploadItemForEditing = async (e) => {
		const file = e.target.files[0];

		if (!file) {
			toast.error('Image was not provided');
			return;
		}

		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
			toast.error('Image type is not supported');
			return;
		}

		try {
			const url = await uploadImageToStorageAsync(file);

			setItemForEditing(url);
			handleResetGeneratedItem();
		} catch (error) {
			toast.error('Could not upload image, try again');
			console.error('Error uploading and setting item for editing:', error);
		}
	};

	const handleChoosePreset = useCallback(
		(preset) => {
			setActivePreset(preset);
			setIsBasicMode(false);
		},
		[setActivePreset],
	);

	const handleDownloadImage = useCallback(() => {
		if (itemForEditing) {
			const url = getImageToShow(itemForEditing);
			saveAs(url, 'serhant-photo');
		}
	}, [itemForEditing, getImageToShow, isGeneratingImage]);
	//@@viewOff:handlers

	//@@viewOn:context
	const contextValue = useMemo(
		() => ({
			isBasicMode,
			setIsBasicMode,
			itemForEditing,
			setItemForEditing,
			suggestionItems,
			setSuggestionItems,
			isLoadingChosenListings,
			setIsLoadingChosenListings,
			allListingsLoading,
			suggestionsAreLoading,
			isPresetModalOpen,
			setIsPresetModalOpen,
			handleSavePreset,
			suggestionsCountForShowing,
			handleDownloadImage,
			isGeneratingImage,
			handleGenerateImage,
			getImageToShow,
			handleUploadItemForEditing,
			progress,
			isItemGenerated,
			setIsItemGenerated,
			generatedItemsList,
			setGeneratedItemsList,
			handleUndoClick,
			handleResetGeneratedItem,
			activePreset,
			setActivePreset,
			presets,
			setPresets,
			presetsIsLoading,
			setPresetsIsLoading,
			handleChoosePreset,
			handleClickEditPreset,
			isUploadingImage,
			isSavingPreset,
			initialItemForEditing,
			setInitialItemForEditing,
		}),
		[
			isBasicMode,
			setIsBasicMode,
			itemForEditing,
			setItemForEditing,
			suggestionItems,
			setSuggestionItems,
			isLoadingChosenListings,
			setIsLoadingChosenListings,
			allListingsLoading,
			suggestionsAreLoading,
			isPresetModalOpen,
			setIsPresetModalOpen,
			handleSavePreset,
			suggestionsCountForShowing,
			handleDownloadImage,
			isGeneratingImage,
			handleGenerateImage,
			getImageToShow,
			handleUploadItemForEditing,
			progress,
			isItemGenerated,
			setIsItemGenerated,
			generatedItemsList,
			setGeneratedItemsList,
			handleUndoClick,
			handleResetGeneratedItem,
			activePreset,
			setActivePreset,
			presets,
			setPresets,
			presetsIsLoading,
			setPresetsIsLoading,
			handleChoosePreset,
			handleClickEditPreset,
			isUploadingImage,
			isSavingPreset,
			initialItemForEditing,
			setInitialItemForEditing,
		],
	);
	//@@viewOff:context

	//@@viewOn:effects
	useDocumentTitle(`Editing Photos | S.GPT`);

	useEffect(() => {
		// Effect for Resetting Generated Items
		if (!itemForEditing) {
			setIsItemGenerated(false);
			setGeneratedItemsList(null);
		}
	}, [itemForEditing, setIsItemGenerated, setGeneratedItemsList]);

	useGetImagesPresets({ UID, presets, setPresets, setPresetsIsLoading });

	useEffect(() => {
		// Effect for Managing Chosen Listings
		setIsLoadingChosenListings(true);
		CreationAPI.getChosenListings(UID).then((chosenListings) => {
			if (chosenListings) {
				const chosenListingsList = chosenListings.length ? chosenListings : Object.values(chosenListings);
				if (chosenListingsList.length < suggestionsCountForShowing) {
					const existingListingIds = new Set(chosenListingsList.map((item) => item.Id));

					getAllListings({ regions, filterOptions, showOnlyAgentsListings }).then((listingsList) => {
						const additionalListings = [];
						for (const listing of listingsList) {
							if (!existingListingIds.has(listing.Id)) {
								additionalListings.push(listing);
								existingListingIds.add(listing.Id);

								if (additionalListings.length === suggestionsCountForShowing - chosenListingsList.length) {
									break;
								}
							}
						}

						setSuggestionItems([...chosenListingsList, ...additionalListings]);
						setIsLoadingChosenListings(false);
					});
				} else {
					setSuggestionItems(chosenListings.slice(0, suggestionsCountForShowing));
				}
				setIsLoadingChosenListings(false);
			} else {
				getAllListings({ regions, filterOptions, showOnlyAgentsListings }).then((listingsList) => {
					setSuggestionItems(listingsList.slice(0, suggestionsCountForShowing) || []);
					setIsLoadingChosenListings(false);
				});
			}
		});
	}, [UID]);

	useEffect(() => {
		// Effect for Managing Basic Mode
		if (initialSavedFilter) {
			setIsBasicMode(false);
		}
	}, [initialSavedFilter, setIsBasicMode]);
	//@@viewOff:effects

	return <EditingPhotosPageContext.Provider value={contextValue}>{children}</EditingPhotosPageContext.Provider>;
};

const useEditingPhotosPage = () => {
	const context = useContext(EditingPhotosPageContext);
	if (!context) {
		throw new Error('useEditingPhotosPage must be used within a EditingPhotos page');
	}
	return context;
};

export { useEditingPhotosPage };
export default memo(EditingPhotosPageProvider);
