import { Box, SvgIcon } from '@mui/material';
import { memo } from 'react';

const InstagramActionsIcons = () => {
	return (
		<Box
			sx={{
				margin: '20px auto 0 auto',
				padding: '0 20px',
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			<Box sx={{ width: '116px', display: 'flex', justifyContent: 'space-between' }}>
				<SvgIcon sx={{ width: '20px', height: '20px' }}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="Frame">
							<path
								id="Vector"
								d="M14.4167 1.29163C12.5417 1.29163 11.125 2.04163 10 3.62496C8.875 2.08329 7.45833 1.33329 5.58333 1.33329C2.5 1.29163 0 3.99996 0 7.33329C0 10.375 2.25 12.3333 4.41667 14.2083C4.66667 14.4166 4.95833 14.6666 5.20833 14.9166L6.16667 15.75C8 17.375 8.91667 18.2083 9.33333 18.4583C9.54167 18.5833 9.79167 18.6666 10 18.6666C10.2083 18.6666 10.4583 18.5833 10.6667 18.4583C11.0833 18.2083 11.8333 17.5416 13.9167 15.625L14.75 14.875C15.0417 14.625 15.2917 14.375 15.5833 14.1666C17.7917 12.3333 20 10.4166 20 7.33329C20 3.99996 17.5 1.29163 14.4167 1.29163Z"
								fill="#FF3040"
							/>
						</g>
					</svg>
				</SvgIcon>
				<SvgIcon sx={{ width: '20px', height: '20px' }}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g
							id="Frame"
							clipPath="url(#clip0_1_250)"
						>
							<path
								id="Vector"
								d="M17.2133 14.1733C18.2259 12.4218 18.5662 10.3614 18.1706 8.37728C17.7751 6.39311 16.6707 4.62082 15.0637 3.3915C13.4568 2.16217 11.4573 1.55989 9.43879 1.69718C7.42023 1.83447 5.5207 2.70194 4.09504 4.13752C2.66939 5.57311 1.81512 7.47862 1.69184 9.49807C1.56856 11.5175 2.1847 13.5128 3.42515 15.1111C4.6656 16.7095 6.44551 17.8016 8.43237 18.1834C10.4192 18.5652 12.4771 18.2106 14.2217 17.1858L18.3333 18.3333L17.2133 14.1733Z"
								stroke="#9FA0A2"
								strokeWidth="2"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<clipPath id="clip0_1_250">
								<rect
									width="20"
									height="20"
									fill="white"
								/>
							</clipPath>
						</defs>
					</svg>
				</SvgIcon>
				<SvgIcon sx={{ width: '20px', height: '20px' }}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="Frame">
							<path
								id="Vector"
								d="M18.3333 2.5L7.68167 8.4025"
								stroke="#9FA0A2"
								strokeWidth="2"
								strokeLinejoin="round"
							/>
							<path
								id="Vector_2"
								d="M9.74832 16.945L18.3333 2.50085H1.66666L7.68166 8.40335L9.74832 16.945Z"
								stroke="#9FA0A2"
								strokeWidth="2"
								strokeLinejoin="round"
							/>
						</g>
					</svg>
				</SvgIcon>
			</Box>
			<SvgIcon sx={{ width: '20px', height: '20px' }}>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g id="Frame">
						<path
							id="Vector"
							d="M16.6666 17.5L9.99998 11.2L3.33331 17.5V2.5H16.6666V17.5Z"
							stroke="#9FA0A2"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</svg>
			</SvgIcon>
		</Box>
	);
};

export default memo(InstagramActionsIcons);
