export const sx = {
	regenerateResponseButton: {
		display: 'flex',
		width: '230px',
		margin: '0 auto 16px auto',
		padding: '10px 21px',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		borderRadius: '8px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom1} !important`,
		background: (theme) => theme.palette.bg.main,
		color: (theme) => theme.palette.text.quinternary,
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '24px',
		letterSpacing: '0.4px',
		maxHeight: '46px',
		span: {
			margin: '0',
		},
	},
	chatControls: {
		width: '100%',
		maxWidth: '799px',
		paddingRight: '20px',
		height: '210px',
		background: 'transparent',
		position: 'relative',
	},
	newMessageInput: {
		inputProps: {
			// borderRadius: '12px',
			// boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
		},
		sx: {
			background: (theme) => theme.palette.bg.main,
			width: '100%',
			fieldset: {
				border: (theme) => `1px solid ${theme.palette.stroke.custom3} !important`,
			},
		},
	},
	sendMessageButton: {
		position: 'absolute',
		top: { xs: '77px', xl: 'auto' },
		bottom: { xs: 0, xl: '17px' },
		right: { xs: '26px', md: '36px' },
		display: 'flex',
		width: '41px',
		height: '41px',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '14px',
		background: '#6562E4 !important',
	},
};
