import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { memo, useState } from 'react';
import ChangelogDialog from '../../components/Modals/ChangelogDialog/ChangelogDialog';
import ChangelogModal from '../../components/Modals/ChangelogModal/ChangelogModal';
import FadeWrapper from '../../components/UI/FadeWrapper/FadeWrapper';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';
import { useQuery } from '@tanstack/react-query';
import CommonAPI from '../../services/commonApi';
import { toast } from 'react-toastify';
import Loader from '../../components/UI/Loader/Loader';

const Layout = () => {
	//@@viewOn:hooks
	const initializedShowChangelog = localStorage.getItem('showUpdateDialog');
	if (!initializedShowChangelog) {
		localStorage.setItem('showUpdateDialog', true);
	}
	const { UID } = useAuthorization();
	const location = useLocation();

	const [showChangelogDialog, setShowChangelogDialog] = useState(JSON.parse(localStorage.getItem('showUpdateDialog')));
	const [showChangelogModal, setShowChangelogModal] = useState(false);

	const [mainMdContent, setMainMdContent] = useState('');

	useQuery({
		queryKey: ['mainMdContent'],
		queryFn: () => CommonAPI.getChangelog(),
		onSuccess: (data) => {
			const regex = /\[\/\/\]: # \((\d{2}\/\d{2}\/\d{4})\)/;
			const match = data.match(regex);

			const showedUpdatesList = localStorage.getItem('showedUpdatesList')
				? JSON.parse(localStorage.getItem('showedUpdatesList'))
				: [];

			if (match && match[1] && !showedUpdatesList.includes(match[1])) {
				setShowChangelogDialog(true);
				showedUpdatesList.push(match[1]);
				localStorage.setItem('showedUpdatesList', JSON.stringify(showedUpdatesList));
				localStorage.setItem('showUpdateDialog', true);
			} else {
				if (!showChangelogDialog) {
					setShowChangelogDialog(false);
				}
			}

			setMainMdContent(data);
		},
		onError: (error) => {
			console.log(error);
			toast.error('Error getting update changelog content');
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchInterval: false,
	});
	//@@viewOff:hooks

	//@@viewOn:statics
	const isMainPage = location.pathname === '/';
	//@@viewOff:statics

	return (
		<div style={{ height: '100vh', position: 'absolute', width: '100%' }}>
			<ChangelogDialog
				setShowChangelogDialog={setShowChangelogDialog}
				showChangelogDialog={showChangelogDialog && UID}
				setShowChangelogModal={setShowChangelogModal}
			/>
			<ChangelogModal
				setShowChangelogModal={setShowChangelogModal}
				showChangelogModal={showChangelogModal}
				mainMdContent={mainMdContent}
			/>
			<Header />
			{!mainMdContent ? (
				<Loader
					sx={{ height: 'calc(100vh - 70px)' }}
					disableShrink={true}
				/>
			) : (
				<>
					<Outlet />
					{isMainPage && (
						<FadeWrapper transitionDuration={3000}>
							<Footer />
						</FadeWrapper>
					)}
				</>
			)}
		</div>
	);
};

export default memo(Layout);
