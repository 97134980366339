import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useQueryActions from '../../hooks/useQueryActions/useQueryActions';
import { v4 as uuidv4 } from 'uuid';
import ChatAPI from '../../services/chatAPI';
import { getContextMessages } from '../../utils/chat/getContextMessages';
import { useAppProvider } from '../Layout/LayoutProvider';
import { useDocumentTitle } from 'usehooks-ts';
import useGetChatThreads from '../../hooks/useGetChatThreads/useGetChatThreads';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';
import { analytics } from '../../firebase/config';
import { logEvent } from 'firebase/analytics';

const ChatPageContext = createContext(null);

const ChatPageProvider = ({ children }) => {
	//@@viewOn:statics
	const { photoURL: profilePhoto, UID } = useAuthorization();
	//@@viewOff:statics

	//@@viewOn:hooks
	const navigate = useNavigate();
	const { id } = useParams();
	const chatMessagesRef = useRef(null);

	const { isMobile } = useAppProvider();

	const [showChatThreads, setShowChatThreads] = useState(false);
	const [isLoadingChatThread, setIsLoadingChatThread] = useState(false);
	const [chatThreads, setChatThreads] = useState([]);
	const [activeChatThread, setActiveChatThread] = useState(null);

	const { makeChatRequest } = useQueryActions();

	const { isLoading, isError, mutateAsync } = makeChatRequest;
	const [chatThreadsIsLoading, setChatThreadsIsLoading] = useState(true);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const scrollChatToBottom = () => {
		chatMessagesRef.current?.scrollTo({ bottom: 0, behavior: 'smooth' });
	};

	const handleToggleChatThreads = useCallback((value) => {
		setShowChatThreads(value);
	}, []);

	const handleChooseChatThread = useCallback((chatThreadId) => {
		navigate(`/chat/${chatThreadId}`);
		handleToggleChatThreads(false);
	}, []);

	const handleCreateNewChatThread = useCallback(() => {
		navigate(`/chat/`);
		setActiveChatThread(null);
		setShowChatThreads(false);
	}, []);

	const handleDeleteAllChatThreads = useCallback(() => {
		ChatAPI.deleteAllChatThreads(UID);
		setChatThreads([]);
		navigate(`/chat/`);
		setShowChatThreads(false);
		setActiveChatThread(null);
	}, [UID]);

	const handleUpdateChat = useCallback(async (chatThreadForUpdate) => {
		await ChatAPI.updateChat(UID, chatThreadForUpdate);
		setActiveChatThread(chatThreadForUpdate);
		setTimeout(scrollChatToBottom, 0);
	}, []);

	const createNameForChatThread = useCallback(
		async (chatThread, messages = []) => {
			const existingMessages = [
				...chatThread.chatString,
				{
					role: 'user',
					content: 'Create chat topic with maximum 4 words without any brackets!!! based on previous messages',
				},
			];

			const generatedName = await makeChatRequest.mutateAsync({
				chatMessages: existingMessages.length < 2 ? messages : existingMessages,
			});

			const chatWithCreatedName = {
				...chatThread,
				chatName: generatedName.choices[0].message.content,
			};
			await handleUpdateChat(chatWithCreatedName);
		},
		[makeChatRequest.mutateAsync, handleUpdateChat],
	);

	const handleSendMessage = useCallback(
		async (prompt) => {
			logEvent(analytics, 'send_message_click');

			if (id) {
				const chatThread = await ChatAPI.getChatById(UID, id);

				const newMessage = { role: 'user', content: prompt, timeStamp: new Date().toISOString(), id: uuidv4() };
				const allChatMessages = [...chatThread.chatString, newMessage];
				let chatThreadForUpdate = {
					...chatThread,
					chatString: allChatMessages,
				};
				await handleUpdateChat(chatThreadForUpdate);

				const aiAnswer = await mutateAsync({ chatMessages: [...getContextMessages(chatThread), newMessage] });
				chatThreadForUpdate = {
					...chatThread,
					chatString: [
						...allChatMessages,
						{
							...aiAnswer.choices[0].message,
							id: uuidv4(),
							timeStamp: new Date().toISOString(),
						},
					],
				};

				await handleUpdateChat(chatThreadForUpdate);

				if (!chatThreadForUpdate?.name) {
					await createNameForChatThread(chatThreadForUpdate, chatThreadForUpdate?.chatString);
				}
			} else {
				const newId = uuidv4().toString();

				const chatThread = {
					chatString: [
						{
							role: 'system',
							content: `You are an AI assistant that helps people with real estate information.`,
							timeStamp: new Date().toISOString(),
						},
						{
							role: 'user',
							content: prompt,
							timeStamp: new Date().toISOString(),
						},
					],
					chatName: '',
					id: newId,
					timestamp: new Date().toISOString(),
				};

				let createdChatThread = await ChatAPI.createChat(UID, chatThread);
				await handleUpdateChat(createdChatThread);
				navigate(`/chat/${createdChatThread.id}`);

				const aiResponse = await mutateAsync({ chatMessages: chatThread.chatString });
				if (aiResponse) {
					const aiAnswer = aiResponse.choices[0].message;
					createdChatThread = {
						...createdChatThread,
						chatString: [
							...createdChatThread.chatString,
							{
								...aiAnswer,
								timeStamp: new Date().toISOString(),
								id: uuidv4(),
							},
						],
					};
					await handleUpdateChat(createdChatThread);

					const contextMessages = [
						{
							role: 'user',
							content: `Create sentence with maximum 4 words!!! based on this user prompt: ${prompt}. Do not use brackets around the words.`,
						},
					];

					await createNameForChatThread(createdChatThread, contextMessages);
				}
			}
		},
		[id],
	);

	const handleRegenerateMessage = useCallback(async () => {
		logEvent(analytics, 'regenerate_message_click');

		const chatThread = await ChatAPI.getChatById(UID, id);
		const contextMessages = getContextMessages(chatThread, true);

		const aiResponse = await mutateAsync({ chatMessages: contextMessages });
		if (aiResponse) {
			const aiAnswer = aiResponse.choices[0].message;

			const chatThreadForUpdate = {
				...chatThread,
				chatString: [
					...contextMessages,
					{
						...aiAnswer,
						timeStamp: new Date().toISOString(),
						id: uuidv4(),
					},
				],
			};
			await handleUpdateChat(chatThreadForUpdate);

			if (!chatThreadForUpdate?.name) {
				await createNameForChatThread(chatThreadForUpdate, chatThreadForUpdate?.chatString);
			}
		}
	}, [UID, id, mutateAsync, handleUpdateChat]);
	//@@viewOff:handlers

	//@@viewOn:effects
	useDocumentTitle(`Chat ${activeChatThread?.chatName.replace(/"/g, '') || ''} | S.GPT`);

	useEffect(() => {
		// Loading Active Chat Thread and Initial Data Fetch
		if (id) {
			setIsLoadingChatThread(true);
			ChatAPI.getChatById(UID, id).then((chatThread) => {
				if (!chatThread) {
					navigate(`/chat/`);
				} else {
					setActiveChatThread(chatThread);
				}
				setIsLoadingChatThread(false);
			});
		}
	}, [id]);

	useGetChatThreads({
		chatThreads,
		setChatThreads,
		setChatThreadsIsLoading,
		UID,
		depsList: [id, UID, activeChatThread?.chatString?.length],
	});

	useEffect(() => {
		// Auto-scrolling Chat Messages
		const chatMessagesContainer = chatMessagesRef.current;
		if (chatMessagesContainer) {
			chatMessagesContainer.scrollTop = chatMessagesContainer.scrollHeight;
		}
	}, [activeChatThread, chatMessagesRef]);
	//@@viewOff:effects

	//@@viewOn:context
	const contextValue = useMemo(
		() => ({
			chatMessagesRef,
			isMobile,
			profilePhoto,
			showChatThreads,
			handleToggleChatThreads,
			handleChooseChatThread,
			chatThreads,
			activeChatThread,
			handleSendMessage,
			handleRegenerateMessage,
			chatThreadsIsLoading,
			handleCreateNewChatThread,
			handleDeleteAllChatThreads,
			isMessageLoading: isLoading,
			isMessageError: isError,
			isLoadingChatThread: isLoadingChatThread || (!activeChatThread && id),
		}),
		[
			chatMessagesRef,
			isMobile,
			profilePhoto,
			showChatThreads,
			handleToggleChatThreads,
			handleChooseChatThread,
			chatThreads,
			activeChatThread,
			handleSendMessage,
			handleRegenerateMessage,
			chatThreadsIsLoading,
			handleCreateNewChatThread,
			handleDeleteAllChatThreads,
			isLoading,
			isError,
			isLoadingChatThread,
			id,
		],
	);
	//@@viewOff:context

	return <ChatPageContext.Provider value={contextValue}>{children}</ChatPageContext.Provider>;
};

const useChatPage = () => {
	const context = useContext(ChatPageContext);
	if (!context) {
		throw new Error('useChatPage must be used within a ChatPageProvider');
	}
	return context;
};

export default memo(ChatPageProvider);
export { useChatPage };
