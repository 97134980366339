import { Avatar, Box, Typography } from '@mui/material';
import { SocialMediaTypes } from '../../../utils/creationContent/socialMediaTypes';
import { avatarSx } from './avatar.sx';
import { memo } from 'react';

const AvatarAndNameForPreview = ({ image = null, name = '', status = '', socialMediaType = null }) => {
	//@@viewOn:statics
	const isTiktok = socialMediaType === SocialMediaTypes.TIKTOK;
	//@@viewOff:statics

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '12px',
			}}
		>
			<Box
				sx={{
					...(isTiktok && {
						padding: '1px',
						border: '1px solid #FFC8D3',
						borderRadius: '50%',
					}),
				}}
			>
				<Avatar
					src={image}
					sx={avatarSx.avatar(socialMediaType)}
				/>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
				<Typography
					variant="body1"
					sx={{
						color: (theme) => theme.palette.text.primary,
						fontSize: '12px',
						fontWeight: '600',
						lineHeight: '15px',
					}}
				>
					{name}
				</Typography>
				<Typography
					sx={{
						color: '#8B8B8B',
						fontSize: '11px',
						fontWeight: '500',
						lineHeight: '13px',
					}}
					variant="body2"
				>
					{status}
				</Typography>
			</Box>
		</Box>
	);
};

export default memo(AvatarAndNameForPreview);
