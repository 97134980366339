import { useEffect, useState } from 'react';
import placeholderImage from '../../../assets/images/placeholderImg.png';
import { Box } from '@mui/material';
import { memo } from 'react';

//@@viewOn:private
const loadingStyles = {
	filter: 'opacity(0%)',
	clipPath: 'inset(0)',
};
const loadedStyles = {
	filter: 'opacity(100%)',
	objectFit: 'cover',
	objectPosition: 'center',
	transition: 'filter 0.4s linear',
};
//@@viewOff:private

const ProgressiveImg = ({ src, ...props }) => {
	//@@viewOn:hooks
	const [imgSrc, setImgSrc] = useState(placeholderImage);
	//@@viewOff:hooks

	//@@viewOn:statics
	const imageStyles = imgSrc === placeholderImage ? loadingStyles : loadedStyles;
	//@@viewOff:statics

	//@@viewOn:effects
	useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => {
			setImgSrc(src);
		};
	}, [src]);
	//@@viewOff:effects

	return (
		<Box
			sx={{
				position: 'relative',
				backgroundImage: `url(${placeholderImage})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				width: '100%',
				height: '100%',
				borderRadius: (props?.style && props?.style?.borderRadius) || '0px',
			}}
		>
			<img
				{...{ src: imgSrc, ...props }}
				alt={props?.alt || ''}
				style={{ ...imageStyles, ...(props?.style ? { ...props.style } : {}) }}
			/>
		</Box>
	);
};
export default memo(ProgressiveImg);
