import { isToday, isYesterday, differenceInDays } from 'date-fns';

/**
 * Categorizes chat threads based on their message timestamps into various time-based categories.
 *
 * @param {Array} chatThreads - An array of chat threads to be categorized.
 * @returns {Object} An object containing categorized chat threads under different time categories.
 * @throws {Error} If chatThreads is not a valid array.
 */
export const createTimeCategoriesForChats = (chatThreads) => {
	const currentDate = new Date();
	const categories = {
		Today: [],
		Yesterday: [],
		'This week': [],
		'This month': [],
		'This year': [],
	};

	chatThreads.forEach((chatThread) => {
		const lastMessage = chatThread.chatString[chatThread.chatString.length - 1];
		const messageDate = new Date(lastMessage.timeStamp);

		if (isToday(messageDate)) {
			categories.Today.push(chatThread);
		} else if (isYesterday(messageDate)) {
			categories.Yesterday.push(chatThread);
		} else if (differenceInDays(currentDate, messageDate) <= 7) {
			categories['This week'].push(chatThread);
		} else if (differenceInDays(currentDate, messageDate) <= 30) {
			categories['This month'].push(chatThread);
		} else {
			categories['This year'].push(chatThread);
		}
	});

	return categories;
};
