import { useEffect } from 'react';
import ChatAPI from '../../services/chatAPI';
import { off, onValue, ref } from 'firebase/database';
import { db } from '../../firebase/config';

const useGetChatThreads = ({ chatThreads, setChatThreads, setChatThreadsIsLoading, UID, depsList }) => {
	useEffect(() => {
		// Loading Initial Chat Threads List
		if (chatThreads.length === 0) {
			ChatAPI.getChats(UID).then((chats) => {
				setChatThreads(chats || []);
			});
		}

		const chatThreadsRef = ref(db, `chatHistory/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const chatThreads = Object.values(data || {});

			setChatThreads(chatThreads || []);
			setChatThreadsIsLoading(false);
		};

		onValue(chatThreadsRef, onDataChange);

		return () => {
			off(chatThreadsRef, onDataChange);
		};
	}, depsList);
};

export default useGetChatThreads;
