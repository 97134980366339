import { Box, Typography } from '@mui/material';
import { sx } from './blogPost.sx';
import { memo } from 'react';

const BlogPostPreview = ({ text }) => {
	return (
		<Box sx={sx.container}>
			<Typography
				sx={{
					color: (theme) => theme.palette.text.primary,
					fontSize: '18px',
					fontWeight: '700',
				}}
			>
				A call for New York Residents
			</Typography>
			<Typography
				paragraph={true}
				variant="pre"
				sx={{
					color: (theme) => theme.palette.text.primary,
					fontSize: '12px',
					fontWeight: '500',
					lineHeight: '18px',
				}}
			>
				{text}
			</Typography>
		</Box>
	);
};

export default memo(BlogPostPreview);
