export const sx = {
	generatedItemsList: {
		margin: { xs: '11px auto 0 auto', md: '11px 0 0 0' },
		display: 'grid',
		width: 'fit-content',
		gridTemplateColumns: { xs: '1fr', md: 'repeat(2, minmax(350.5px, 522px))' },
		gap: '17px',
	},
	generatedItem: {
		display: 'flex',
		flexDirection: { xs: 'column', md: 'row' },
		width: '100%',
		height: { xs: 'fit-content', md: '255px' },
		maxWidth: '522px',
		gap: '9px',
		padding: '9px',
		borderRadius: '8px',
		border: (theme) => `1px solid ${theme.palette.bg.light}`,
		background: 'rgba(246, 248, 249, 0.50)',
	},
	images: {
		width: '100%',
		height: '100%',
		position: 'relative',
		zIndex: 1,
	},
	carouselWrapper: {
		width: '100%',
		height: '235px',
		borderRadius: '8px',
	},
	carousel: {
		indicatorIconButtonProps: {
			sx: {
				width: '10px',
				height: '10px',
				padding: '0',
				color: (theme) => theme.palette.bg.main,
				opacity: '0.3',
				margin: '0 3px',
				svg: {
					width: '10px',
					height: '10px',
				},
			},
		},
		activeIndicatorIconButtonProps: {
			style: {
				opacity: '1',
			},
		},
		navButtonsProps: {
			style: {
				background: 'transparent',
				padding: '0',
				width: '14px',
				height: '14px',
			},
		},
		indicatorContainerProps: {
			style: {
				position: 'absolute',
				bottom: '5px',
				textAlign: 'center',
				zIndex: '10',
			},
		},
	},
	imageButton: {
		padding: '4px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '10px',
		borderRadius: '50%',
		background: 'rgba(0, 0, 0, 0.10)',
		flexShrink: 0,
		width: '25px',
		height: '25px',
	},
	actions: {
		zIndex: 5,
		position: 'absolute',
		top: '12px',
		right: '10px',
		display: 'flex',
		gap: '6px',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: '19px',
		width: '100%',
	},
	detailedInfo: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		borderRadius: '8px',
		gap: '13px',
		padding: '10px',
		background: (theme) => theme.palette.bg.custom1,
	},
	detailedInfoItem: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexDirection: 'column',
		gap: '3px',
	},
	detailedLabel: {
		color: (theme) => theme.palette.text.primary,
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: 'normal',
		textWrap: 'nowrap',
	},
	detailedValue: {
		color: (theme) => theme.palette.text.quinternary,
		fontSize: '11px',
		fontWeight: '500',
		lineHeight: '15px',
		maxHeight: '60px',
		overflow: 'hidden',
		textWrap: 'wrap',
		textOverflow: 'ellipsis',
	},
};
