import { useEffect } from 'react';
import EditingImagesAPI from '../../services/editingImagesAPI';
import { off, onValue, ref } from 'firebase/database';
import { db } from '../../firebase/config';

const useGetGeneratedImages = ({ generatedImages, UID, setGeneratedImages, setGeneratedImagesIsLoading }) => {
	useEffect(() => {
		// Effect for managing Generated Images
		if (!generatedImages?.length) {
			EditingImagesAPI.getGeneratedImages(UID).then((generatedImages) => {
				setGeneratedImages(generatedImages || []);
			});
		}

		const generatedImagesRef = ref(db, `generatedImages/${UID}/`);
		const onDataChange = (snapshot) => {
			const data = snapshot.val();
			const newGeneratedImages = Object.values(data || {});

			setGeneratedImages(newGeneratedImages || []);
			setGeneratedImagesIsLoading(false);
		};

		onValue(generatedImagesRef, onDataChange);

		return () => {
			off(generatedImagesRef, onDataChange);
		};
	}, [UID, setGeneratedImages, setGeneratedImagesIsLoading]);
};

export default useGetGeneratedImages;
