import { useMutation, useQueryClient } from '@tanstack/react-query';
import RoomsAPI from '../../services/roomsAPI';
import CreationAPI from '../../services/creationAPI';
import { platformsPrompts } from '../../utils/creationContent/platformPrompts';
import { getFilterOptionsBody } from '../../utils/listing/getPropertyTypesForFilter';
import { openaiChat } from '../../azure/config';
import { toast } from 'react-toastify';
import EditingImagesAPI from '../../services/editingImagesAPI';

const UseQueryActions = () => {
	const queryClient = useQueryClient();

	const getListingsRooms = useMutation({
		mutationKey: ['getListingsRooms'],
		mutationFn: async ({ regions = [], filterOptions, showOnlyAgentsListings = false }) => {
			let filters;
			if (!filterOptions) {
				filters = getFilterOptionsBody(null);
			}

			const body = {
				...(filterOptions ? filterOptions : filters),
				regionsSelected: regions || [],
				showOnlyAgentsListings,
			};
			return RoomsAPI.getRoomsPost(body);
		},
		onSuccess: async (data) => {
			await queryClient.setQueryData(['getListingsRooms'], data);
		},
		onError: (error) => {
			const errorMessage = error.toString();
			toast.error('Could not get listings');
			console.log('error', errorMessage);
		},
	});

	const createSocialPost = useMutation({
		mutationKey: ['createSocialPost'],
		mutationFn: async ({ prompt, promptAdjective, listingId, postType }) => {
			let reqBody = platformsPrompts[postType];
			reqBody.platformPrompt = prompt;
			reqBody.propertyID = listingId;
			reqBody.promptAdjective = promptAdjective;

			return await CreationAPI.createSocialPost(reqBody);
		},
		onError: (error) => {
			const errorMessage = error.toString();
			toast.error('Could not create social post');
			console.log('error', errorMessage);
		},
	});

	const makeChatRequest = useMutation({
		mutationKey: ['makeChatRequest'],
		mutationFn: async ({ chatMessages }) => {
			const filteredChatMessages = chatMessages.map((message) => {
				return {
					role: message.role,
					content: message.content,
				};
			});

			const response = await openaiChat.createChatCompletion({
				messages: filteredChatMessages,
				model: 'Test4323',
				temperature: 0.7,
				max_tokens: 800,
				top_p: 0.95,
				frequency_penalty: 0,
				presence_penalty: 0,
				stop: null,
			});
			return response.data;
		},
		onError: (error) => {
			const errorMessage = error.toString();
			toast.error('Could not send chat request, try later');

			console.log('error', errorMessage);
		},
	});

	const saveImageToStorage = useMutation({
		mutationKey: ['saveImageToStorage'],
		mutationFn: async (file) => {
			return await EditingImagesAPI.saveImageInStorageAndReturnUrl(file);
		},
		onError: (error) => {
			const errorMessage = error instanceof Error ? error.message : 'Could not save image, try again';
			toast.error(errorMessage);
		},
	});

	const saveOrUpdatePreset = useMutation({
		mutationKey: ['saveOrUpdatePreset'],
		mutationFn: async ({ presetValues, UID, activePreset }) => {
			if (activePreset) {
				return await EditingImagesAPI.updatePreset(presetValues, UID, activePreset.id);
			} else {
				return await EditingImagesAPI.savePreset(presetValues, UID);
			}
		},
		onError: (error) => {
			const errorMessage = error instanceof Error ? error.message : 'Could not save preset, try again';
			toast.error(errorMessage);
		},
	});

	const getListingItem = useMutation({
		mutationKey: ['getListingItem'],
		mutationFn: async ({ listingId }) => {
			if (!listingId) {
				return;
			} else {
				return await RoomsAPI.getListingItemById(listingId);
			}
		},
		onError: (error) => {
			const errorMessage = error instanceof Error ? error.message : 'Could not save preset, try again';
			toast.error(errorMessage);
		},
	});

	return {
		getListingsRooms,
		createSocialPost,
		makeChatRequest,
		saveImageToStorage,
		saveOrUpdatePreset,
		getListingItem,
	};
};

export default UseQueryActions;
