/**
 * Handles numeric input change event by parsing and processing the input value.
 *
 * @param {Object} e - The event object containing information about the input change.
 * @param {string} fieldName - The name of the field associated with the input.
 * @param {Function} setFieldValue - A function to set the field's value in the parent component's state or context.
 * @returns {void}
 */
export function handleChangeNumericInput(e, fieldName, setFieldValue) {
	const { name, value } = e.target;
	const isValueEmpty = value === '';

	const parsedValue = parseInt(value.toString().replace(/[^0-9]/g, ''));
	if (isNaN(parsedValue) && value !== '') {
		return;
	}

	setFieldValue(fieldName || name, isValueEmpty ? value : parsedValue);
}
