import { Box, Typography } from '@mui/material';
import { useChatPage } from '../../../pages/ChatPage/ChatPageProvider';
import { memo } from 'react';

const suggestions = [
	'Provide me with the current real estate market trends and forecasts for a New York region',
	'Can you assist me in finding demographic data for a specific city or town in the USA?',
	'Provide me with a list of reputable home inspectors, appraisers, and lenders in New York',
];

const EmptyChat = () => {
	//@@viewOn:hooks
	const { handleSendMessage } = useChatPage();
	//@@viewOff:hooks

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: { xs: 'flex-start', md: 'center' },
				alignItems: 'center',
				height: '100%',
				maxWidth: '799px',
				width: '100%',
				textAlign: 'center',
				margin: 'auto 0',
				paddingRight: { xs: '11px', md: '27px' },
				paddingBottom: '20px',
				overflow: 'auto',
			}}
		>
			<Typography
				variant="h2"
				sx={{
					color: '#333952',
					fontSize: '32px',
					lineHeight: { xs: '40px', md: '32px' },
					fontWeight: '700',
				}}
			>
				Welcome to Serhant Chat
			</Typography>
			<Box
				sx={{
					marginTop: '29px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexWrap: 'wrap',
					gap: '12px',
				}}
			>
				{suggestions.map((suggestion) => (
					<Box
						key={suggestion}
						sx={{
							borderRadius: '8px',
							background: (theme) => theme.palette.bg.main,
							boxShadow: '0px 4px 4px 0px rgba(234, 240, 243, 0.60)',
							padding: '32px 30px',
							cursor: 'pointer',
						}}
						onClick={() => handleSendMessage(suggestion)}
					>
						<Typography
							sx={{
								color: '#3B4251',
								fontSize: '12px',
								fontWeight: '400',
								lineHeight: 'normal',
								width: '176px',
							}}
						>
							{suggestion}
						</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default memo(EmptyChat);
