import { Avatar, Box, IconButton } from '@mui/material';
import { ContentCopy, LibraryAddCheck } from '@mui/icons-material';
import chatGPTMessagePhoto from '../../../assets/logos/s-logo-for-chat.svg';
import { sx } from './messageItem.sx';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { useEffect, useState, useCallback, memo } from 'react';

const MessageItem = ({ message, profilePhoto = '', containerSx = {} }) => {
	//@@viewOn:statics
	const senderIsUser = message.role === 'user';
	//@@viewOff:statics

	//@@viewOn:hooks
	const [isCopied, setIsCopied] = useState(false);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleCopyGeneratedResponse = useCallback(() => {
		setIsCopied(true);
	}, []);
	//@@viewOff:handlers

	//@@viewOn:effects
	useEffect(() => {
		if (isCopied) {
			const timer = setTimeout(() => {
				setIsCopied(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [isCopied]);
	//@@viewOff:effects

	if (message.role === 'system') return null;

	return (
		<Box sx={{ ...sx.container, ...containerSx, ...(senderIsUser && sx.userMessageContainer) }}>
			<Avatar
				sx={{ ...sx.avatar, ...(!senderIsUser && sx.avatarForChatGPT) }}
				variant="circular"
				src={senderIsUser ? profilePhoto : chatGPTMessagePhoto}
			/>
			<Box
				key={message.id}
				sx={{ ...sx.messageItem, ...(senderIsUser && sx.userMessageItem) }}
			>
				<pre style={{ ...sx.messageText, ...(senderIsUser && { color: '#3B4251' }) }}>{message.content}</pre>
			</Box>
			{!senderIsUser && (
				<CopyToClipboard
					text={message.content}
					onCopy={handleCopyGeneratedResponse}
				>
					<IconButton sx={sx.copyIcon}>
						{isCopied ? (
							<LibraryAddCheck
								sx={{
									width: '16px',
									height: '16px',
								}}
							/>
						) : (
							<ContentCopy
								sx={{
									width: '16px',
									height: '16px',
								}}
							/>
						)}
					</IconButton>
				</CopyToClipboard>
			)}
		</Box>
	);
};

export default memo(MessageItem);
