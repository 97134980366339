import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import ProfileAPI from '../../services/profileAPI';
import { useParams } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';
import useScreenSize from '../../hooks/useScreenSize/useScreenSize';
import useFavoriteListings from '../../hooks/useFavoriteListings/useFavoriteListings';
import useHandleDetailedListingItem from '../../hooks/useHandleDetailedListingItem/useHandleDetailedListingItem';
import useGetAllListings from '../../hooks/useGetAllListings/useGetAllListings';

const ListingItemPageContext = createContext(null);

const ListingItemPageProvider = ({ children }) => {
	//@@viewOn:statics
	const { UID } = useAuthorization();
	//@@viewOff:statics

	//@@viewOn:hooks
	const screenSize = useScreenSize();
	const isMobile = screenSize === 'xs' || screenSize === 's';
	const { id } = useParams();

	const [activeListing, setActiveListing] = useState(null);
	const { favoriteListingsIds } = useFavoriteListings({ UID });

	const { listingsIsLoading, allListings } = useGetAllListings();

	const { detailedListingData, detailedListingIsLoading } = useHandleDetailedListingItem({
		listingId: id,
		allListings,
		favoriteListingsIds,
		setActiveListing,
	});

	useDocumentTitle(`Listing ${activeListing?.UnparsedAddress} | S.GPT`);
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleToggleLikeListingItem = useCallback(
		async (isLiked, listingId) => {
			await ProfileAPI.toggleLikeFavoriteListing(isLiked, UID, listingId);
		},
		[UID],
	);
	//@@viewOff:handlers
	const contextValue = useMemo(
		() => ({
			UID,
			activeListing,
			isMobile,
			allListings,
			listingsIsLoading,
			handleToggleLikeListingItem,
			favoriteListingsIds,
			setActiveListing,
			detailedListingData,
			detailedListingIsLoading,
		}),
		[
			UID,
			activeListing,
			isMobile,
			allListings,
			listingsIsLoading,
			handleToggleLikeListingItem,
			favoriteListingsIds,
			setActiveListing,
			detailedListingData,
			detailedListingIsLoading,
		],
	);

	return <ListingItemPageContext.Provider value={contextValue}>{children}</ListingItemPageContext.Provider>;
};

const useListingsItemPage = () => {
	const context = useContext(ListingItemPageContext);
	if (!context) {
		throw new Error('useListingsItemPage must be used within a ListingItemPageProvider');
	}
	return context;
};

export default ListingItemPageProvider;
export { useListingsItemPage };
