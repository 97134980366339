import { SocialMediaTypes } from '../../../utils/creationContent/socialMediaTypes';

export const avatarSx = {
	avatar: (type) => {
		if (type === SocialMediaTypes.TIKTOK) {
			return {
				border: '1px solid #F54694',
				borderRadius: '50%',
				width: 35,
				height: 35,
				img: {
					borderRadius: '50%',
					width: 31,
					height: 31,
				},
			};
		} else if (type === SocialMediaTypes.INSTAGRAM) {
			return {
				width: 35,
				height: 35,
				padding: '2px',
				borderRadius: '50%',
				backgroundImage: 'linear-gradient(to right top, #D3A700, #D100C3)',
				backgroundOrigin: 'border-box',
				backgroundClip: 'content-box, border-box',
				img: {
					padding: '2px',
					borderRadius: '50%',
					width: 31,
					height: 31,
				},
			};
		} else {
			return {
				width: 31,
				height: 31,
				borderRadius: '50%',
			};
		}
	},
};
