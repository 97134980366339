import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { memo } from 'react';

const FadeWrapper = ({ children, sx = {}, transitionDuration = 1000 }) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsVisible(true);
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return (
		<Box
			sx={{
				...sx,
				opacity: isVisible ? 1 : 0,
				width: '100%',
				margin: '0 auto',
				transition: `opacity ${transitionDuration > 100 ? transitionDuration / 1000 : transitionDuration}s ease-in-out`,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{children}
		</Box>
	);
};

export default memo(FadeWrapper);
