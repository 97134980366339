import { useState, useEffect, useMemo, useContext, createContext, useCallback } from 'react';
import EditingImagesAPI from '../../services/editingImagesAPI';
import { useAppProvider } from '../Layout/LayoutProvider';
import { useNavigate, useParams } from 'react-router-dom';
import useFavoriteListings from '../../hooks/useFavoriteListings/useFavoriteListings';
import useScreenSize from '../../hooks/useScreenSize/useScreenSize';
import ProfileAPI from '../../services/profileAPI';
import useHandleDetailedListingItem from '../../hooks/useHandleDetailedListingItem/useHandleDetailedListingItem';
import useGetImagesPresets from '../../hooks/useGetImagesPresets/useGetImagesPresets';
import useGetChatThreads from '../../hooks/useGetChatThreads/useGetChatThreads';
import useGetAllListings from '../../hooks/useGetAllListings/useGetAllListings';
import useGetGeneratedImages from '../../hooks/useGetGeneratedImages/useGetGeneratedImages';
import { useAuthorization } from '../AuthorizationLayer/AuthorizationLayer';

const ProfilePageContext = createContext(null);

const ProfilePageProvider = ({ children }) => {
	//@@viewOn:statics
	const user = useAuthorization();
	const UID = user.UID;
	//@@viewOff:statics

	//@@viewOn:hooks
	const screenSize = useScreenSize();
	const isMobile = screenSize === 'xs' || screenSize === 's';

	const navigate = useNavigate();
	const { id } = useParams();
	const { setInitialSavedFilter } = useAppProvider();

	const [chatThreads, setChatThreads] = useState([]);
	const [chatThreadsIsLoading, setChatThreadsIsLoading] = useState(true);

	const [presets, setPresets] = useState(null);
	const [presetsIsLoading, setPresetsIsLoading] = useState(true);

	const [generatedImages, setGeneratedImages] = useState(null);
	const [generatedImagesIsLoading, setGeneratedImagesIsLoading] = useState(true);

	const [favoriteListings, setFavoriteListings] = useState(null);

	const [activeListing, setActiveListing] = useState(null);

	const { favoriteListingsIds } = useFavoriteListings({ UID });
	const { listingsIsLoading, allListings } = useGetAllListings();
	//@@viewOff:hooks

	//@@viewOn:handlers
	const handleApplyFilter = useCallback(
		(filter) => {
			setInitialSavedFilter(filter);
			navigate('/editing');
		},
		[setInitialSavedFilter],
	);

	const handleToggleLikePreset = useCallback(
		async (isLiked, presetId) => {
			await EditingImagesAPI.toggleLikePreset(isLiked, UID, presetId);
		},
		[UID],
	);

	const handleToggleLikeGeneratedImage = useCallback(
		async (isLiked, itemId) => {
			await EditingImagesAPI.toggleLikeGeneratedResponse(isLiked, UID, itemId);
		},
		[UID],
	);

	const handleToggleLikeListingItem = useCallback(
		async (isLiked, listingId) => {
			await ProfileAPI.toggleLikeFavoriteListing(isLiked, UID, listingId);
		},
		[UID],
	);
	//@@viewOff:handlers

	//@@viewOn:effects
	useEffect(() => {
		const favoriteListings = allListings?.filter((listing) => (favoriteListingsIds || []).includes(listing.Id));
		setFavoriteListings(favoriteListings || []);
	}, [allListings, UID, favoriteListingsIds]);

	useGetGeneratedImages({ generatedImages, UID, setGeneratedImages, setGeneratedImagesIsLoading });
	useGetChatThreads({
		chatThreads,
		setChatThreads,
		setChatThreadsIsLoading,
		UID,
		depsList: [UID, setChatThreads, setChatThreadsIsLoading],
	});

	const { detailedListingData, detailedListingIsLoading } = useHandleDetailedListingItem({
		listingId: id,
		allListings,
		favoriteListingsIds,
		setActiveListing,
	});
	useGetImagesPresets({ UID, presets, setPresets, setPresetsIsLoading });
	//@@viewOff:effects

	const contextValue = useMemo(
		() => ({
			user,
			UID,
			chatThreads,
			setChatThreads,
			chatThreadsIsLoading,
			setChatThreadsIsLoading,
			presets,
			setPresets,
			presetsIsLoading,
			setPresetsIsLoading,
			generatedImages,
			setGeneratedImages,
			generatedImagesIsLoading,
			setGeneratedImagesIsLoading,
			listingsIsLoading,
			favoriteListings,
			allListings,
			handleApplyFilter,
			handleToggleLikePreset,
			handleToggleLikeGeneratedImage,
			activeListing,
			setActiveListing,
			isMobile,
			handleToggleLikeListingItem,
			detailedListingData,
			detailedListingIsLoading,
		}),
		[
			user,
			UID,
			chatThreads,
			setChatThreads,
			chatThreadsIsLoading,
			setChatThreadsIsLoading,
			presets,
			setPresets,
			presetsIsLoading,
			setPresetsIsLoading,
			generatedImages,
			setGeneratedImages,
			generatedImagesIsLoading,
			setGeneratedImagesIsLoading,
			listingsIsLoading,
			favoriteListings,
			allListings,
			handleApplyFilter,
			handleToggleLikePreset,
			handleToggleLikeGeneratedImage,
			activeListing,
			setActiveListing,
			isMobile,
			handleToggleLikeListingItem,
			detailedListingData,
			detailedListingIsLoading,
		],
	);

	return <ProfilePageContext.Provider value={contextValue}>{children}</ProfilePageContext.Provider>;
};

const useProfilePage = () => {
	const context = useContext(ProfilePageContext);
	if (!context) {
		throw new Error('useProfilePage must be used within a ProfilePageProvider');
	}
	return context;
};

export default ProfilePageProvider;
export { useProfilePage };
