import townhouseBlack from '../../assets/propertyTypes/townhouse-black.svg';
import condoBlack from '../../assets/propertyTypes/condo-black.svg';
import singleBlack from '../../assets/propertyTypes/single-black.svg';
import apartmentBlack from '../../assets/propertyTypes/apartment-black.svg';
import duplexBlack from '../../assets/propertyTypes/duplex-black.svg';
import multiBlack from '../../assets/propertyTypes/multi-black.svg';
import cooperativeBlack from '../../assets/propertyTypes/cooperative-black.svg';
import mixedBlack from '../../assets/propertyTypes/mixed-black.svg';
import office from '../../assets/propertyTypes/office.svg';

export const propertyTypes = [
	{
		label: 'Townhouse',
		type: 'TOWNHOUSE',
		icon: townhouseBlack,
	},
	{
		label: 'Condo',
		type: 'CONDO',
		icon: condoBlack,
	},
	{
		label: 'Apartment',
		type: 'APARTMENT',
		icon: apartmentBlack,
	},
	{
		label: 'Single Family Residence',
		type: 'SINGLE_FAMILY_RESIDENCE',
		icon: singleBlack,
	},
	{
		label: 'Multi-Level',
		type: 'MULTI_LEVEL',
		icon: duplexBlack,
	},
	{
		label: 'Multi Family',
		type: 'MULTI_FAMILY',
		icon: multiBlack,
	},
	{
		label: 'Coop',
		type: 'COOP',
		icon: cooperativeBlack,
	},
	{
		label: 'Mixed Use',
		type: 'MIXED_USE',
		icon: mixedBlack,
	},
	{
		label: 'Office',
		type: 'OFFICE',
		icon: office,
	},
];

export const propertyTypeMap = {
	TOWNHOUSE: 'TOWNHOUSE',
	CONDO: 'CONDO',
	APARTMENT: 'APARTMENT',
	COOP: 'COOP',
	SINGLE_FAMILY_RESIDENCE: 'SINGLE_FAMILY_RESIDENCE',
	MULTI_LEVEL: 'MULTI_LEVEL',
	MULTI_FAMILY: 'MULTI_FAMILY',
	MIXED_USE: 'MIXED_USE',
	OFFICE: 'OFFICE',
};
