import * as Yup from 'yup';
import { propertyTypes } from '../listing/listingFilter';

export const listingFilterValidation = Yup.object().shape({
	propertyTypes: Yup.array()
		.of(
			Yup.string()
				.oneOf(
					propertyTypes.map((type) => type.type),
					'Invalid property type',
				)
				.required('Property type is required'),
		)
		.required('Property type is required'),
	bedsCount: Yup.mixed(),
	bathroomsCount: Yup.mixed(),
	minPrice: Yup.number().typeError('Minimum price must be a number').min(0, 'Minimum price cannot be negative'),
	maxPrice: Yup.number()
		.typeError('Maximum price must be a number')
		.when('minPrice', (minPrice, schema) =>
			schema.min(minPrice, 'Maximum price must be greater than or equal to the minimum price'),
		),
	minSquare: Yup.number()
		.typeError('Minimum square must be a number')
		.required('Minimum square is required')
		.min(0, 'Minimum square cannot be negative'),
	maxSquare: Yup.number()
		.typeError('Maximum square must be a number')
		.when('minSquare', (minSquare, schema) =>
			schema.min(minSquare, 'Maximum square must be greater than or equal to the minimum square'),
		),
});
