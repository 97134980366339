export const sx = {
	container: {
		padding: '15px 25px 22px 16px',
		background: (theme) => theme.palette.bg.main,
		borderRadius: '12px',
		border: (theme) => `1px solid ${theme.palette.stroke.custom3}`,
		boxShadow: (theme) => `0px 4px 4px 0px ${theme.palette.stroke.custom7}`,
	},
	imageAndControllersBox: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'flex-end',
		gap: '23px',
		maxWidth: '100%',
	},
	image: (imageProp) => ({
		marginTop: '15px',
		...(imageProp && { backgroundImage: `url(${imageProp})` }),
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		borderRadius: '8px',
	}),
};
