import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { get, ref as dbRef, set, update } from 'firebase/database';
import { axiosInstance } from './axios';
import { v4 as uuidv4 } from 'uuid';
import { db } from '../firebase/config';
import imageCompression from 'browser-image-compression';

/**
 * A utility class for interacting with the API to manage editing images-related data.
 */
export default class EditingImagesAPI {
	static imagineRequestUrl = process.env.REACT_APP_API_BACKEND_URL + '/midJourneyImagine';
	static getGenerationResultUrl = process.env.REACT_APP_API_BACKEND_URL + '/midJourneyGetMessage';

	/**
	 * Generates a new photo using the provided message.
	 *
	 * @param {string} msg - The message to generate a photo from.
	 * @returns {Promise<object>} - The response data of the generated photo.
	 */
	static async generateNewPhoto(msg) {
		const response = await axiosInstance.post(this.imagineRequestUrl, {
			msg,
		});
		return response.data;
	}

	/**
	 * Fetches the generation result for a specific message.
	 *
	 * @param {string} messageId - The ID of the message.
	 * @param {string} loadbalancerId - The ID of the loadbalancer.
	 * @returns {Promise<object>} - The response data of the generation result.
	 */
	static async getGenerationResult(messageId, loadbalancerId) {
		console.log(messageId);
		console.log(loadbalancerId);
		const response = await axiosInstance.get(
			`${this.getGenerationResultUrl}?messageID=${messageId}&loadBalanceId=${loadbalancerId}`,
		);
		return response.data;
	}

	/**
	 * Saves an image in storage and returns its download URL.
	 *
	 * @param {File} imageFile - The image file to be saved.
	 * @returns {Promise<string>} - The download URL of the saved image.
	 */
	static async saveImageInStorageAndReturnUrl(imageFile) {
		const storage = getStorage();

		const fileName = uuidv4() + imageFile.name;
		const fileReference = ref(storage, fileName);

		const options = {
			maxSizeMB: 1,
			alwaysKeepResolution: false,
			useWebWorker: false,
			maxIteration: 16,
		};

		try {
			const compressedFile = await imageCompression(imageFile, options);
			const snapshot = await uploadBytes(fileReference, compressedFile);

			return await getDownloadURL(snapshot.ref);
		} catch (error) {
			return error;
		}
	}

	/**
	 * Saves generated images and its filters for a specific user to the Firebase.
	 *
	 * @param {object} filters - The filters of the generated images.
	 * * @param {Array<string>} images - The generated images.
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<void>} - The response data of the generated images.
	 */
	static async saveGeneratedImagesResponse(filters, images, UID) {
		const generatedItemId = uuidv4();
		const generatedItemBody = {
			...filters,
			images,
			id: generatedItemId,
			isLiked: false,
		};

		await set(dbRef(db, `generatedImages/${UID}/${generatedItemId}`), generatedItemBody);
	}

	/**
	 * Fetches a preset by its ID for a specific user.
	 *
	 * @param {string} presetId - The ID of the preset.
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<object|null>} - The preset object or null if not found.
	 */
	static async getPresetById(presetId, UID) {
		const presetsRef = dbRef(db, `presets/${UID}/${presetId}`);
		const presetsSnapshot = await get(presetsRef);

		const presetsData = presetsSnapshot.val();
		return presetsData || null;
	}

	/**
	 * Fetches all presets for a user.
	 *
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<Array<object>>} - An array of preset objects.
	 */
	static async getPresets(UID) {
		const presetsRef = dbRef(db, `presets/${UID}/`);
		const presetsSnapshot = await get(presetsRef);
		const presetsData = presetsSnapshot.val();

		return Object.values(presetsData || {}).map((preset) => preset);
	}

	/**
	 * Fetches a chat thread by its ID for a specific user.
	 * @param {string}UID - The user's unique identifier.
	 * @returns {Promise<Array<object>|null>} - An array of generated images objects.
	 */
	static async getGeneratedImages(UID) {
		const generatedImagesRef = dbRef(db, `generatedImages/${UID}/`);
		const generatedImagesSnapshot = await get(generatedImagesRef);
		const generatedImagesSnapshotData = generatedImagesSnapshot.val();

		return Object.values(generatedImagesSnapshotData || {});
	}

	/**
	 * Saves a new preset for a user.
	 *
	 * @param {object} preset - The preset object to be saved.
	 * @param {string} UID - The user's unique identifier.
	 * @returns {Promise<object>} - The saved preset object.
	 */
	static async savePreset(preset, UID) {
		const presetId = uuidv4();

		try {
			let thumbnail = null;
			if (preset.image) {
				thumbnail = await this.saveImageInStorageAndReturnUrl(preset.image);
			}
			const presetBody = {
				...preset,
				thumbnail,
				id: presetId,
				isLiked: false,
			};
			delete presetBody.image;

			await set(dbRef(db, `presets/${UID}/${presetId}`), presetBody);
			return await presetBody;
		} catch (error) {
			return error;
		}
	}

	/**
	 * Updates an existing preset for a user.
	 *
	 * @param {object} preset - The updated preset object.
	 * @param {string} UID - The user's unique identifier.
	 * @param {string} presetId - The ID of the preset to be updated.
	 * @returns {Promise<object>} - The updated preset object.
	 */
	static async updatePreset(preset, UID, presetId) {
		try {
			let thumbnail = null;

			if (preset.image && typeof preset.image !== 'string') {
				thumbnail = await this.saveImageInStorageAndReturnUrl(preset.image);
			}

			const presetBody = {
				...preset,
				thumbnail,
				id: presetId,
			};
			delete presetBody.image;

			await update(dbRef(db, `presets/${UID}/${presetId}`), presetBody);
			return await this.getPresetById(presetId, UID);
		} catch (error) {
			return error;
		}
	}

	static async toggleLikePreset(isLiked, UID, presetId) {
		try {
			await update(dbRef(db, `presets/${UID}/${presetId}`), { isLiked });
		} catch (error) {
			return error;
		}
	}

	static async toggleLikeGeneratedResponse(isLiked, UID, itemId) {
		try {
			await update(dbRef(db, `generatedImages/${UID}/${itemId}`), { isLiked });
		} catch (error) {
			return error;
		}
	}
}
