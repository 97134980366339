import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';

const ChatThreadsSkeleton = () => {
	return (
		<Box
			sx={{
				maxWidth: { xs: '100%', md: '327px', lg: '427px' },
				width: '100%',
				position: { xs: 'fixed', md: 'static' },
				minHeight: { xs: 'calc(100% - 70px)', md: '100%' },
				top: '70px',
				left: '0',
				zIndex: '10',
				padding: '21px 20px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				gap: '10px',
				borderRadius: { xs: '0', md: '8px' },
				border: { xs: 'none', md: '1px solid #CFD2E4' },
				background: (theme) => theme.palette.bg.light,
				boxShadow: '0px 6px 6px 0px rgba(230, 234, 243, 0.56)',
			}}
		>
			<Box
				sx={{
					width: '100%',
					marginBottom: '15px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Skeleton
					variant="text"
					width={100}
					height={30}
					sx={{}}
				/>
			</Box>

			<Box
				sx={{
					width: '100%',
					maxHeight: '100%',
					marginBottom: '20px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				{Array.from(Array(6).keys()).map((index) => (
					<Box
						key={index}
						sx={{
							display: 'flex',
							height: '47px',
							padding: '6px 4px 7px 11px',
							justifyContent: 'space-between',
							minWidth: '100%',
							alignItems: 'center',
							borderRadius: '4px',
							background: (theme) => theme.palette.bg.main,
							marginBottom: '10px',
						}}
					>
						<Skeleton
							variant="text"
							width={120}
							height={20}
							sx={{}}
						/>
					</Box>
				))}
			</Box>
			<Box
				sx={{
					borderTop: '1px solid #CFD2E4',
					paddingTop: '20px',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '10px',
				}}
			>
				<Skeleton
					variant="rounded"
					width="100%"
					height={46}
					sx={{}}
				/>
				<Skeleton
					variant="rounded"
					width="100%"
					height={46}
					sx={{}}
				/>
			</Box>
		</Box>
	);
};

export default memo(ChatThreadsSkeleton);
